
import React from 'react'


export default function MobileNavbar({ menuIsOpen, setMenuIsOpen}){


    return(
<section id="contact-mobile" className="d-block d-md-none">
    <div className="container">
        <div className="row">
            <div className="col-4 d-flex justify-content-center">
                <div style={{width: '42px'}} className="align-self-start">
                    <div className={`hamburger ${menuIsOpen ? 'checked' : ''}`} id="hamburger" onClick={()=>setMenuIsOpen(!menuIsOpen)}><div></div></div>
                </div>
            </div>
            <div className="col-4 d-flex justify-content-center">
                <a href="tel:+543516503470" className="text-center">
                    <img loading="lazy" width="23.847" height="23.839" alt="phone" src="/images/img/icon-phone.svg" />
                </a>
            </div>
            <div className="col-4 d-flex justify-content-center">
                <a href="https://api.whatsapp.com/send?phone=+5493516503470&text=Buenos%20d%C3%ADas,%20quiero%20mas%20info%20sobre%20desarrollo%20web" className="text-center FB-mkt">
                    <img loading="lazy" width="23.907" height="24" alt="whatsapp" src="/images/img/icon-whatsapp.svg" />
                </a>
            </div>
        </div>
    </div>
    

    </section>
    )
}