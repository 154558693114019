import React from 'react'
import './politicas.css'

export default function PrivacyText() {
  return (
    <div className='container politicas'>
        <h1 className='mt-2'>Política de Cookies</h1>

<p>Fecha de entrada en vigencia: [Fecha de entrada en vigencia]</p>

<p>Esta Política de Cookies establece los términos y condiciones bajo los cuales chimpance.digital utiliza cookies y tecnologías similares en nuestro sitio web. Al acceder y utilizar nuestro sitio web, aceptas el uso de cookies de acuerdo con los términos y condiciones establecidos en esta política.</p>

<h2>Definiciones</h2>

<p>1.1. Cookies: se refiere a pequeños archivos de texto que se almacenan en tu dispositivo cuando visitas nuestro sitio web. Estas cookies recopilan información sobre tu actividad en nuestro sitio web y nos permiten recordar tus preferencias y mejorar tu experiencia de navegación.</p>
<p>1.2. Sitio web: se refiere a chimpance.digital, accesible a través de la dirección www.chimpance.digital.</p>

<h2>Uso de Cookies</h2>

<p>2.1. Consentimiento: Al acceder y utilizar nuestro sitio web, aceptas el uso de cookies de acuerdo con esta política. Si no deseas aceptar el uso de cookies, te recomendamos ajustar la configuración de tu navegador para bloquear o eliminar las cookies. Sin embargo, ten en cuenta que esto podría afectar la funcionalidad de nuestro sitio web y limitar algunas características.</p>

<p>2.2. Tipos de Cookies: Utilizamos los siguientes tipos de cookies en nuestro sitio web:</p>

<ul>
    <li>a) Cookies esenciales: Estas cookies son necesarias para el correcto funcionamiento de nuestro sitio web. No recopilan información personal identificable y no se pueden desactivar a través de la configuración de tu navegador.</li>
    <li>b) Cookies de rendimiento: Estas cookies recopilan información anónima sobre cómo utilizas nuestro sitio web, como las páginas que visitas y los enlaces en los que haces clic. Esta información nos ayuda a mejorar el rendimiento y la experiencia de navegación en nuestro sitio web.</li>
    <li>c) Cookies de funcionalidad: Estas cookies nos permiten recordar tus preferencias y personalizar tu experiencia en nuestro sitio web, como el idioma seleccionado o la región en la que te encuentras.</li>
    <li>d) Cookies de publicidad: Podemos utilizar cookies de terceros para mostrar anuncios relevantes en nuestro sitio web. Estas cookies recopilan información sobre tus intereses y preferencias para mostrar anuncios adaptados a tus necesidades.</li>
</ul>

<p>2.3. Cookies de Terceros: Es posible que nuestro sitio web utilice cookies de terceros para mejorar la funcionalidad y la experiencia de navegación. Estas cookies están sujetas a las políticas de privacidad de los terceros correspondientes y no tenemos control sobre su uso.</p>

<h2>Privacidad y Protección de Datos</h2>

<p>3.1. Protección de la Privacidad: El uso de cookies en nuestro sitio web se rige por nuestra Política de Privacidad. Respetamos tu privacidad y nos comprometemos a proteger tus datos personales de acuerdo con las leyes y regulaciones aplicables.</p>
<p>3.2. Información Recopilada: Las cookies utilizadas en nuestro sitio web no recopilan información personal identificable, a menos que nos la proporciones voluntariamente a través de formularios o interacciones adicionales.</p>

<h2>Modificaciones a esta Política</h2>

<p>4.1. Nos reservamos el derecho de modificar o actualizar esta Política de Cookies en cualquier momento. Te recomendamos revisar esta página periódicamente para estar al tanto de cualquier cambio. La fecha de entrada en vigencia se indicará al comienzo de esta política.</p>

<h2>Contacto</h2>

<p>5.1. Si tienes alguna pregunta o inquietud acerca de nuestra Política de Cookies, no dudes en ponerte en contacto con nosotros a través de la información de contacto proporcionada en nuestro sitio web.</p>

<p>Agradecemos tu comprensión y tu confianza al utilizar nuestro sitio web.</p>

<p>Atentamente,<br/>El equipo de Chimpancé Digital</p>
    </div>
  )
}
