import marcadorPortafolio from "./../../../assets/img/img-marcador-portfolio.svg";

export default function SectionOne() {
  return (
    <header className="branding d-flex align-items-center">
      <div className="container">
        <div className="row rellax" >
          <div className="col-md-6 d-flex flex-row flex-wrap align-content-center head-branding ">
            <span>
              <img src={marcadorPortafolio} alt="" className="mt-1 " />
            </span>
            <h3
              className="ff-circularBook fs-74 branding2 ms-1"
              style={{ fontFamily: "Circular Black" }}
            >
              Branding
            </h3>
          </div>
          <div className="col-md-6 d-flex justify-content-lg-end branding">
            <ul className="fs-60 branding me-5">
              <li className="ff-circularBlack">MARCAS/</li>
              <li className="ff-circularBlack">INVESTIGACIÓN/</li>
              <li className="ff-circularBlack">IDENTIDAD/</li>
              <li className="ff-circularBlack">BRANDBOOK/</li>
              <li className="ff-circularBlack">KEY VISUAL/</li>
              <li className="ff-circularBlack">ESTRATEGIA/</li>
            </ul>
          </div>
        </div>
        <div className="position-vertical-container">
          <div className="vertical-text-container d-flex ">
            <img src={marcadorPortafolio} alt="" className="rotate-image" />
            <p className=" vertical-text">
              Marcas/Investigación/Identidad/Brandbook/Key Visual/Estrategia
            </p>
          </div>
        </div>
      </div>
    </header>
  );
}
