
import Traslaaqua3 from "./img/branding/Traslaqua/3-traslaqua.webp"
import Traslaaqua4 from "./img/branding/Traslaqua/4-traslaqua.webp"
import Traslaaqua5 from "./img/branding/Traslaqua/5-traslaqua.webp"
import Traslaaqua6 from "./img/branding/Traslaqua/6-traslaqua.webp"
import Traslaaqua7 from "./img/branding/Traslaqua/7-traslaqua.webp"
import Traslaaqua8 from "./img/branding/Traslaqua/8-traslaqua.webp"
import Traslaaqua9 from "./img/branding/Traslaqua/9-traslaqua.webp"
import Traslaaqua10 from "./img/branding/Traslaqua/10-traslaqua.webp"
import Traslaaqua11 from "./img/branding/Traslaqua/11-traslaqua.webp"
import Traslaaqua12 from "./img/branding/Traslaqua/12-traslaqua.webp"
import Traslaaqua13 from "./img/branding/Traslaqua/13-traslaqua.webp"
import Traslaaqua14 from "./img/branding/Traslaqua/14-traslaqua.webp"

import Sebastian3 from "./img/branding/Sebastian/3-sebastian.webp"
import Sebastian4 from "./img/branding/Sebastian/4-sebastian.webp"
import Sebastian5 from "./img/branding/Sebastian/5-sebastian.webp"
import Sebastian6 from "./img/branding/Sebastian/6-sebastian.webp"
import Sebastian7 from "./img/branding/Sebastian/7-sebastian.webp"
import Sebastian8 from "./img/branding/Sebastian/8-sebastian.webp"

import Aapre3 from "./img/branding/Aapre/3-aapre.webp"
import Aapre4 from "./img/branding/Aapre/4-aapre.webp"
import Aapre5 from "./img/branding/Aapre/5-aapre.webp"
import Aapre6 from "./img/branding/Aapre/6-aapre.webp"
import Aapre7 from "./img/branding/Aapre/7-aapre.webp"
import Aapre8 from "./img/branding/Aapre/8-aapre.webp"
import Aapre9 from "./img/branding/Aapre/9-aapre.webp"
import Aapre10 from "./img/branding/Aapre/10-aapre.webp"
import Aapre11 from "./img/branding/Aapre/11-aapre.webp"
import Aapre12 from "./img/branding/Aapre/12-aapre.webp"

import ZonaInmo3 from "./img/branding/Zonainmo/3-zonainmo.webp"
import ZonaInmo4 from "./img/branding/Zonainmo/4-zonainmo.webp"
import ZonaInmo5 from "./img/branding/Zonainmo/5-zonainmo.webp"
import ZonaInmo6 from "./img/branding/Zonainmo/6-zonainmo.webp"
import ZonaInmo7 from "./img/branding/Zonainmo/7-zonainmo.webp"
import ZonaInmo8 from "./img/branding/Zonainmo/8-zonainmo.webp"
import ZonaInmo9 from "./img/branding/Zonainmo/9-zonainmo.webp"
import ZonaInmo10 from "./img/branding/Zonainmo/10-zonainmo.webp"
import ZonaInmo11 from "./img/branding/Zonainmo/11-zonainmo.webp"

import Airfort3 from "./img/branding/Airfort/3-airfort.webp"
import Airfort4 from "./img/branding/Airfort/4-airfort.webp"
import Airfort5 from "./img/branding/Airfort/5-airfort.webp"
import Airfort6 from "./img/branding/Airfort/6-airfort.webp"
import Airfort7 from "./img/branding/Airfort/7-airfort.webp"
import Airfort8 from "./img/branding/Airfort/8-airfort.webp"
import Airfort9 from "./img/branding/Airfort/9-airfort.webp"
import Airfort10 from "./img/branding/Airfort/10-airfort.webp"

import Indisual3 from "./img/branding/Indisual/3-indisual.webp"
import Indisual4 from "./img/branding/Indisual/4-indisual.webp"
import Indisual5 from "./img/branding/Indisual/5-indisual.webp"
import Indisual6 from "./img/branding/Indisual/6-indisual.webp"
import Indisual7 from "./img/branding/Indisual/7-indisual.webp"
import Indisual8 from "./img/branding/Indisual/8-indisual.webp"
import Indisual9 from "./img/branding/Indisual/9-indisual.webp"
import Indisual10 from "./img/branding/Indisual/10-indisual.webp"
import Indisual11 from "./img/branding/Indisual/11-indisual.webp"

import Paracol3 from "./img/branding/Paracol/3-paracol.webp"
import Paracol4 from "./img/branding/Paracol/4-paracol.webp"
import Paracol5 from "./img/branding/Paracol/5-paracol.webp"
import Paracol6 from "./img/branding/Paracol/6-paracol.webp"
import Paracol7 from "./img/branding/Paracol/7-paracol.webp"
import Paracol8 from "./img/branding/Paracol/8-paracol.webp"

import LaMatilde3 from "./img/branding/La-matilde/3-lamatilde.webp"
import LaMatilde4 from "./img/branding/La-matilde/4-lamatilde.webp"
import LaMatilde5 from "./img/branding/La-matilde/5-lamatilde.webp"
import LaMatilde6 from "./img/branding/La-matilde/6-lamatilde.webp"
import LaMatilde7 from "./img/branding/La-matilde/7-lamatilde.webp"
import LaMatilde8 from "./img/branding/La-matilde/8-lamatilde.webp"

import Marph3 from "./img/branding/Marph/3-marph.webp"
import Marph4 from "./img/branding/Marph/4-marph.webp"
import Marph5 from "./img/branding/Marph/5-marph.webp"
import Marph6 from "./img/branding/Marph/6-marph.webp"
import Marph7 from "./img/branding/Marph/7-marph.webp"
import Marph8 from "./img/branding/Marph/8-marph.webp"
import Marph9 from "./img/branding/Marph/9-marph.webp"

import Puro3 from "./img/branding/Puro/3-puro.webp"
import Puro4 from "./img/branding/Puro/4-puro.webp"
import Puro5 from "./img/branding/Puro/5-puro.webp"
import Puro6 from "./img/branding/Puro/6-puro.webp"
import Puro7 from "./img/branding/Puro/7-puro.webp"
import Puro8 from "./img/branding/Puro/8-puro.webp"
import Puro9 from "./img/branding/Puro/9-puro.webp"

import Singulares3 from "./img/branding/Singulares/3-singulares.webp"
import Singulares4 from "./img/branding/Singulares/4-singulares.webp"
import Singulares5 from "./img/branding/Singulares/5-singulares.webp"
import Singulares6 from "./img/branding/Singulares/6-singulares.webp"
import Singulares7 from "./img/branding/Singulares/7-singulares.webp"
import Singulares8 from "./img/branding/Singulares/8-singulares.webp"
import Singulares9 from "./img/branding/Singulares/9-singulares.webp"

import DelGradal3 from "./img/branding/Del-gredal/3-delgredal.webp"
import DelGradal4 from "./img/branding/Del-gredal/4-delgredal.webp"
import DelGradal5 from "./img/branding/Del-gredal/5-delgredal.webp"
import DelGradal6 from "./img/branding/Del-gredal/6-delgredal.webp"
import DelGradal7 from "./img/branding/Del-gredal/7-delgredal.webp"
import DelGradal8 from "./img/branding/Del-gredal/8-delgredal.webp"
import DelGradal9 from "./img/branding/Del-gredal/9-delgredal.webp"

import Gaintza3 from "./img/branding/Gaintza/3-gaintza.webp"
import Gaintza4 from "./img/branding/Gaintza/4-gaintza.webp"
import Gaintza5 from "./img/branding/Gaintza/5-gaintza.webp"
import Gaintza6 from "./img/branding/Gaintza/6-gaintza.webp"
import Gaintza7 from "./img/branding/Gaintza/7-gaintza.webp"
import Gaintza8 from "./img/branding/Gaintza/8-gaintza.webp"
import Gaintza9 from "./img/branding/Gaintza/9-gaintza.webp"

import DeliveryWap3 from "./img/branding/DeliveryWap/3-deliverywap.webp"
import DeliveryWap4 from "./img/branding/DeliveryWap/4-deliverywap.webp"
import DeliveryWap5 from "./img/branding/DeliveryWap/5-deliverywap.webp"
import DeliveryWap6 from "./img/branding/DeliveryWap/6-deliverywap.webp"
import DeliveryWap7 from "./img/branding/DeliveryWap/7-deliverywap.webp"
import DeliveryWap8 from "./img/branding/DeliveryWap/8-deliverywap.webp"
import DeliveryWap9 from "./img/branding/DeliveryWap/9-deliverywap.webp"

export const proyectosNuevos = [
  {
    titulo: "TRASLAAQUA",
    cliente: "Traslaaqua",
    sector: "Lavandería Industrial",
    tags: ['branding', 'lavandería', 'diseño', 'identidad', 'brandbook'],
    descripcion: "Traslaaqua es una empresa de lavandería industrial que atiende principalmente a hoteles y cabañas. Nuestro objetivo fue desarrollar una identidad visual robusta y coherente que reflejara la alta calidad y fiabilidad del servicio ofrecido.",
    desafios: [
      {
        item: "Diferenciación de Marca",
        desarrollo: "Creamos una identidad visual fresca, moderna y disruptiva, utilizando colores vivos y tipografías contemporáneas para destacar a Traslaaqua frente a otros competidores."
      },
      {
        item: "Comunicación de Calidad y Fiabilidad",
        desarrollo: "Diseñamos elementos gráficos que transmiten confianza y profesionalismo, resaltando la pureza y limpieza, valores fundamentales para la empresa."
      }
    ],
    imagenes: [
      {
        localPath: Traslaaqua3,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Traslaaqua4,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Traslaaqua5,
        width: "100%",
        heigth: "807px"
      },
      [
        {
          localPath: Traslaaqua6,
          width: "48%",
          heigth: "770px"
        },
        {
          localPath: Traslaaqua7,
          width: "48%",
          heigth: "770px"
        },
      ],
      {
        localPath: Traslaaqua8,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Traslaaqua9,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Traslaaqua10,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Traslaaqua11,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Traslaaqua12,
        width: "100%",
        heigth: "807px"
      },
      [
        {
          localPath: Traslaaqua13,
          width: "48%",
          heigth: "770px"
        },
        {
          localPath: Traslaaqua14,
          width: "48%",
          heigth: "770px"
        },
      ]
    ]
  },
  {
    titulo: "SEBASTIAN MARTINEZ",
    cliente: "Sebastian Martinez",
    tags: ['Branding', 'Diseño', 'Arquitectura', 'MarcaPersonal', 'Identidad'],
    sector: "Arquitectura",
    descripcion: "Sebastián Martinez es una marca personal especializada en proyectos integrales de arquitectura.",
    descripcion2: "Nuestra meta fue crear una identidad visual que abarcara todos sus servicios y que perdurara en el tiempo.",
    desafios: [
      {
        item: "Representación de la marca",
        desarrollo: "profesionalismo técnico e innovación."
      },
    ],
    imagenes: [
      {
        localPath: Sebastian3,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Sebastian4,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Sebastian5,
        width: "100%",
        heigth: "807px"
      },
      [{
        localPath: Sebastian6,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Sebastian7,
        width: "100%",
        heigth: "807px"
      }],
      {
        localPath: Sebastian8,
        width: "100%",
        heigth: "807px"
      },

    ]
  },
  {
    titulo: "AAPRESID",
    subtitulo: "Asociación Argentina de Productores en Siembra Directa",
    cliente: "Asociación Argentina de Productores en Siembra Directa",
    tags: ['Key Visual', 'Agro', 'Web', 'Diseño', 'Identidad'],
    sector: "Agro",
    descripcion: "Aapresid es una organización sin fines de lucro que reúne a una red de productores agropecuarios comprometidos con la conservación del suelo. Su enfoque en la Siembra Directa ha marcado un nuevo paradigma agrícola en Argentina.",
    descripcion2: "El principal reto fue desarrollar un Key Visual que brindara elementos visuales atractivos para su sitio web.",
    desafios: [
      {
        item: "Consistencia y coherencia",
        desarrollo: "Utilizamos formas geométricas simples inspiradas en su isotipo, asegurando la consistencia y coherencia en todos los elementos de la marca."
      },
      {
        item: "Identidad y representación",
        desarrollo: "Creamos una amplia variedad de composiciones visuales, manteniendo la unidad y la identidad visual de Aapresid."
      },
    ],
    imagenes: [
      {
        localPath: Aapre3,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Aapre4,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Aapre5,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Aapre6,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Aapre7,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Aapre8,
        width: "100%",
        heigth: "807px"
      },
      [{
        localPath: Aapre9,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Aapre10,
        width: "50%",
        heigth: "770px"
      }],
      {
        localPath: Aapre11,
        width: "100%",
        heigth: "770px"
      },
      {
        localPath: Aapre12,
        width: "100%",
        heigth: "807px"
      }
    ]
  },
  {
    titulo: "ZONAINMO",
    cliente: "ZonaInmo",
    tags: ['Branding', 'Real Estate', 'Diseño', 'Identidad', 'Brandbook'],
    sector: "Real Estate",
    descripcion: "ZonaInmo es una plataforma de Intermediación Inmobiliaria Digital que busca simplificar y enriquecer la experiencia de compra y venta de bienes raíces.",
    desafios: [
      {
        item: "Trabajar sobre la percepción de ZonaInmo",
        desarrollo: "no solo como una marca de bienes raíces, sino también como una agencia de intermediación e innovación en el rubro."
      },
      {
        item: "Creamos una identidad de marca llamativa y divertida",
        desarrollo: "desarrollando un diseño dinámico, fresco y distintivo que les permitiera destacarse en un mercado competitivo."
      },
    ],
    imagenes: [
      {
        localPath: ZonaInmo3,
        width: "100%",
        heigth: "807px"
      },
      [{
        localPath: ZonaInmo4,
        width: "50%",
        heigth: "807px"
      },
      {
        localPath: ZonaInmo5,
        width: "50%",
        heigth: "807px"
      }],
      {
        localPath: ZonaInmo6,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: ZonaInmo7,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: ZonaInmo8,
        width: "100%",
        heigth: "807px"
      },
      [{
        localPath: ZonaInmo9,
        width: "50%",
        heigth: "770px"
      },
      {
        localPath: ZonaInmo10,
        width: "50%",
        heigth: "770px"
      }],
      {
        localPath: ZonaInmo11,
        width: "100%",
        heigth: "770px"
      },
    ]
  },
  {
    titulo: "AIRFORT",
    cliente: "Airfort",
    tags: ['Rebranding', 'Gastronomía', 'Aeropuerto', 'Diseño', 'Identidad'],
    sector: "Cafetería",
    descripcion: "Airfort es una cafetería take away situada en el aeropuerto de Formosa. Debido a los requisitos del programa Aeropuertos 2000, la marca necesitaba una actualización. Por esta razón, desarrollamos una identidad que cumpliera con los nuevos estándares, manteniendo su esencia única.",
    desafios: [
      {
        item: "Representación regional",
        desarrollo: "Nos inspiramos en los símbolos que representan la región, su geografía y naturaleza, para el desarrollo de su identidad."
      },
      {
        item: "Simbología única",
        desarrollo: "Formosa se destaca por su rica biodiversidad, paisajes tropicales y ecosistemas únicos, incluyendo una abundancia de palmeras y aves. Utilizamos esta simbología junto con elementos que hacen referencia directa al rubro de la cafetería, para lograr un balance representativo y atractivo a nivel visual."
      },
    ],
    imagenes: [
      {
        localPath: Airfort3,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Airfort4,
        width: "100%",
        heigth: "807px"
      },
      [{
        localPath: Airfort5,
        width: "50%",
        heigth: "807px"
      },
      {
        localPath: Airfort6,
        width: "50%",
        heigth: "807px"
      }],
      {
        localPath: Airfort7,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Airfort8,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Airfort9,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Airfort10,
        width: "100%",
        heigth: "770px"
      },

    ]
  },
  {
    titulo: "INDISUAL",
    cliente: "Indisual",
    tags: ['Branding', 'Identidad', 'Arquitectura', 'Diseño', 'Social Media'],
    sector: "Branding y Diseño, Sitios Web Inmobiliarios y de Propiedades, Marketing Integrado, Social Media, Señalética, Visualización (Marph), Videos, Consultoría de Arquitectura.",
    descripcion: "Indisual ofrece servicios integrales de marketing con especialización en el nicho inmobiliario. Su diferencial radica en el expertise colaborativo entre artistas, arquitectos, agentes inmobiliarios y especialistas en marketing, quienes combinan sus conocimientos para proporcionar información crítica sobre nuevas tendencias y prácticas que aseguren el éxito comercial de sus proyectos.",
    desafios: [
      {
        item: "Crear una identidad representativa de la marca y todos suservicios",
        desarrollo: "lo logramos a través de un diseño minimalista y moderno, utilizando una paleta de colores neutros para no limitar la identidad a un solo rubro. Este factor fue determinante, para que todos sus clientes pudieran verse incluidos y sentirse representados en su identidad visual."
      },
    ],
    imagenes: [
      {
        localPath: Indisual3,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Indisual4,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Indisual5,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Indisual6,
        width: "100%",
        heigth: "807px"
      },
      [{
        localPath: Indisual7,
        width: "50%",
        heigth: "807px"
      },
      {
        localPath: Indisual8,
        width: "50%",
        heigth: "807px"
      }],
      {
        localPath: Indisual9,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Indisual10,
        width: "100%",
        heigth: "770px"
      },
      {
        localPath: Indisual11,
        width: "100%",
        heigth: "770px"
      }
    ]
  },
  {
    titulo: "PARACOL",
    cliente: "Paracol",
    tags: ['Branding', 'Identidad', 'Metalúrgia', 'Simbología', 'Industria'],
    sector: "Metalúrgico",
    descripcion: "Paracol es una empresa con 40 años de trayectoria, vinculada al progreso del país. Inicialmente reconocida por su actividad en la producción metalúrgica en su localidad y regiones adyacentes, con el tiempo se especializó en la fabricación de productos para el sector agropecuario a partir de 1991.",
    desafios: [
      {
        item: "Traducir su visión, historia y valores, en una identidad",
        desarrollo: "fusionamos elementos robustos con los valores, la misión y la visión de la marca, reflejando las características distintivas del sector metalúrgico."
      },
      {
        item: "Crear una simbología representativa",
        desarrollo: "Utilizamos símbolos que transmiten solidez y precisión, comunicando estabilidad y tecnología."
      },
    ],
    imagenes: [
      {
        localPath: Paracol3,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Paracol4,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Paracol5,
        width: "100%",
        heigth: "807px"
      },
      [{
        localPath: Paracol6,
        width: "50%",
        heigth: "807px"
      },
      {
        localPath: Paracol7,
        width: "50%",
        heigth: "807px"
      }],
      {
        localPath: Paracol8,
        width: "100%",
        heigth: "807px"
      }
    ]
  },
  {
    titulo: "LA MATILDE",
    cliente: "La Matilde",
    sector: "Hotelería",
    tags: ['Key Visual', 'Identidad', 'Hotelería', 'Boutique', 'Bioarquitectura'],
    descripcion: "La Matilde es una posada exclusiva que abraza una filosofía basada en la producción sostenible, la bioarquitectura, la gastronomía sustentable y el cuidado del medio ambiente, junto con la preservación de oficios tradicionales y la creación de espacios libres de químicos.",
    descripcion2: "La propuesta de valor de la posada se centra en la sustentabilidad, ofreciendo comida saludable, espacios libres de químicos, arte, materiales nobles y una redefinición del lujo y confort.",
    desafios: [
      {
        item: "Desarrollar una identidad visual que se adapte al logotipo de la marca",
        desarrollo: "tomamos como elemento principal la cruz andina que lo compone y creamos patrones a partir de esta forma simple y reconocible."
      },
      {
        item: "Enlazar su simbología con su entorno natural",
        desarrollo: "Utilizamos una paleta de colores terracota para hacer referencia al entorno donde se ubica la posada, resaltando su conexión con la naturaleza y la sostenibilidad."
      },
    ],
    imagenes: [
      {
        localPath: LaMatilde3,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: LaMatilde4,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: LaMatilde5,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: LaMatilde6,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: LaMatilde7,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: LaMatilde8,
        width: "100%",
        heigth: "807px"
      },

    ]
  },
  {
    titulo: "MARPH",
    cliente: "Marph",
    tags: ['Key Visual ', 'Arquitectura', 'Diseño', 'Identidad', 'Web'],
    sector: "Hotelería",
    descripcion: "Marph es un estudio de arquitectura con sede en Montevideo, especializado en el diseño y desarrollo de proyectos de diversas escalas y programas. Destacan en arquitectura residencial, comercial y de oficinas, ofreciendo soluciones creativas y funcionales.",
    descripcion2: "El objetivo fue desarrollar un Key Visual para ser utilizado en su sitio web.",
    desafios: [
      {
        item: "Coherencia y unidad visual",
        desarrollo: "Creamos un sistema de elementos visuales que siguen la morfología del logotipo de la marca, logrando armonía en la identidad."
      },
      {
        item: "Reflejar la modernidad y el profesionalismo de la marca",
        desarrollo: "logramos un diseño que combina elegancia y funcionalidad, permitiendo una navegación intuitiva y una experiencia visual atractiva para los visitantes del sitio web."
      },
    ],
    imagenes: [
      {
        localPath: Marph3,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Marph4,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Marph5,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Marph6,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Marph7,
        width: "100%",
        heigth: "807px"
      },
      [{
        localPath: Marph8,
        width: "50%",
        heigth: "807px"
      },
      {
        localPath: Marph9,
        width: "50%",
        heigth: "807px"
      }],
    ]
  },
  {
    titulo: "PURO",
    cliente: "Puro",
    tags: ['Branding', 'Limpieza', 'Industrial', 'Diseño', 'Identidad'],
    sector: "Servicio de Limpieza",
    descripcion: "PURO SRL, fue fundada por Vilma en 2010 en Córdoba. Se destaca por ofrecer servicios de limpieza de alta calidad gracias a su equipo capacitado y confiable. Una empresa que prioriza la seguridad, la confianza y la formación continua de su equipo para asegurar un servicio de excelencia.",
    desafios: [
      {
        item: "Desarrollar una identidad visual que reflejara sus valores",
        desarrollo: "Optamos por un diseño limpio y simple para transmitir una imagen moderna y actual."
      },
      {
        item: "Transmitir tranquilidad y confianza",
        desarrollo: "Seleccionamos una paleta que creara una atmósfera relajante y serena, generando una sensación de confianza y tranquilidad en los clientes, asociando la marca con un servicio de limpieza confiable y efectivo."
      },
    ],
    imagenes: [
      {
        localPath: Puro3,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Puro4,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Puro5,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Puro6,
        width: "100%",
        heigth: "807px"
      },
      [{
        localPath: Puro7,
        width: "50%",
        heigth: "807px"
      },
      {
        localPath: Puro8,
        width: "50%",
        heigth: "807px"
      }],
      {
        localPath: Puro9,
        width: "100%",
        heigth: "807px"
      }
    ]
  },
  {
    titulo: "SINGULARES",
    cliente: "Singulares",
    tags: ['Key Visual', 'Diseño', 'Identidad', 'Coaching', 'Constultoría'],
    sector: "Consultoría - Coaching",
    descripcion: "Singulares es una empresa dedicada a desarrollar el potencial humano para crear organizaciones de alto impacto. Su misión es alinear el potencial organizacional con su propósito, trabajando tanto en el mindset de empresas como de personas. Enfocados en el desarrollo humano y organizacional, operan desde un paradigma sistémico y son una Empresa B certificada, comprometida con el triple impacto: medioambiental, social y económico.",
    desafios: [
      {
        item: "Transmitir conceptos centrales de la marca",
        desarrollo: "vinculados a la circularidad, la mirada sistémica, el valor humano, la estrategia, flexibilidad y asertividad. Para comunicar estos conceptos, utilizamos el círculo como forma geométrica base. Descomponiendo el círculo de diversas maneras, logramos representar los diferentes sistemas y subsistemas que interactúan entre sí. Este enfoque nos permitió jugar con las formas y sus mitades, visualizando así la complejidad y la interconexión inherente a una mirada sistémica."
      },
      {
        item: "Modernizar su identidad de marca manteniendo su logotipo",
        desarrollo: "El resultado es una identidad visual moderna que refleja la esencia de Singulares: una empresa flexible, asertiva y estratégica, que pone en el centro el valor humano y trabaja de manera integral para generar un impacto positivo y sostenible en todos los ámbitos."
      },
    ],
    imagenes: [
      {
        localPath: Singulares3,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Singulares4,
        width: "100%",
        heigth: "807px"
      },
      [{
        localPath: Singulares5,
        width: "50%",
        heigth: "807px"
      },
      {
        localPath: Singulares6,
        width: "50%",
        heigth: "807px"
      }],
      {
        localPath: Singulares7,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Singulares8,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Singulares9,
        width: "100%",
        heigth: "807px"
      },
    ]
  },
  {
    titulo: "DEL GREDAL",
    cliente: "Del Gredal",
    tags: ['Key Visual', 'Bodega', 'Diseño', 'Identidad', 'Gastronomía'],
    sector: "Producción y comercialización vitivinícola",
    descripcion: "Del Gredal es una bodega que produce vinos de alta calidad en un lugar único y exclusivo, caracterizado por su terroir singular (suelo, microclima y una naturaleza de belleza agreste y austera). La ubicación de la bodega está impregnada de historia y misticismo: situada junto al antiguo camino real, cerca de una histórica posta que fue transitada por figuras ilustres del siglo XIX y en proximidad al Cerro Colorado, un lugar con una rica historia de los pueblos originarios.",
    descripcion2: "El objetivo de este proyecto fue comunicar la mística de este lugar tan especial, así como el respeto por la naturaleza y la historia.",
    desafios: [
      {
        item: "Crear una identidad coherente y consistente",
        desarrollo: "elaboramos patrones y elementos gráficos a partir del imagotipo de la palma. Estos elementos se integraron armoniosamente para reflejar la esencia de la marca."
      },
      {
        item: "Dotar a la marca de elementos gráficos",
        desarrollo: "incorporamos tres figuras visuales claves para enriquecer la identidad visual:",
        lista: [
          "La forma de una uva: Representando la materia prima esencial del producto, simbolizando la calidad y autenticidad del vino.",
          "La forma de una copa: Evocando la experiencia de degustación y la elegancia del vino.",
          "La vista cenital de las viñas: Simplificada morfológicamente para representar la disposición y el cuidado en el cultivo de las viñas."
        ]
      },
      {
        item: "Síntesis gráfica",
        desarrollo: "simplificamos las formas originales mediante el uso de líneas y planos minimalistas. Esto aportó un toque de modernidad y sofisticación al diseño, manteniendo la esencia y la historia de Del Gredal."
      },
    ],
    imagenes: [
      {
        localPath: DelGradal3,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: DelGradal4,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: DelGradal5,
        width: "100%",
        heigth: "807px"
      },
      [{
        localPath: DelGradal6,
        width: "50%",
        heigth: "807px"
      },
      {
        localPath: DelGradal7,
        width: "50%",
        heigth: "807px"
      }],
      {
        localPath: DelGradal8,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: DelGradal9,
        width: "100%",
        heigth: "807px"
      }
    ]
  },
  {
    titulo: "GAINTZA",
    cliente: "Gaintza",
    tags: ['Rebranding', 'Diseño', 'Identidad', 'Bodega', 'Hotelería'],
    sector: "Bodega / Hoteleria",
    descripcion: "Un Key Visual pensado desde la tradición. El reflejo identitario de una bodega familiar con casi 100 años de trayectoria, que fusiona producción de vinos y hotelería boutique en Gretaria.",
    descripcion2: "El objetivo de este proyecto fue comunicar la mística de este lugar tan especial, así como el respeto por la naturaleza y la historia.",
    desafios: [
      {
        item: "",
        desarrollo: "Encontrar un equilibrio entre lo tradicional, la innovación y lo contemporáneo, fusionando dos unidades de negocio en un solo concepto estético."
      },
      {
        item: "Inclusión de la geografía nativa en la identidad",
        desarrollo: "Empleamos formas orgánicas, propias de la naturaleza (nubes, árboles, montañas, rocas...) caracterizadas por su contorno regular y caprichoso. La geografía del lugar y el respeto de la marca por su entorno natural, fueron pilar fundamental en el desarrollo de su identidad visual.",

      },
      {
        item: "Definición de un elemento representativo de la marca",
        desarrollo: "La búsqueda constante de Gaintza por la exelencia, la protección y confiabilidad, guiaron el proceso creativo, orientándolo a la selección de una estrella como elemento gráfico de jerarquía en el diseño."
      },
    ],
    imagenes: [
      {
        localPath: Gaintza3,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Gaintza4,
        width: "100%",
        heigth: "807px"
      },
      [{
        localPath: Gaintza5,
        width: "50%",
        heigth: "807px"
      },
      {
        localPath: Gaintza6,
        width: "50%",
        heigth: "807px"
      }],
      {
        localPath: Gaintza7,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Gaintza8,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: Gaintza9,
        width: "100%",
        heigth: "807px"
      }
    ]
  },
  {
    titulo: "DELIVERY WAP",
    cliente: "Delivery Wap",
    tags: ['Branding', 'Diseño', 'Delivery App', 'Identidad', 'Gastronomía'],
    descripcion: "Delivery Wap es una plataforma digital especialmente diseñada para pequeños y medianos comercios. Ideada para acompañar el crecimiento sostenido de las ventas, de forma eficaz, simple y ordenada.",
    desafios: [
      {
        item: "Diferenciarse en el mercado",
        desarrollo: "Desarrollamos una identidad visual vibrante y llamativa, que captara la atención en un mercado competitivo, sin dejar de reflejar su misión y valores: rapidez, eficiencia y variedad."
      },
      {
        item: "Orientar a sus usuarios",
        desarrollo: "empleamos una tipografía clara y moderna, asegurando la legibilidad y una jerarquía visual que guíara al usuario en la lectura de manera intuitiva",
      }
    ],
    imagenes: [
      {
        localPath: DeliveryWap3,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: DeliveryWap4,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: DeliveryWap5,
        width: "100%",
        heigth: "807px"
      },
      [{
        localPath: DeliveryWap6,
        width: "50%",
        heigth: "807px"
      },
      {
        localPath: DeliveryWap7,
        width: "50%",
        heigth: "807px"
      }],
      {
        localPath: DeliveryWap8,
        width: "100%",
        heigth: "807px"
      },
      {
        localPath: DeliveryWap9,
        width: "100%",
        heigth: "807px"
      }
    ]
  }
]

// export default proyectos;
