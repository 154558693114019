import * as React from 'react';
import PropTypes from 'prop-types';

import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';

export const themeChimpa = createTheme({
    palette: {
        primary: {
            main: '#fddb00', // Your custom color
        },
        accent: {
            main: '#fddb00',
        }
    },
});
function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="white">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  
  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  };
  
  export default function LinearWithValueLabel({progress = 0}) {
   
  
    return (
      <Box sx={{ width: '100%' }}>
        <ThemeProvider theme={themeChimpa}>

        <LinearProgressWithLabel color="accent" value={progress} />
        </ThemeProvider>
      </Box>
    );
  }