import React, { useCallback, useEffect, useState } from "react";
import InputChimpa from "./InputChimpa";
import SearchCountryComponent from "../../shared-components/searchCountryComponent/SearchCountryComponent";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Box, CircularProgress } from "@mui/material";

const ProyectoFormulario = () => {
  const [selectedCountry, setSelectedCountry] = useState({
    nombre: "Argentina",
    phone_code: 54,
    iso2: "ar",
  });
  const [showSearch, setShowSearch] = useState(false);

  const handleChangeShow = (res) => {
    setTimeout(() => {
      setShowSearch(res);
    }, 300);
  };

  const handleChangeSearch = () => {
    setShowSearch(!showSearch);
  };

  const handleSearchInfo = (info) => {
    setSelectedCountry(info);
  };
  const [token, setToken] = useState("");

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    console.log("Now is recaptcha available");

    const token = await executeRecaptcha();
    setToken(token);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();
  const [mensajeEnviado, setMensajeEnviado] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const redirectToGracias = () => {
    window.location.href = "/gracias  "; // Redirect to the 'other' page
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const dataForm = {
      name: data.name,
      email: data.email,
      country: selectedCountry.nombre,
      company: data.email,
      company_position: "-",
      consulta: ["-"],
      message: data.message,
      phone: `(+${selectedCountry.phone_code})` + data.phone,
      "g-recaptcha-response": token,
    };
    if (token) {
      axios
        .post(
          "https://propuestas.chimpance.digital/api/api/Mail/Form",
          dataForm,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response?.data?.status === "200") {
            console.log(response);
            setMensajeEnviado(true);
            setIsLoading(false);
            redirectToGracias();
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h4 className="mb-3 contactanos-titulo">Contáctanos</h4>
      <div className="contenedor-inputs">
        <InputChimpa
          htmlForId={"namegranding"}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M6 6C7.65685 6 9 4.65685 9 3C9 1.34315 7.65685 0 6 0C4.34315 0 3 1.34315 3 3C3 4.65685 4.34315 6 6 6ZM8 3C8 4.10457 7.10457 5 6 5C4.89543 5 4 4.10457 4 3C4 1.89543 4.89543 1 6 1C7.10457 1 8 1.89543 8 3Z"
                fill="#fff"
              />
              <path
                d="M12 11C12 12 11 12 11 12H1C1 12 0 12 0 11C0 10 1 7 6 7C11 7 12 10 12 11ZM11 10.9965C10.9986 10.7497 10.8462 10.0104 10.1679 9.33211C9.51563 8.67985 8.2891 8 5.99999 8C3.71088 8 2.48435 8.67985 1.8321 9.33211C1.15375 10.0104 1.00142 10.7497 1 10.9965H11Z"
                fill="#fff"
              />
            </svg>
          }
          name={"name"}
          placeholder={"Nombre"}
          type={"text"}
          register={{
            ...register("name", {
              required: {
                value: true,
                message: "Este campo es requerido",
              },
              minLength: {
                value: 3,
                message: "Debe tener al menos 3 caracteres",
              },
              maxLength: {
                value: 30,
                message: "Debe tener menos de 30 caracteres",
              },
            }),
          }}
          error={errors?.name?.message ? errors?.name?.message : ""}
        />

        <InputChimpa
          htmlForId={"empresa"}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.7629 0.0746751C14.9103 0.165778 15 0.326712 15 0.500001V15.5C15 15.7761 14.7761 16 14.5 16H11.5C11.2239 16 11 15.7761 11 15.5V14H10V15.5C10 15.7761 9.77614 16 9.5 16H0.5C0.223858 16 0 15.7761 0 15.5V10C0 9.78479 0.137715 9.59372 0.341886 9.52566L6 7.63962V4.5C6 4.31061 6.107 4.13748 6.27639 4.05279L14.2764 0.0527869C14.4314 -0.0247102 14.6155 -0.0164282 14.7629 0.0746751ZM6 8.69371L1 10.3604V15H6V8.69371ZM7 15H9V13.5C9 13.2239 9.22386 13 9.5 13H11.5C11.7761 13 12 13.2239 12 13.5V15H14V1.30902L7 4.80902V15Z"
                fill="#fff"
              />
              <path d="M2 11H3V12H2V11Z" fill="#fff" />
              <path d="M4 11H5V12H4V11Z" fill="#fff" />
              <path d="M2 13H3V14H2V13Z" fill="#fff" />
              <path d="M4 13H5V14H4V13Z" fill="#fff" />
              <path d="M8 9H9V10H8V9Z" fill="#fff" />
              <path d="M10 9H11V10H10V9Z" fill="#fff" />
              <path d="M8 11H9V12H8V11Z" fill="#fff" />
              <path d="M10 11H11V12H10V11Z" fill="#fff" />
              <path d="M12 9H13V10H12V9Z" fill="#fff" />
              <path d="M12 11H13V12H12V11Z" fill="#fff" />
              <path d="M8 7H9V8H8V7Z" fill="#fff" />
              <path d="M10 7H11V8H10V7Z" fill="#fff" />
              <path d="M12 7H13V8H12V7Z" fill="#fff" />
              <path d="M8 5H9V6H8V5Z" fill="#fff" />
              <path d="M10 5H11V6H10V5Z" fill="#fff" />
              <path d="M12 5H13V6H12V5Z" fill="#fff" />
              <path d="M12 3H13V4H12V3Z" fill="#fff" />
            </svg>
          }
          name={"empresa"}
          placeholder={"Empresa"}
          type={"text"}
          register={{
            ...register("empresa", {
              required: {
                value: true,
                message: "Este campo es requerido",
              },
              minLength: {
                value: 3,
                message: "Debe tener al menos 3 caracteres",
              },
              maxLength: {
                value: 30,
                message: "Debe tener menos de 30 caracteres",
              },
            }),
          }}
          error={errors?.empresa?.message}
        />

        <InputChimpa
          htmlForId={"email"}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M15.2 7.05572C15.2 2.89515 12.1283 0 7.71204 0C3.1371 0 0 3.24985 0 7.99521C0 12.8939 3.05307 16 7.87076 16C9.04717 16 10.1769 15.8274 11.0172 15.5303V14.3223C10.429 14.6004 9.16855 14.7825 7.88943 14.7825C3.89337 14.7825 1.31646 12.1462 1.31646 8.05273C1.31646 4.06471 3.94005 1.20791 7.59066 1.20791C11.3253 1.20791 13.8835 3.57579 13.8835 7.03655C13.8835 9.39485 13.1273 10.9191 11.9509 10.9191C11.2786 10.9191 10.8958 10.5261 10.8958 9.855V4.22768H9.486V5.39724H9.33661C8.97248 4.56321 8.13219 4.04554 7.15184 4.04554C5.23784 4.04554 3.9027 5.67525 3.9027 8.00479C3.9027 10.4398 5.20983 12.0791 7.15184 12.0791C8.24423 12.0791 9.08452 11.5327 9.486 10.5548H9.63538C9.74742 11.4943 10.5971 12.1654 11.6894 12.1654C13.8369 12.1654 15.2 10.1809 15.2 7.05572ZM5.39656 8.04314C5.39656 6.36549 6.13415 5.36848 7.38526 5.36848C8.65504 5.36848 9.46732 6.40383 9.46732 8.04314C9.46732 9.68244 8.6457 10.7274 7.36658 10.7274C6.14349 10.7274 5.39656 9.7112 5.39656 8.04314Z"
                fill="#fff"
              />
            </svg>
          }
          name={"email"}
          placeholder={"Email"}
          type={"text"}
          register={{
            ...register("email", {
              required: {
                value: true,
                message: "Este campo es requerido",
              },
            }),
          }}
          error={errors?.email?.message}
        />

        <div className="position-relative d-flex align-items-baseline telefono-contenedor">
          <div
            className="d-flex align-items-center gap-3"
            style={{ width: "110%" }}
          >
            <div className="country-select">
              {selectedCountry ? (
                <div
                  className="position-relative country_char d-flex   align-items-center"
                  style={{ width: "100%" }}
                >
                  <div
                    onClick={handleChangeSearch}
                    className="text-center  position-relative d-flex gap-2"
                    style={{ width: "100%" }}
                  >
                    <span
                      className={`flag-icon flag-icon-${selectedCountry.iso2
                        .toString()
                        .toLowerCase()} px-2`}
                    ></span>
                    <span className="ps-2">
                      (+{selectedCountry.phone_code})
                    </span>
                    <span className="d-flex justify-content-venter align-items-center ps-2 ms-auto">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="7"
                        viewBox="0 0 14 7"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0.646447 0.146447C0.841709 -0.0488155 1.15829 -0.0488155 1.35355 0.146447L7 5.79289L12.6464 0.146447C12.8417 -0.0488155 13.1583 -0.0488155 13.3536 0.146447C13.5488 0.341709 13.5488 0.658291 13.3536 0.853553L7.35355 6.85355C7.15829 7.04882 6.84171 7.04882 6.64645 6.85355L0.646447 0.853553C0.451184 0.658291 0.451184 0.341709 0.646447 0.146447Z"
                          stroke="#fff"
                        />
                      </svg>
                    </span>
                  </div>
                  {showSearch ? (
                    <div className="searchComponent">
                      <SearchCountryComponent
                        showSearch={showSearch}
                        onChangeShow={handleChangeShow}
                        onInfoSended={handleSearchInfo}
                      />
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
            <div className="position-relative  contenedor-telefono">
              <InputChimpa
                htmlForId={"phonebranding"}
                icon={
                  <svg width="16" height="16.012" viewBox="0 0 19 19.012">
                    <defs>
                      <clipPath id="clipPath">
                        <rect
                          id="Rectángulo_4335"
                          data-name="Rectángulo 4335"
                          width="19"
                          height="19.012"
                          fill="#fff"
                        />
                      </clipPath>
                    </defs>
                    <g
                      id="Grupo_5255"
                      data-name="Grupo 5255"
                      transform="translate(-3.358 -2.944)"
                    >
                      <g
                        id="Grupo_4709"
                        data-name="Grupo 4709"
                        transform="translate(3.358 2.944)"
                        clipPath="url(#clipPath)"
                      >
                        <path
                          id="Trazado_17770"
                          data-name="Trazado 17770"
                          d="M14.245,19.012a9.542,9.542,0,0,1-2.489-.582,17.449,17.449,0,0,1-5-2.956A21.559,21.559,0,0,1,1.527,9.311,12.475,12.475,0,0,1,.1,5.558C.062,5.351.041,5.14.009,4.932a.981.981,0,0,1,.167-.638A13.7,13.7,0,0,1,2.137,1.645,17.348,17.348,0,0,1,3.56.3,1.107,1.107,0,0,1,5.266.547,22.583,22.583,0,0,1,7.631,4.423a4.916,4.916,0,0,1,.216.614.955.955,0,0,1-.2.962c-.228.254-.486.482-.737.714-.174.161-.354.317-.542.462a.212.212,0,0,0-.074.286,8.64,8.64,0,0,0,3.2,4.191,8.525,8.525,0,0,0,2.047,1.061.215.215,0,0,0,.286-.072q.329-.408.682-.8a4.958,4.958,0,0,1,.523-.509.905.905,0,0,1,.911-.18,8.19,8.19,0,0,1,1.357.581c1.036.615,2.046,1.276,3.06,1.927a1.246,1.246,0,0,1,.354.34,1.012,1.012,0,0,1,.052,1.347,8.231,8.231,0,0,1-1.406,1.522,14.574,14.574,0,0,1-2.654,1.955,3.37,3.37,0,0,1-.464.185M4.282,1.3c-.066.055-.1.079-.125.1A17.857,17.857,0,0,0,1.465,4.531a.871.871,0,0,0-.139.716A10.925,10.925,0,0,0,2.516,8.479a20.311,20.311,0,0,0,7.469,7.709,10.788,10.788,0,0,0,4.021,1.521.427.427,0,0,0,.289-.065c.716-.538,1.441-1.067,2.13-1.639.453-.377.851-.82,1.286-1.245-.1-.078-.165-.133-.234-.183a23.349,23.349,0,0,0-3.5-2.081.312.312,0,0,0-.456.094c-.384.451-.791.884-1.187,1.325a.574.574,0,0,1-.636.165,8.847,8.847,0,0,1-2.931-1.39A9.606,9.606,0,0,1,5.023,7.616c-.236-.753-.281-.6.306-1.2.365-.37.776-.695,1.17-1.035a.181.181,0,0,0,.053-.266c-.43-.778-.837-1.57-1.285-2.337-.295-.5-.646-.977-.985-1.485"
                          transform="translate(0 0)"
                          fill="#fff"
                        />
                      </g>
                    </g>
                  </svg>
                }
                name={"phone"}
                placeholder={"Teléfono"}
                type={"tel"}
                register={{
                  ...register("phone", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                    minLength: {
                      value: 5,
                      message: "Debe tener al menos 5 caracteres",
                    },
                    maxLength: {
                      value: 30,
                      message: "Debe tener menos de 30 caracteres",
                    },
                  }),
                }}
                error={errors?.phone?.message}
              />
            </div>
          </div>
        </div>
        <div className="position-relative contenedor-textarea">
          <textarea
            className="textarea"
            placeholder=" "
            id="consultaBranding"
            {...register("message", {
              required: {
                value: true,
                message: "Este campo es requerido",
              },
              minLength: {
                value: 3,
                message: "Debe tener al menos 3 caracteres",
              },
              maxLength: {
                value: 100,
                message: "Debe tener menos de 100 caracteres",
              },
            })}
          />
          <label className="fakePlaceholder" htmlFor="consultaBranding">
            Dejanos tu consulta...
          </label>
        </div>
      </div>
      <button className="enviar">
        {isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress
              sx={{
                color: "var(--darkBlue)",
              }}
            />
          </Box>
        ) : (
          "Enviar"
        )}
      </button>

      {mensajeEnviado && (
        <p className="text-center mt-1"> Mensaje enviado exitosamente!</p>
      )}
    </form>
  );
};

export default ProyectoFormulario;
