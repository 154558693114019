import React from 'react'
import { NavLink } from 'react-router-dom';
import './highlight.css'


const Highlight = ({ highlight }) => {

  return (
    <div className="articles__destacado">
      <div className="destacado__contenedor">
        <div className="destacado__contenedor__contenedorDeImagen">
          <img
            src={highlight.mainPhoto}
            className="destacado__contenedorDeImagen__imagen"
            alt={highlight.title}
          />
        </div>

        <div className="destacado__contenedor__lateral">
          <div className="destacado__contenedor__informacion">
            <h4 className="contenedor__informacion__encabezado">Nota destacada</h4>

            <div className="destacado__contenedor__informacion__contenedorDeImagen">
              <img
                src={highlight.mainPhoto}
                className="destacado__contenedor__informacion__contenedorDeImagen__imagen"
                alt={highlight.title}
              />
            </div>
            <small className="articulo__informacion__fechaDePublicacion">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 2048 2048"
                >
                  <path
                    fill="currentColor"
                    d="M1707 911q78 37 141 93t108 127t68 152t24 168q0 123-47 232t-128 190t-190 128t-232 47q-87 0-168-24t-151-68t-127-107t-94-142H0V171h341V0h171v171h683V0h170v171h342zM171 512h1365V341H171zm688 1024q-6-42-6-85q0-124 47-233t128-190t190-128t233-47q43 0 85 6V683H171v853zm592 341q88 0 165-33t136-92t91-135t34-166q0-88-33-166t-91-136t-136-91t-166-34q-89 0-166 33t-136 92t-91 136t-34 166q0 89 33 166t92 135t136 91t166 34m85-512h171v171h-342v-341h171z"
                  ></path>
                </svg>
                {highlight.publicationDate}
              </small>
            <h5 className="contenedor__informacion__titulo">{highlight.title}</h5>
            <p className="contenedor__informacion__entradilla">{highlight.lead}</p>
          </div>

          <div className="destacado__contenedor__botones">


            <NavLink to={`/blog/${highlight.category}/${highlight.slug}`} className="contenedor__botones__link1" target="_blank">
              Leer más
              <svg
                className="articulo__link__icono"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                ></path>
              </svg>
            </NavLink>
            <a href="#form" className="contenedor__botones__link">
              Contáctanos
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Highlight