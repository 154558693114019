import { logo22Svg, logoCinergiaSvg, logoInglobeSvg, logoMcdgSvg } from './../../../assets/img/logos-testimonials'

import { useTranslation } from 'react-i18next'

export default function SectionEleven() {

    const { t } = useTranslation()

    return (
        <section id="text-doble" className="position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 d-flex flex-column align-self-center text-center">
                        <h2 className="wow bounceInUp mb-1">{t('partner.weAre')}</h2>
                        <div className="doble-text position-relative d-flex justify-content-center">
                            <h4 className="fc-lightBlue word w1 fs1">{t('partner.partner')}</h4>
                            <h4 className="fc-lightBlue word w2 fs1">{t('partner.manada')}</h4>
                            <h4 className="fc-lightBlue word w3 fs1">{t('partner.partner')}</h4>
                            <h4 className="fc-lightBlue word w4 fs1">{t('partner.manada')}</h4>
                        </div>
                        <h2 className="mb-4">{t('partner.trustly')}</h2>
                        <h5 
                        className="wow bounceInUp d-none d-md-block" dangerouslySetInnerHTML={{__html: t('partner.text')}}>
                        </h5>
                        <p className="wow bounceInUp d-block d-md-none">Tenemos un proceso de trabajo especial para</p>
                        <h4 className="ff-circularBold fc-lightBlue">{t('partner.agencias')}</h4>
                        <div className="wow bounceInUp d-none d-md-block">
                            <img loading="lazy" src={logoCinergiaSvg} />
                            <img loading="lazy" src={logoMcdgSvg} />
                            <img loading="lazy" src={logo22Svg} />
                            <img loading="lazy" src={logoInglobeSvg} />
                        </div>
                        <div className="mt-3">
                            <a href="https://api.whatsapp.com/send?phone=+5493516503470&amp;text=Hola%20manada!%20😄" className="btn btn-blue-outline-white d-block d-md-none">{t('partner.button')}</a>
                            <a target='_blank' href="https://web.whatsapp.com/send?phone=+5493516503470&amp;text=Hola%20manada!%20😄" className="btn btn-blue-outline-white d-none d-md-block">{t('partner.button')}</a>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
} 