import arrowYellow from './../../../assets/img/arrow-yellow.svg'

export default function SectionSix(){
    return(
        <section id="posicionamiento" className="d-block d-md-none pt-100 pb-100 position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <div className="box text-center">
                            <div className="d-flex justify-content-center align-items-baseline">
                                <img loading={"lazy"} src={arrowYellow} className="d-none d-sm-block" />
                                <h4 className="ms-2 mb-0">Hace más de <span className="ff-circularBold fc-lightBlue">8
                                        años</span> acompañamos la evolución digital de empresas</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}