import React from 'react'
import ContactConversiones from './ContactConversiones'
import './styles.css'
import webVideo from './../../assets/img/web.mp4'
import webVideoMob from './../../assets/img/web-vertical.mp4'



import { useTranslation } from 'react-i18next';

export default function HeaderConversiones({ isMobile }) {

  const { t } = useTranslation();
  return (
    <header id="hero" className=" conversiones-header panel d-flex align-items-center position-relative" >
      <div className="videoGradient"></div>
      <div style={{ width: '100%', height: '100%', objectFit: 'cover', top: 0 }} className="contenedor-video position-absolute">
        <div className="gradiente position-absolute"></div>
        <video muted playsInline autoPlay loop style={{ width: '100%', height: '100%', objectFit: 'cover' }}>
          <source className="embed-responsive-item position-absolute" loading="lazy" src={isMobile ? webVideoMob : webVideo} type="video/mp4" />
        </video>
      </div>
      <div className="container z-2 position-relative">
        <div className="row m-mobile">
          <div className="col-lg-6 col-md-12 d-flex align-items-center">
            <h1 className="d-none">{t('conversions.headerTitle')}</h1>
            <div className="titleconv"></div>

            <h1 className="h3">{t('conversions.headerTitle')}</h1>

            {/* <div className="d-flex mt-5 gap-4 flex-column flex-md-row   ">
              <a className="btn btn-blue-outline-white" href="#slider-desarrollo">Nuestros servicios</a>
            </div> */}
          </div>
          <div className="col-lg-6 col-md-12">
            <ContactConversiones />
          </div>
        </div>
      </div>

    </header>
  )
}
