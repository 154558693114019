import React from "react";
import "./InputChimpaStyles.css";

export default function InputChimpa({
  value,
  type,
  name,
  onChange,
  placeholder,
  icon,
  htmlForId,
  register,
  error,
}) {
  return (
    <div className="position-relative main-container-input">
      <div className="inputChimpaContainer position-relative border-b-inherit">
        <div className="inputIcon">{icon}</div>
        <input
          id={htmlForId}
          name={name}
          placeholder=" "
          value={value}
          type={type}
          onChange={onChange}
          className={`inputChimpa`}
          {...register}
        />
        <label htmlFor={htmlForId} className="fakePlaceholder">
          {placeholder}
        </label>
      </div>
      {error && (
        <span className="text-danger" style={{ bottom: "-25px" }}>
          {error}
        </span>
      )}
    </div>
  );
}
