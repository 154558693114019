import leftArrow from './../../../assets/img/arrows/arrow-left.png'
import rightArrow from './../../../assets/img/arrows/arrow-right.png'

  
export function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, position: 'absolute', zIndex: 999, top: 'calc(50% - 38px)', left: '30px' }}
        onClick={onClick}
      >
        <img src={leftArrow} style={{ width: '38px', borderRadius: '100%', border: '2px solid white', padding: '9px 13px'}} />
    </div>
    );
  }
  
 export function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
        className={className}
        style={{ ...style, position: 'absolute', zIndex: 999, top: 'calc(50% - 38px)', right: '30px' }}
        onClick={onClick}
      >
        <img src={rightArrow} style={{ width: '38px', borderRadius: '100%', border: '2px solid white', padding: '9px 13px'}}/>
    </div>
    );
  }
