import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet'

import Header from '../../layouts/Navbar';
import Footer from '../../layouts/Footer';
import ArticleDetail from '../../components/blog/Article';
import { fetchArticleBySlug, fetchArticles, fetchComments, submitComment } from '../../services/strapiServices';
import Articles from '../../components/blog/Articles';
import Comments from '../../components/blog/Article/Comments';
import CommentForm from '../../components/blog/Article/CommentForm';
import './../../components/blog/Article/article2.css'
import MobileNavbar from '../../layouts/MobileNavbar';
import Loading from '../../components/Loading';
import NotFound from '../NotFound';

export default function ArticlePage({ isMobile }) {
  const { slug } = useParams();

  const [article, setArticle] = useState(null);
  const [articles, setArticles] = useState(null);
  const [error, setError] = useState(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [comments, setComments] = useState({})
  const [sended, setSended] = useState(false)
  const [commentData, setCommentData] = useState({
    content: '',
    autor: '',
    autorEmail: ''
  })

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      submitComment(commentData, article.id)
      setCommentData({
        content: '',
        autor: '',
        autorEmail: '',
      });
      setSended(true);
    } catch (error) {
      console.error('Error submitting comment:', error);
    }
  };




  useEffect(() => {
    const getArticlePage = async () => {
      if (slug) {
        try {
          const result = await fetchArticleBySlug(slug);
          console.log(result)
          if (result.error) {
            setError(result.error);
            return;
          }
          setArticle(result.data);
          const relatedResult = await fetchArticles("", [result.data.category], 1, 3);
          if (relatedResult.error) {
            setError(relatedResult.error);
            console.error(error);
          } else {
            setArticles(relatedResult.data.data);
          }
          const commentResult = await fetchComments(result.data.id);
          if (commentResult.error) {
            setError(commentResult.error);
            console.error(error);
          } else {
            setComments(commentResult.comments)

          }
        } catch (error) {
          setError(error.message);
          console.error(error);
        }
      }
    };

    getArticlePage();
  }, [slug]);
  if (error) {
    return <NotFound isMobile={isMobile}/>;
  }

  if (!article) {
    return <Loading />;
  }

  return (
    <div className='ArticlePage'>
      <Helmet>
        <title>{article.title}</title>
        <meta name="description" content={article.lead} />
        <meta name="keywords" content="Desarrollo Web, Marketing Digital, Branding, Programacion" />
      </Helmet>
      <Header
        isMobile={isMobile} menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen}
      />
      {/* Renderiza componentes para mobile */}
      {isMobile &&
        <MobileNavbar menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />}


      <ArticleDetail
        article={article}
      />

      <div className='article__subscribeAdd container' >
        <p className='article__subscribeAdd__text'>¿Te gustaría recibir nuestro contenido de forma personalizada?</p>
        <a className='article__subscribeAdd__enlace' href=""><img className='article__subscribeAdd__cohete' src='/images/rocket.svg' alt='avion' />Suscribite</a>
        <span className='relatedPostTitle' >Post relacionados</span>
      </div>


      <Articles
        articles={articles}
      />

      <Comments
        comments={comments}
      />
      <CommentForm
        commentData={commentData}
        sended={sended}
        setCommentData={setCommentData}
        handleSubmit={handleSubmit}
      />
      <Footer color='#00214B' />
    </div>
  );
}

