import Lottie from "lottie-react";

import bgHeaderDesk from './../../../assets/img/lotties/bgHeaderDesk.json'
import bgHeaderMobile0 from './../../../assets/img/lotties/bgHeaderMobile0.json'
import bgHeaderMobile1 from './../../../assets/img/lotties/bgHeaderMobile1.json'

import bgParallax2 from './../../../assets/img/bg-parallax-02.png'
import junglaBack from './../../../assets/img/jungla-back.svg'

import React, {useEffect, useRef} from 'react'

export default function SectionOne({ lottieHome }){

    const lottieRef1 = useRef();
    const lottieRef2 = useRef();
    const lottieRef3 = useRef();
  

    useEffect(()=>{
        if(lottieHome){
            lottieRef1.current.play()
            lottieRef2.current.play()
            lottieRef3.current.play()
        }else{
            lottieRef1.current.pause()
            lottieRef2.current.pause()
            lottieRef3.current.pause()
        }
    },[lottieHome])
    return(
        <section id="parallax">
            <div className="container position-relative z-index2">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3 className="rellax" data-rellax-speed="-5">
                            <div className="header-animation">
                                <span className="first">La evolución digital</span>
                                <br />
                                <span className="animation">
                                    <span>que tu empresa necesita</span>
                                </span>
                            </div>
                        </h3>
                    </div>
                </div>
            </div>

            <Lottie lottieRef={lottieRef1} loop={true} className="img rellax d-none d-md-block"  mode={"normal"} autoplay={true} animationData={bgHeaderDesk} />

            <img loading={"lazy"} src={bgParallax2} className="img-02 position-absolute"/>
            <img loading={"lazy"} src={junglaBack} className="img-01 rellax" data-rellax-speed="-3"
                data-rellax-porcentage="3" />

            <Lottie lottieRef={lottieRef2} loop={true} className="rellax position-absolute d-block d-md-none" style={{ width: '100%', height: 'auto', zIndex: 1, bottom: 0 }} mode={"normal"} autoplay={true} animationData={bgHeaderMobile0} />
            <Lottie lottieRef={lottieRef3} loop={true} className="rellax position-absolute d-block d-md-none" style={{width: '100%', height: 'auto', zIndex: 0, bottom: 0}} mode={"normal"} autoplay={true} animationData={bgHeaderMobile1} />
        </section>
    )
}