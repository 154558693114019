import React from 'react'
import image404 from '../assets/img/404.png'
import Navbar from '../layouts/Navbar'
import MobileNavbar from '../layouts/MobileNavbar';

export default function NotFound({ isMobile }) {
    const [menuIsOpen, setMenuIsOpen] = React.useState(false);

    return (
        <div className="w-100 d-flex relative  justify-content-center" style={{ background: '#36A9E1', height: '100vh' }}>
        
                    <Navbar isMobile={isMobile} menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
        
            <h4 className='fc-yellow fs1 text-center mt-150 z-index2 '>Ooops!</h4>
            <img src={image404} alt="" className='position-absolute bottom-0 left-0 w-100' />
        </div>
    )
}
