import AccordionSummary from "@mui/material/AccordionSummary";
import Accordion from "@mui/material/Accordion";

import AccordionDetails from "@mui/material/AccordionDetails";
import React, { useState } from "react";

const ProyectoAcordeon = ({ children, titulo }) => {
  const [isopenAccordeon, setIsopenAccordeon] = useState(false);

  return (
    <Accordion
      onChange={() => setIsopenAccordeon(!isopenAccordeon)}
      sx={{
        "::before": {
          display: "none",
        },
        border: "none",
        margin: 0,
        padding: 0,
        boxShadow: "4px 4px 4px 0px #00000026",
        marginBottom: "10px",
        ".MuiAccordionSummary-content": {
          justifyContent: "space-between",
          alignItems: "center",
        },
      }}
    >
      <AccordionSummary
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          fontSize: 18,
          fontFamily: "Circular Bold",
          color: "#00214B",
          marginTop: "13px",
        }}
      >
        {titulo}
        <span
          className={`accordeon-icon ${isopenAccordeon ? "open" : ""}`}
        ></span>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          color: "#777",
          fontSize: 18,
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default ProyectoAcordeon;
