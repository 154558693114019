import { Col, Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import DesktopGalery from "./desktop";
import GaleryMobile from "./mobile";
import Header from "./../header/index"


import Navbar from "../../layouts/Navbar";
import MobileNavbar from "../../layouts/MobileNavbar";
import ContactComponent from "../../pages/shared-components/contactComponent/ContactComponent";
import Footer from "../../layouts/Footer";

function Galery({ firstTitle, secondTitle }) {
  const [isMobile, setIsMobile] = useState(false)
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  useEffect(() => {
    const handleResize = (ev) => {
      if(ev.target.innerWidth <=800){
        setIsMobile(true)
      }else{
        setIsMobile(false)
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array to ensure the effect runs only once

  return (
    <>
      <Navbar isMobile={isMobile} menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
      <Header
        deskTopText="Construimos tu negocio en la web"
        mobileText="Hacemos monerías, pero no tiramos frutas"
        headerClass="proyectos"
      />
      <Container >
        <Row>
          {
            isMobile ?
            <>
             <MobileNavbar  menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
              <Col md="d-block ">
                <GaleryMobile secondTitle={secondTitle} />
              </Col>
            </>
              :
              <Col className="d-md-block">
                <DesktopGalery firstTitle={firstTitle} secondTitle={secondTitle} />
              </Col>
          }


        </Row>
      </Container>
        <ContactComponent isMobile={isMobile} lottieContact={true} />
        <Footer />
    </>
  );
}

export default Galery;
