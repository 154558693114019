import React, { useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import "./filterDropdown.css";

let active = 0

function FilterDropdown({
  handleChange,
  categories,
  show,
  setShow,
  mobileSize,
}) {
  const menuDrop = useRef(null);


  function openTypeFilter(index ){
    let divs = document.querySelectorAll('.inputs-cont')
    let containerForms = document.querySelectorAll('.containerForm')
    let arrowActive = index
    let arrow = document.querySelectorAll('.bi-chevron-right')
    console.log(index)
    if(window.innerWidth <=768){
      active = index
      arrowActive = index 
    }else{
      active = index
    }
    if(containerForms[active].offsetHeight !== 0){
      containerForms[active].style.height = 0
      arrow[arrowActive].style.transform = 'rotate(90deg)'
    }else{
      containerForms[active].style.height = `${divs[active].offsetHeight + 20}px`
      arrow[arrowActive].style.transform = 'rotate(-90deg)'
    }
  }
  
  useEffect(() => {
    if (mobileSize) {
      const handler = (e) => {
        

        if (!menuDrop.current.contains(e.target)) setShow(false);
      };
      return () => {
        document.removeEventListener("mousedown", handler);

      };
    }
  });

  return (
    <div className={`filter-cont sticky-column ${show ? "heightFixed" : ""}`} ref={menuDrop}>
      <i
        className={`bi bi-sliders filter-icon ${show ? "no-show" : ""}`}
        onClick={() => setShow(true)}
      ></i>
      <div
        ref={menuDrop}
        className={`dropdown-custom ${show ? "show" : "hidden"}  ${
          mobileSize ? "mobileSize" : "generalSize"
        }`}
      >
        <div className="filter-drop-header d-flex justify-content-between align-items-baseline"
        onClick={() => setShow(false)}  >
          <div className="icon-and-header">
            <i
              className="bi bi-sliders inside-icon"
              
            ></i>
            <h6 className="filter-header">Filtros</h6>
          </div>
          <i
            className="bi bi-chevron-left close-icon"
            
          ></i>
        </div>
        <hr className="line-separator" />

        <div className="filter-body">
          <h6 className="filter-by">Filtrar Por</h6>
          {Object.keys(categories).map((filterHeader, index) => (
            <React.Fragment key={index + new Date().getTime}>
              <div  
                    style={{ cursor: 'pointer'}}
                    onClick={() => openTypeFilter(index)} 
                    className="py-2 fs-6 ff-circularBold category-header d-flex justify-content-between"
                    >
                    {filterHeader} 
                  <i className="bi bi-chevron-right" 
                      style={{ transform: index === 0 ? 'rotate(-90deg)' : 'rotate(90deg)',}}></i>
              </div>
              <div className="containerForm" style={{ height: index === 0 ? 'auto' : '0px'}}>
              <Form className="inputs-cont">
                {categories["Tipos de Web"] &&
                  categories[filterHeader].map((category, i) => (
                    <Form.Group
                      key={`${filterHeader}_${i}`}
                      className="mb-3 filter-div"
                      controlId="formBasicCheckbox"
                    >
                      <Form.Check
                        id={`${filterHeader}-${i}`}
                        type="checkbox"
                        label={
                          category === "all"
                            ? "Todos"
                            : category.replaceAll("-", " ")
                        }
                        name="shuffle-filter"
                        className="custom-checkbox"
                        onChange={handleChange}
                        value={category}
                      />
                    </Form.Group>
                  ))}
              </Form>
              </div>
              {index === Object.keys(categories).length - 1 ? (
                <></>
              ) : (
                <hr className="line-separator" />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FilterDropdown;

