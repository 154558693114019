import React, { useState } from 'react'

const MultimediaComponent = ({ alternativeText, file, type }) => {
    const [videoHeight, setVideoHeight] = useState('305px');
  
    const handlePlay = (event) => {
      setVideoHeight('fit-content');
    };
    const handleEnd = () => {
      setVideoHeight('305px');
    };
  
    return (
      <>
        {type === 'photo' ? (
          <div className='article__multimedia__photo'>
          <img className='article__multimedia__photo__imagen' src={file.url} alt={alternativeText} />
        </div>
        ) : (
          <div>
          </div>
        )}
      </>
    );
  };
  

export default MultimediaComponent