import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'owl.carousel/dist/owl.carousel.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'aos/dist/aos.css';
import './index.css';
import './assets/css/style.css';
import { Helmet } from 'react-helmet'
import App from './App';
import { BrowserRouter } from "react-router-dom";
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";

import { translationsChimpa } from './assets/translations';
import 'aos/dist/aos.css'; // Import the AOS CSS
import AOS from 'aos'; // Import the AOS library
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: translationsChimpa, //las translations se encuentran en assets
    fallbackLng: localStorage.getItem("lang") || "es", // Fallback language
    interpolation: {
      escapeValue: false // React already escapes values
    }
  });
AOS.init();
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider
      reCaptchaKey="6LdXxuQnAAAAAEh1kmf6lYHoPAORQdqKmHAjKn8D"
    >
      <BrowserRouter>
        <Helmet>
          <title>Chimpancé | Desarrollo Web y Marketing Digital</title>
          <meta name="description" content="Somos una Empresa de Diseño web ubicada en Argentina, trabajamos para todo el mundo!" />
          <meta name="keywords" content="Desarrollo Web, Marketing Digital, Branding, Programacion" />
        </Helmet>
        <App />
      </BrowserRouter>
    </GoogleReCaptchaProvider>
  </React.StrictMode>
);
