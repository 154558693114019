import React from 'react';

const ContentComponent = ({ content }) => {
  if (!content) return null;

  const renderContent = (contentItem) => {
    switch (contentItem.type) {
      case 'heading':
        const headingText = contentItem.children.map(child => child.text).join('');
        const headingId = headingText.toLowerCase().replace(/\s+/g, '-');
        const Tag = `h${contentItem.level}`;

        return (
          <Tag key={headingId} id={headingText.replace(/\s+/g, '_')}>
            {contentItem.children.map((child, index) => (
              <span key={index} style={{ fontWeight: child.bold ? 'bold' : 'normal' }}>
                {child.text}
              </span>
            ))}
          </Tag>
        );

      case 'paragraph':
        return (
          <p key={Math.random()}>
            {contentItem.children.map((child, index) => (
              <span key={index} style={{ fontWeight: child.bold ? 'bold' : 'normal' }}>
                {child.text}
              </span>
            ))}
          </p>
        );

      case 'quote':
        return (
          <blockquote key={Math.random()}>
            {contentItem.children.map((child, index) => (
              <span key={index}>{child.text}</span>
            ))}
          </blockquote>
        );

      case 'photo':
        return (
          <img
            key={contentItem.file.url}
            src={contentItem.file.url}
            alt={contentItem.file.alternativeText || ''}
          />
        );

      case 'video':
        return (
          <video key={contentItem.file.url} controls>
            <source src={contentItem.file.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );

      default:
        return null;
    }
  };

  return (
    <div>
      {content.map((item, index) => (
        <React.Fragment key={index}>
          {renderContent(item)}
        </React.Fragment>
      ))}
    </div>
  );
};

export default ContentComponent;
