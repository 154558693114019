import React from "react";
import ProyectoFormulario from "./ProyectoFormulario";
import "./ProyectoFooterStyles.css";
import { proyectosNuevos } from "../../../../src/assets/proyectos";
import chimpaLogo from "./../../../assets/img/chimpance-logo.svg";

const ProyectosFooter = () => {
  return (
    <div className="container-footer-brand">
      <h3 className="header">
        Construyamos <br /> el
        <span> Branding </span>
        de
        <span> tu marca</span>
      </h3>
      <div className="form-y-mapa">
        <div className="contenedor-izquierda">
          <ProyectoFormulario />
        </div>
        <div className="contenedor-derecha">
          <div className="contenedor-lista">
            <h4 className="titulo">Mapa de sitio</h4>
            <div className="contenedor-links-sitios">
              <a href="/">Home</a>
              <a href="/desarrollo-web">Diseño y desarrollo web</a>
              <a href="/portfolio-web">Portfolio web</a>
              <a href="/branding">Branding</a>
            </div>
          </div>

          <div className="contenedor-lista">
            <h4 className="titulo">Proyectos Branding</h4>
            <div className="contenedor-links-sitios">
              {proyectosNuevos.reverse().slice(0, 5).map((proyecto) => (
                <a className="capitalize" key={proyecto.titulo + "brandingfoter"} href={`/branding/${proyecto.titulo}`}>
                  {proyecto.titulo.toLowerCase()}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div>
        <img alt="logo Chimpance Digital" className="mt-5" src={chimpaLogo} />
        <h5 className="text-center mt-2"> Córdoba, Argentina </h5>
      </div>
    </div>
  );
};

export default ProyectosFooter;
