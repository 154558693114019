import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ArticleDownload from '../../components/blog/Download';
import { fetchArticleBySlug } from '../../services/strapiServices';


export default function ArticlePage() {
    const { slug } = useParams();
  
    const [article, setArticle] = useState(null);
    const [error, setError] = useState(null);
  
useEffect(() => {
    const getArticlePage = async () => {
      if (slug) {
        try {
          const result = await fetchArticleBySlug(slug);
          if (result.error) {
            setError(result.error);
            return;
          }
          setArticle(result.data);
        } catch (error) {
          setError(error.message);
          console.error(error);
        }
      }
    };

    getArticlePage();
  }, [slug]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!article) {
    return <div>Loading...</div>;
  }

  return (
    <div className='ArticlePage'>
      <ArticleDownload
        article={article}
      />
    </div>
  );
}

