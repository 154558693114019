import Slider from "react-slick";
import './slick.css'
// import './slickTheme.css'
import {PrevArrow, NextArrow} from './arrows'

import React, {  useState, useRef  } from 'react';

import bgSlider1 from './../../../assets/img/mockup/bg-slider-desarrollo-web-01.jpg'
import slider1 from './../../../assets/img/mockup/img-slider-desarrollo-web-01.png'

import bgSlider2 from './../../../assets/img/mockup/bg-slider-desarrollo-web-02.jpg'
import slider2 from './../../../assets/img/mockup/img-slider-desarrollo-web-02.png'

import bgSlider3 from './../../../assets/img/mockup/bg-slider-desarrollo-web-03.jpg'
import slider3 from './../../../assets/img/mockup/img-slider-desarrollo-web-03.png'

import bgSlider4 from './../../../assets/img/mockup/bg-slider-desarrollo-web-04.jpg'
import slider4 from './../../../assets/img/mockup/img-slider-desarrollo-web-04.png'

import bgSlider5 from './../../../assets/img/mockup/bg-slider-desarrollo-web-05.jpg'
import slider5 from './../../../assets/img/mockup/img-slider-desarrollo-web-05.png'
import './styles.css'

export default function CustomSlider(){

    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    const sliderRef = useRef(null);

    const handleTabClick = (slideIndex) => {
      sliderRef.current.slickGoTo(slideIndex);
    };

      const settings = {
        arrows: true,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        afterChange: (current) => setActiveSlideIndex(current)
      };

      const TABS = [
        { label: 'Página web', slideIndex: 0 },
        { label: 'Landing Page', slideIndex: 1 },
        { label: 'eCommerce', slideIndex: 2 },
        { label: 'Plataforma', slideIndex: 3 },
        { label: 'App', slideIndex: 4 },
      ];
    return(
        <section id="slider-desarrollo" className="position-relative d-none d-md-block">
            <div className="container">
                <div className="row">
                    <div className="col-12 position-relative">
                        <ul className="tabs w-100 d-flex justify-content-between">
                            {TABS.map(({ label, slideIndex }) => (
                            <li key={slideIndex} >
                                <button className={`tab ${activeSlideIndex === slideIndex ? 'active' : ''}`} onClick={() => handleTabClick(slideIndex)}>{label}</button>
                            </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <Slider ref={sliderRef} {...settings}  >
                <div className="item slider-item-h" data-hash="pagina-web" style={{height: '100vh'}}>
                    <img src={bgSlider1} className="bg" />
                    <div className="container d-flex align-items-center h-100">
                        <div className="row mt-5 d-flex justify-content-between">
                            <div className="col-md-6 d-flex flex-column align-self-center align-items-start">
                                <h4 className="h1">Creamos tu <span className="fc-lightBlue">Sitio web</span> a tu medida</h4>
                                <h5 className="ff-circularBold mt-2 mb-5">Un sitio intuitivo, rápida y personal</h5>

                                <a href="#form" className="btn btn-blue-outline-white">Quiero digitalizarme</a>
                            </div>
                            <div className="col-md-4 d-flex justify-content-center">
                                <img src={slider1} className="img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item slider-item-h" data-hash="landing-page" style={{height: '100vh'}}>
                    <img src={bgSlider2} className="bg" />
                    <div className="container d-flex align-items-center h-100">
                        <div className="row mt-5 d-flex justify-content-between w-100">
                            <div className="col-md-6 d-flex flex-column align-self-center align-items-start">
                                <h4 className="h1">Diseñamos una<br /><span className="fc-lightBlue">Landing page</span> que enamore</h4>
                                <h5 className="ff-circularBold mt-2 mb-5">Para convertir a tus visitantes, en clientes</h5>
                                <a href="#form" className="btn btn-blue-outline-white">Quiero enamorarme</a>
                            </div>
                            <div className="col-md-4 d-flex justify-content-center">
                                <img src={slider2} className="img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item slider-item-h" data-hash="ecommerce" style={{height: '100vh'}}>
                    <img src={bgSlider3} className="bg" />
                    <div className="container d-flex align-items-center h-100">
                        <div className="row mt-5 d-flex justify-content-between">
                            <div className="col-md-6 d-flex flex-column align-self-center align-items-start">
                                <h4 className="h1">Te ayudamos a despegar con el <span className="fc-lightBlue">e-Commerce</span>
                                    que<br />necesitas</h4>
                                <h5 className="ff-circularBold mt-2 mb-5">Llega a nuevos mercados y genera nuevas estrategias</h5>

                                <a href="#form" className="btn btn-blue-outline-white">Quiero evolucionar</a>
                            </div>
                            <div className="col-md-4 d-flex justify-content-center">
                                <img src={slider3} className="img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item slider-item-h" data-hash="plataforma" style={{height: '100vh'}}>
                    <img src={bgSlider4} className="bg" />
                    <div className="container d-flex align-items-center h-100">
                        <div className="row mt-5 d-flex justify-content-between">
                            <div className="col-md-6 d-flex flex-column align-self-center align-items-start">
                                <h4 className="h1">Desarrollamos tu<br /><span className="fc-lightBlue">plataforma</span> para que no dejes
                                    de crecer</h4>
                                <h5 className="ff-circularBold mt-2 mb-5">Automatización e integración de procesos</h5>

                                <a href="#form" className="btn btn-blue-outline-white">Quiero crecer</a>
                            </div>
                            <div className="col-md-4 d-flex justify-content-center">
                                <img src={slider4} className="img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item slider-item-h" data-hash="app" style={{height: '100vh'}}>
                    <img src={bgSlider5} className="bg" />
                    <div className="container d-flex align-items-center h-100">
                        <div className="row mt-5 d-flex justify-content-between">
                            <div className="col-md-6 d-flex flex-column align-self-center align-items-start">
                                <h4 className="h1">Creamos tu <span className="fc-lightBlue">APP</span> para que la distancia no sea un
                                    problema</h4>
                                <h5 className="ff-circularBold mt-2 mb-5">Vende más, con eficiencia y rapidez</h5>
                                <a href="#form" className="btn btn-blue-outline-white">Consultar</a>
                            </div>
                            <div className="col-md-4 d-flex justify-content-center">
                                <img src={slider5} className="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </section>
    )
}