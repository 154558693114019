import React, { useState } from 'react'
import './multimediaComponent.css'

const MultimediaComponent = ({ alternativeText, file, type }) => {
    const [videoHeight, setVideoHeight] = useState(16/3);
  
    const handlePlay = (event) => {
      setVideoHeight(16/9);
    };
    const handleEnd = () => {
      setVideoHeight(16/3);
    };
  
    return (
      <>
        {type === 'photo' ? (
          <div className='article__multimedia__photo'>
            <img className='article__multimedia__photo__imagen' src={file.url} alt={alternativeText} />
          </div>
        ) : (
          <div className='article__multimedia__video' style={{ aspectRatio: videoHeight,  }}>
            <video
              className='article__multimedia__video__video'
              controls
              src={file.url}
              poster={file.previewUrl}
              onPlay={handlePlay}
              onEnded={handleEnd}
            ></video>
          </div>
        )}
      </>
    );
  };
  

export default MultimediaComponent