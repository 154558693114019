import './Articles.css';
import { NavLink } from 'react-router-dom';

const Articles = ({ articles }) => {

  const calendarSvg = <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 2048 2048"> <path fill="currentColor" d="M1707 911q78 37 141 93t108 127t68 152t24 168q0 123-47 232t-128 190t-190 128t-232 47q-87 0-168-24t-151-68t-127-107t-94-142H0V171h341V0h171v171h683V0h170v171h342zM171 512h1365V341H171zm688 1024q-6-42-6-85q0-124 47-233t128-190t190-128t233-47q43 0 85 6V683H171v853zm592 341q88 0 165-33t136-92t91-135t34-166q0-88-33-166t-91-136t-136-91t-166-34q-89 0-166 33t-136 92t-91 136t-34 166q0 89 33 166t92 135t136 91t166 34m85-512h171v171h-342v-341h171z"></path></svg>
  const diagonalArrowSvg = <svg className="articulo__link__icono" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">  <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"  ></path></svg>

  return (
    <div className="articles__pagina">
      {
        articles == null ? <></> :
          articles && articles.length == 0 ? (
            <div>No se encontraron artículos</div>
          ) : (
            articles.map((article) => (
              <div key={article.id} className="pagina__articulo">

                <NavLink className="articulo__linkDeImagen" to={`/blog/${article.category}/${article.slug}`} target="_blank" rel="noopener noreferrer">
                  <div className="articulo__linkDeImagen__cortina" ></div>

                  <img
                    className="articulo__linkDeImagen__imagen"
                    src={article.mainPhoto}
                    alt={article.title}
                  />

                </NavLink>
                <div className="articulo__informacion">
                  <small className="articulo__informacion__fechaDePublicacion">
                    {calendarSvg}
                    {article.publicationDate}
                  </small>
                  <NavLink to={`/blog/${article.category}/${article.slug}`} target="_blank" className="articulo__informacion__linkDeTitulo" rel="noopener noreferrer">
                    <h5 className="articulo__informacion__titulo">{article.title}</h5>
                  </NavLink>
                  <p className="articulo__informacion__entradilla">{article.lead}</p>
                </div>
                <NavLink to={`/blog/${article.category}/${article.slug}`} target="_blank" rel="noopener noreferrer" className="articulo__link" >
                  <span className="articulo__link__texto">Leer más</span>
                  {diagonalArrowSvg}
                </NavLink>
              </div>
            ))

          )}
    </div>
  );
};

export default Articles;
