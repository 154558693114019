import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../../layouts/Navbar";
import MobileNavbar from "../../../layouts/MobileNavbar";
import ProyectoData from "./ProyectoData";
import { proyectosNuevos } from "../../../../src/assets/proyectos";
import ProyectoGaleria from "./ProyectoGaleria";
import ProyectoFooter from "./ProyectoFooter";
export default function DetalleProyectos() {
  const { ruta } = useParams();

  const proyecto = proyectosNuevos.find(
    (objeto) => objeto.titulo.toLowerCase() === ruta.toLowerCase()
  );

  useEffect(() => {
    window.scrollTo(0, 0); // Ajusta la posición de desplazamiento a (0, 0) para posicionar la ventana en la parte superior
  }, []);

  const [isMobile, setIsMobile] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  useEffect(() => {
    const handleResize = (ev) => {
      if (ev.target.innerWidth <= 800) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array to ensure the effect runs only once

  return (
    <section style={{ overflow: "hidden", backgroundColor: "white" }}>
      <Navbar
        isMobile={isMobile}
        menuIsOpen={menuIsOpen}
        setMenuIsOpen={setMenuIsOpen}
        lightBG={true}
      />
      {isMobile && (
        <MobileNavbar menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
      )}
      <ProyectoData proyecto={proyecto} />
      <ProyectoGaleria imagenes={proyecto.imagenes} />
      <ProyectoFooter/>
    </section>
  );
}
