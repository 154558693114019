import React from "react";

const ArrowLong = ({ className }) => {
  return (
    <svg
      width="57"
      height="12"
      viewBox="0 0 57 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M57 5.70824C56.9176 5.6882 56.9204 5.61192 56.8885 5.55891C56.6857 5.22276 56.3811 5.04305 55.9657 5.03141C55.8882 5.02947 55.8106 5.03141 55.7324 5.03141C48.6113 5.03141 11.0196 5.03141 3.89925 5.03141C3.80441 5.03141 3.70956 5.03141 3.59533 5.03141C3.62164 4.93121 3.70818 4.88725 3.76841 4.83101C4.89826 3.77729 6.02949 2.72552 7.1628 1.67439C7.4591 1.399 7.55811 1.06801 7.44802 0.700183C7.34003 0.340109 7.07072 0.108033 6.67679 0.0252869C6.2801 -0.0581054 5.95264 0.0698921 5.66949 0.333644C4.41087 1.50631 3.1481 2.67509 1.88741 3.84646C1.37925 4.31837 0.870403 4.78899 0.365021 5.26349C-0.119595 5.71859 -0.123749 6.28165 0.365021 6.73546C2.13733 8.38585 3.91172 10.0343 5.68887 11.6802C6.14026 12.0978 6.77095 12.1049 7.1891 11.7106C7.60587 11.3169 7.59133 10.726 7.1441 10.309C6.03295 9.27213 4.91903 8.23781 3.80441 7.20413C3.7331 7.1382 3.64795 7.08519 3.56902 7.02636C3.57941 7.00697 3.59048 6.98693 3.60087 6.96753H3.87294C10.9075 6.96753 48.4126 6.96236 55.4472 6.97594C56.1104 6.97723 56.6774 6.90677 57 6.29005V5.70954V5.70824Z" />
    </svg>
  );
};

export default ArrowLong;
