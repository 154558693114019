import marcadorPortafolio from './../../../assets/img/img-marcador-portfolio.svg'
import bgUx from './../../../assets/img/bg-portfolio-ux.jpg'
import bgUi from './../../../assets/img/bg-portfolio-ui.jpg'
import bgWeb from './../../../assets/img/web.mp4'
import bgBranding from './../../../assets/img/bg-portfolio-branding.jpg'
import bgMkt from './../../../assets/img/bg-portfolio-mkt.jpg'

import React from 'react';

export default function SectionNine(){

    const [isPlaying, setIsPlaying] = React.useState(false);
    React.useEffect(()=>{},[isPlaying])

    return(
        <section id="portfolio" className="d-none d-md-flex position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <ul className="list-group">
                            <li className="me-3 me-md-0">
                                <span>UX
                                    <img loading="lazy" src={marcadorPortafolio} />
                                </span>
                                <img loading="lazy" src={bgUx} />
                            </li>
                            <li className="me-3 me-md-0">
                                <span>UI
                                    <img loading="lazy" src={marcadorPortafolio} />
                                </span>
                                <img loading="lazy" src={bgUi} />
                            </li>
                            <li 
                            onMouseEnter={() => setIsPlaying(true)}
                            onMouseLeave={() => setIsPlaying(false)}
                            className="me-3 me-md-0">
                                <span>WEB
                                    <img loading="lazy" src={marcadorPortafolio} />
                                </span>
                                <video muted id="web-video" loop autoPlay={true}>
                                    <source loading="lazy" src={bgWeb} type="video/mp4" />
                                </video>
                            </li>
                            <li className="me-3 me-md-0">
                                <span>Branding
                                    <img loading="lazy" src={marcadorPortafolio} />
                                </span>
                                <img loading="lazy" src={bgBranding} />
                            </li>
                            <li>
                                <span>MKT Digital
                                    <img loading="lazy" src={marcadorPortafolio} />
                                </span>
                                <img loading="lazy" src={bgMkt} />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}