import React from 'react';

const ContentComponent = ({ content }) => {
  if (!content) return null;

  const renderContent = (contentItem, index) => {
    switch (contentItem.type) {
      case 'heading':
        if (!contentItem.children || contentItem.children.length == 0 ) return (<></>)
        const headingText = contentItem.children.map(child => child.text).join('');
        const headingId = headingText.toLowerCase().replace(/\s+/g, '-');
        const Tag = `h${contentItem.level}`;

        return (
          <Tag key={headingId} id={headingText.replace(/\s+/g, '_')}>
            {contentItem.children.map((child, index) => (
              <span
                key={index}
                style={{
                  fontWeight: child.bold ? 'bold' : 'normal',
                  fontStyle: child.italic ? 'italic' : 'normal',
                  textDecoration: `${child.underline ? 'underline' : ''}${child.strikethrough ? ' line-through' : ''}` || 'none',
                  fontFamily: child.code ? 'monospace' : 'inherit',
                  backgroundColor: child.code ? '#f5f5f5' : 'transparent',
                  padding: child.code ? '0.2em 0.4em' : '0',
                  borderRadius: child.code ? '4px' : '0',
                  border: child.code ? '1px solid #ddd' : 'none',
                }}
              >
                {child.text}
              </span>
            ))}
          </Tag>
        );

      case 'paragraph':
        if (!contentItem.children || contentItem.children.length == 0 ) return (<></>)
        return (
          <p key={index}>
            {contentItem.children.map((child, index) => (
              <span
                key={index}
                style={{
                  fontWeight: child.bold ? 'bold' : 'normal',
                  fontStyle: child.italic ? 'italic' : 'normal',
                  textDecoration: `${child.underline ? 'underline' : ''}${child.strikethrough ? ' line-through' : ''}` || 'none',
                  fontFamily: child.code ? 'monospace' : 'inherit',
                  backgroundColor: child.code ? '#f5f5f5' : 'transparent',
                  padding: child.code ? '0.2em 0.4em' : '0',
                  borderRadius: child.code ? '4px' : '0',
                  border: child.code ? '1px solid #ddd' : 'none',
                }}
              >
                {child.text}
              </span>
            ))}
          </p>
        );

      case 'quote':
        if (!contentItem.children || contentItem.children.length == 0 ) return (<></>)
        return (
          <blockquote key={index}>
            {contentItem.children.map((child, index) => (
              <span
                key={index}
                style={{
                  fontWeight: child.bold ? 'bold' : 'normal',
                  fontStyle: child.italic ? 'italic' : 'normal',
                  textDecoration: `${child.underline ? 'underline' : ''}${child.strikethrough ? ' line-through' : ''}` || 'none',
                  fontFamily: child.code ? 'monospace' : 'inherit',
                  backgroundColor: child.code ? '#f5f5f5' : 'transparent',
                  padding: child.code ? '0.2em 0.4em' : '0',
                  borderRadius: child.code ? '4px' : '0',
                  border: child.code ? '1px solid #ddd' : 'none',
                }}
              >
                {child.text}
              </span>
            ))}
          </blockquote>
        );

      case 'list':
        if (!contentItem.children || contentItem.children.length == 0 ) return (<></>)
        const ListTag = contentItem.format === 'unordered' ? 'ul' : 'ol';
        return (
          <ListTag key={index}>
            {contentItem.children.map((listItem, itemIndex) => (
              <li key={itemIndex}>
                {listItem.children.map((child, childIndex) => (
                  <span
                    key={childIndex}
                    style={{
                      fontWeight: child.bold ? 'bold' : 'normal',
                      fontStyle: child.italic ? 'italic' : 'normal',
                      textDecoration: `${child.underline ? 'underline' : ''}${child.strikethrough ? ' line-through' : ''}` || 'none',
                      fontFamily: child.code ? 'monospace' : 'inherit',
                      backgroundColor: child.code ? '#f5f5f5' : 'transparent',
                      padding: child.code ? '0.2em 0.4em' : '0',
                      borderRadius: child.code ? '4px' : '0',
                      border: child.code ? '1px solid #ddd' : 'none',
                    }}
                  >
                    {child.text}
                  </span>
                ))}
              </li>
            ))}
          </ListTag>
        );

      case 'photo':
        return (
          <img
            key={index}
            src={contentItem.file.data.attributes.url}
            alt={contentItem.file.data.attributes.alternativeText || ''}
          />
        );

      case 'video':
        return (
          <video key={index} controls>
            <source src={contentItem.file.data.attributes.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );

      case 'link':
        if (!contentItem.children || contentItem.children.length == 0 ) return (<></>)
        return (
          <a key={index} href={contentItem.url}>
            {contentItem.children.map((child, childIndex) => (
              <span
                key={childIndex}
                style={{
                  fontWeight: child.bold ? 'bold' : 'normal',
                  fontStyle: child.italic ? 'italic' : 'normal',
                  textDecoration: `${child.underline ? 'underline' : ''}${child.strikethrough ? ' line-through' : ''}` || 'none',
                  fontFamily: child.code ? 'monospace' : 'inherit',
                  backgroundColor: child.code ? '#f5f5f5' : 'transparent',
                  padding: child.code ? '0.2em 0.4em' : '0',
                  borderRadius: child.code ? '4px' : '0',
                  border: child.code ? '1px solid #ddd' : 'none',
                }}
              >
                {child.text}
              </span>
            ))}
          </a>
        );

      default:
        return null;
    }
  };

  return (
    <div>
      {content.map((item, index) => (
        <React.Fragment key={index}>
          {renderContent(item, index)}
        </React.Fragment>
      ))}
    </div>
  );
};

export default ContentComponent;
