import React, { useEffect, useState } from 'react';
import './commentForm.css'

const CommentForm = ({ commentData, sended, setCommentData, handleSubmit }) => {
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    // Validación simple para verificar que todos los campos estén llenos
    const { content, autor, autorEmail } = commentData;
    if (content && autor && autorEmail) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [commentData]);

  return (
    <div className="container">
      <form className="article__formulario" onSubmit={handleSubmit}>
        <span className="article__formulario__titulo">Deja tu comentario</span>
        <small className="article__formulario__info">Tu dirección de email no será publicada</small>
        <label className="article__formulario__label" htmlFor="content">Comentario</label>
        <textarea
          className="article__formulario__content"
          type="text"
          value={commentData.content}
          onChange={(e) => setCommentData({ ...commentData, content: e.target.value })}
        ></textarea>
        <label className="article__formulario__label" htmlFor="autor">Nombre</label>
        <input
          className="article__formulario__autor"
          type="text"
          value={commentData.autor}
          onChange={(e) => setCommentData({ ...commentData, autor: e.target.value })}
        />
        <label className="article__formulario__label" htmlFor="autorEmail">Correo Electrónico</label>
        <input
          className="article__formulario__autorEmail"
          type="email"
          value={commentData.autorEmail}
          onChange={(e) => setCommentData({ ...commentData, autorEmail: e.target.value })}
        />
        <button className={isFormValid ? "article__formulario__button__valid" : "article__formulario__button__invalid"} disabled={!isFormValid}>
          {isFormValid ? 'Enviar' : sended? 'Gracias':'Dejanos tu comentario'}
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 1024 1024">
            <path fill="currentColor" d="M1004.03 0c-6.096 0-13.52 1.73-22.096 5.361L27.278 410.45c-34.368 14.577-36.544 42.689-4.832 62.449l269.76 168.032c31.712 19.744 73.648 62.08 93.184 94.047l161.712 264.768c9.28 15.184 20.496 22.72 31.28 22.72c11.92 0 23.28-9.152 31.025-27.232L1017.663 41.49C1028.718 15.617 1022.415 0 1004.03 0M325.552 583.922L106.896 447.713l733.616-311.248L368.32 616.657c-14.432-12.8-29.088-24.224-42.768-32.735M572.72 915.265l-130.432-213.52c-7.696-12.609-17.856-26.05-29.185-39.393l474.384-482.384z" />
          </svg>
        </button>
      </form>
    </div>
  );
};

export default CommentForm;
