import { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet'

import Navbar from '../../layouts/Navbar'
import Footer from '../../layouts/Footer'
import NavbarBlog from '../../components/blog/NavbarBlog'
import Articles from '../../components/blog/Articles';
import Pagination from '../../components/blog/Pagination';
import Highlight from '../../components/blog/Highlight';
import { fetchArticles, fetchHilightArticle } from '../../services/strapiServices';
import ContactComponent from '../shared-components/contactComponent/ContactComponent';

import MobileNavbar from '../../layouts/MobileNavbar';


// Pagina principal.
export default function Blog({ isMobile }) {

  // Formulario.
  const [categories, setCategories] = useState([]);
  const [keyword, setKeyword] = useState("");

  // Resultados.
  const [articles, setArticles] = useState([]);
  const [highlightArticle, setHighlightArticle] = useState(false);

  // Posibles erorres.
  const [error, setError] = useState(1)

  // Paginacion.
  const [pagination, setPagination] = useState([{ page: 1, active: true }]);

  // Estados de la barra.
  const [collapse, setCollapse] = useState(false)
  const [loading, setLoading] = useState(false)

  const [lottieContact, setLottieContact] = useState(false);
  const [lottieHome, setLottieHome] = useState(true);
  const [lottieJungle, setLottieJungle] = useState(false);

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const articlesRef = useRef(null);
  const sectionContact = useRef()
  const sectionThreeRef = useRef()
  const sectionLottieJunglaRef = useRef()

  // Efecto para LottieHome, LottieContact y LottieJungle.
  useEffect(() => {
    let lastScrollTop = 0;

    const handleScroll = (event) => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      // Detect when is in section three, to pause Hero lottie
      if (sectionThreeRef.current) {
        if (sectionThreeRef.current.getBoundingClientRect().y < -40) {
          if (lottieHome) setLottieHome(false);
        } else {
          if (!lottieHome) setLottieHome(true);
        }
      }

      // Detect to play/pause Contact lottie
      if (sectionContact.current) {
        if (sectionContact.current.getBoundingClientRect().y < 1000) {
          if (!lottieContact) setLottieContact(true);
        }

        if (sectionContact.current.getBoundingClientRect().y >= 1000) {
          if (lottieContact) setLottieContact(false);
        }
      }

      if (sectionLottieJunglaRef.current) {
        if (sectionLottieJunglaRef.current.getBoundingClientRect().y < 1300) {
          setLottieJungle(true);
        } else {
          setLottieJungle(false);
        }
      }

      // Check if scrolling up when at the top
      if (scrollTop === 0 && lastScrollTop > scrollTop) {
        setCollapse(false);
      }

      lastScrollTop = scrollTop;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lottieHome, lottieContact]);

  // manejo de las categorias seleccionadas para la peticion inmediata de las categorias seleccionadas.
  const handleCategoryChange = async (category) => {
    try {
      setLoading(true);

      let updatedCategories;
      if (category === "") {
        updatedCategories = [];
      } else {
        updatedCategories = categories.includes(category)
          ? categories.filter(cat => cat !== category)
          : [...categories, category];
      }
      // Actualizas el estado de categorías con las categorias calculadas
      setCategories(updatedCategories);

      const result = await fetchArticles(keyword, updatedCategories, 1);
      setArticles(result.data.data);
      setPagination(result.data.meta.pagination);

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);

    }
  };

  //Manejo de la palabra clave para la proxima busqueda.
  const handleKeywordChange = (e) => {
    setKeyword(e.target.value);
  };

  //Manejo del cambio de pagina para la navegacion de resultados
  const handlePageChange = async (e) => {
    const result = await fetchArticles(keyword, categories, e.target.value);
    if (result.error) {
      console.error('Error fetching articles:', result.error);
    } else {
      setArticles(result.data.data);
      setPagination(result.data.meta.pagination);
    }
  };

  //Manejo de la busqueda con filtro de categorias y palabra clave
  const handleSearchSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    const result = await fetchArticles(keyword, categories, 1);
    if (result.error) {
      console.error('Error fetching articles:', result.error);
    } else {
      setArticles(result.data.data);
      setPagination(result.data.meta.pagination);
      setLoading(false)
      setCollapse(true)
    }
  };

  // Efecto para la busqueda automatica de resultados y articulo destacado al montarse la pagina principal.
  useEffect(() => {
    const getArticles = async () => {
      setLoading(true);
      try {
        const result = await fetchArticles("", []);
        const highlightResult = await fetchHilightArticle();

        if (result.error) {
          setError(result.error);
          return <div>Error: {error}</div>;
        } else {
          setArticles(result.data.data);
          setHighlightArticle(highlightResult.data);
          setPagination(result.data.meta.pagination);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);

      }
    };
    getArticles();
  }, []);


  // Muestro el Navbar de Chimpance Digital, al cual le paso las props menuIsOpen, setMenuIsOpen y isMobil.
  // Muestro el NavbarBlog, al cual le paso las categorias seleccionadas; y las funciones para: seleccionar categorias, añadir una palabra clave y disparar la busqueda filtrada.
  // Tambien añado Articles que reciven los resultados, la nota destacada y la la paginacion para la navegacion de resultados.
  // Siguiente, muestro un div con useRef para ContactComponent.
  // por ultimo agrego el footer.
  return (
    <>
      <Helmet>
        <title>Chimpancé | Blog</title>
        <meta name="description" content="Somos una Empresa de Diseño web ubicada en Argentina, trabajamos para todo el mundo!" />
        <meta name="keywords" content="Desarrollo Web, Marketing Digital, Branding, Programacion" />
      </Helmet>

      <Navbar
        isMobile={isMobile} menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen}
      />

      {/* Renderiza componentes para mobile */}
      {isMobile &&
        <MobileNavbar menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />}

      <NavbarBlog
        categories={categories}
        collapse={collapse}
        loading={loading}
        categoryChange={handleCategoryChange}
        keywordChange={handleKeywordChange}
        searchSubmit={handleSearchSubmit}
      />
      <article id="articles" ref={articlesRef} className='w-100'>
        <Articles
          articles={articles}
        />
      </article>

      {highlightArticle && <Highlight
        highlight={highlightArticle}
      />}

      <Pagination
        pagination={pagination}
        pageChange={handlePageChange}
      />

      <div ref={sectionContact}>
        <ContactComponent lottieContact={lottieContact} isMobile={isMobile} />
      </div>
      <Footer />
    </>
  );
}