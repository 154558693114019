import React from 'react';
import './comments.css'

const Comments = ({ comments }) => {
  return (
    <div className="article__comentarios container">
      <span className="article__comentarios__titulo">Comentarios</span>
      {comments && comments.length > 0 ? (
        comments.map(comment => (
          <div key={comment.id} className='article__comentarios__comment'>
            <div className='article__comentarios__comment__frame'>
              <img
                className='article__comentarios__comment__frame__avatar'
                src="/images/defaultUser.svg"
                alt={`${comment.autor}'s avatar`}
              />
            </div>
            <div className='article__comentarios__comment__box'>
              <span className="article__comentarios__comment__box__autor">{comment.autor}</span>
              <p className="article__comentarios__comment__box__content">{comment.content}</p>
              <small className="article__comentarios__comment__box__date">{comment.date}</small>
            </div>
          </div>
        ))
      ) : (
        <div>No hay comentarios</div>
      )}
    </div>
  );
};

export default Comments;
