
import MobileNavbar from '../../layouts/MobileNavbar'
import Footer from '../../layouts/Footer'
import Navbar from '../../layouts/Navbar'
import React from 'react'
import PrivacyText from './PrivacyText';

export default function PrivacyPolicy({isMobile}){
    const [menuIsOpen, setMenuIsOpen] = React.useState(false);
    return(
        <>
            <Navbar isMobile={isMobile} menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
            
                {isMobile ?
                    <>

                        {/* Renderiza componentes para mobile */}
                        <MobileNavbar  menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
                        <PrivacyText/>
                    </>
                        :
                    <>
                        {/* Renderiza componentes para desktop */}
                        <PrivacyText/>
                    </>
                }

            <Footer />
        </>
    )
}