import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSpring, animated } from "react-spring";

import axios from "axios";
import Lottie from "lottie-react";

import success_img from "../../../assets/img/contact_success.png";
import cohete from "../../../assets/img/lotties/cohete.json";
import arrowForm from "./../../../assets/img/icon-arrow-form.svg";
import phoneForm from "./../../../assets/img/icon-phone-form.svg";
import { useInputField } from "../../../customHooks/validations";

import "./ContactComponent.css";
import "flag-icon-css/css/flag-icons.min.css";

import { FormControlLabel, FormGroup, Checkbox } from "@mui/material";

import SearchCountryComponent from "../searchCountryComponent/SearchCountryComponent";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import ReactPixel from "react-facebook-pixel";
import { useTranslation } from "react-i18next";
import { numberPhone } from "../../../utils/configChimpa";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export default function ContactComponent({ isMobile, lottieContact }) {
  const [startAnimation, setStartAnimation] = useState(false);
  const { t } = useTranslation();
  const [buttonClicked, setButtonClicked] = useState(false);
  const [succesSend, setSuccesSend] = useState(false);
  const [coheteHeight, setHeight] = useState(0);
  const [formHeight, setHeightForm] = useState(0);
  const [checkBoxes, changeCheck] = useState([
    "contactForm.option1",
    "contactForm.option2",
    "contactForm.option3",
    "contactForm.option4",
  ]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [submitLoading, setSubmitLoader] = useState(false);

  const [formValid, setFormValid] = useState({
    name: false,
    email: false,
    company: false,
    company_position: false,
    consulta: false,
    message: false,
    phone: false,
  });

  const coheteRef = useRef(null);
  const buttonSubmit = useRef();
  const btnLaunchRef = useRef();
  const titleCardRef = useRef();
  const bgFormRef = useRef();
  const coheteLottieRef = useRef(null);
  const formRef = useRef(null);
  const cardFormRef = useRef(null);
  const sendAnimationRef = useRef(null);
  const lottieSendRef = useRef(null);

  const [selectedCountry, setSelectedCountry] = useState({
    nombre: "Argentina",
    phone_code: 54,
    iso2: "ar",
  });
  const [showSearch, setShowSearch] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const redirectToGracias = () => {
    window.location.href = "/gracias  "; // Redirect to the 'other' page
  };

  // form validations
  const nameInput = useInputField("", (value) => value.trim() !== "");
  const emailInput = useInputField("", (value) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
  );
  const phoneInput = useInputField("", (value) => value.trim() !== "");
  const messageInput = useInputField("", (value) => value.trim() !== "");
  const companyInput = useInputField("", (value) => value.trim() !== "");
  const cargoInput = useInputField("", (value) => value.trim() !== "");

  // funciones componente hijo
  const handleSearchInfo = (info) => {
    setSelectedCountry(info);
  };

  const handleChangeShow = (res) => {
    setTimeout(() => {
      setShowSearch(res);
    }, 300);
  };
  const areAllPropertiesTrue = (obj) => {
    for (let key in obj) {
      if (!obj[key]) {
        return false;
      }
    }
    return true;
  };

  const handleChangeSearch = () => {
    setShowSearch(!showSearch);
  };

  // Fin de componente hijo

  // Checkboxes

  useEffect(() => {
    setFormValid({
      name: nameInput.isValid && !nameInput.isEmpty,
      email: emailInput.isValid && !emailInput.isEmpty,
      phone: phoneInput.isValid && !phoneInput.isEmpty,
      consulta: selectedCheckboxes.length > 0,
      company_position: cargoInput.isValid && !cargoInput.isEmpty,
      company: companyInput.isValid && !companyInput.isEmpty,
      message: messageInput.isValid && !messageInput.isEmpty,
    });
    setDisabled(!areAllPropertiesTrue(formValid));
  }, [
    nameInput,
    emailInput,
    phoneInput,
    selectedCheckboxes,
    messageInput,
    companyInput,
    cargoInput,
  ]);

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const checked = event.target.checked;

    if (checked) {
      setSelectedCheckboxes((prevState) => [...prevState, value]);
    } else {
      setSelectedCheckboxes((prevState) =>
        prevState.filter((checkbox) => checkbox !== value)
      );
    }
  };

  const getData = () => {
    fetch("code_country.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then(function (response) {
      return response.json();
    });
  };

  const subirCohete = () => {
    if (!buttonClicked) {
      if (typeof window !== "undefined" && typeof window.fbq === "function") {
        window.fbq("track", "ViewContent", {
          event: "ViewContent",
          contents: [{ coheteForm: "clickeado" }],
        });
      }

      setStartAnimation(true);

      setTimeout(() => {
        window.scrollBy(0, 300);
      }, 1200);
      setButtonClicked(true);
    }
  };

  const upRocket = useSpring({
    transform: startAnimation
      ? isMobile
        ? `translateY(-${coheteHeight}px)`
        : `translateY(-${coheteHeight}px)`
      : "translateY(0px)",
    delay: 500,
    config: {
      duration: 500,
    },
  });

  const showForm = useSpring({
    height: startAnimation ? `${formHeight}px` : "0px",

    delay: 0,
    config: {
      duration: 500,
    },
  });

  const [token, setToken] = useState("");

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    console.log("Now is recaptcha available");

    const token = await executeRecaptcha();
    setToken(token);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  useEffect(() => {
    setHeight(coheteRef.current.getBoundingClientRect().height);
    setHeightForm(cardFormRef.current.getBoundingClientRect().height);
  }, []);

  useEffect(() => {
    getData();

    if (lottieContact) {
      coheteLottieRef.current.play();
    } else {
      coheteLottieRef.current.pause();
    }
  }, [lottieContact]);

  function handleSubmit(event) {
    event.preventDefault();
    setSubmitLoader(true);
    buttonSubmit.current.disabled = true;
    const formData = new FormData(event.target);

    const jsonData = {
      name: formData.get("name"),
      email: formData.get("email"),
      country: selectedCountry.nombre,
      company: formData.get("empresa"),
      company_position: formData.get("cargo"),
      consulta: selectedCheckboxes,
      message: formData.get("message"),
      phone: `(+${selectedCountry.phone_code})` + formData.get("phone"),
      "g-recaptcha-response": token,
    };
    console.log(jsonData);

    if (token) {
      axios
        .post(
          "https://propuestas.chimpance.digital/api/api/Mail/Form",
          jsonData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          formRef.current.style.display = "none";
          sendAnimationRef.current.style.display = "flex";
          sendAnimationRef.current.classList.remove("d-none");
          titleCardRef.current.style.display = "none !important";
          titleCardRef.current.classList.remove("d-md-block");
          bgFormRef.current.style["padding-bottom"] = "0px";

          ReactPixel.trackCustom("Contact", jsonData);

          setTimeout(() => {
            redirectToGracias();
          }, 400);
        })
        .catch((error) => {
          setSubmitLoader(false);
          buttonSubmit.current.disabled = false;
          btnLaunchRef.current.scrollIntoView({ behavior: "smooth" });
          console.log(error);
        });
    }
  }

  return (
    <section id="form" className="pb-0 pt-100">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-md-10 d-flex flex-column align-items-center align-self-end">
            <h3 className="text-center mb-5 ">{t("contactForm.ready")}</h3>
            <button
              onClick={() => subirCohete()}
              ref={btnLaunchRef}
              className={`mb-5 btn-power btn ${
                buttonClicked ? "active" : null
              }`}
              disabled={buttonClicked}
            >
              <svg
                width="132"
                height="132"
                viewBox="0 0 132 132"
                className="start"
              >
                <defs>
                  <filter
                    id="Trazado_12261"
                    x="0"
                    y="0"
                    width="132"
                    height="132"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood floodOpacity="0.486" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                </defs>
                <g
                  id="Grupo_5823"
                  data-name="Grupo 5823"
                  transform="translate(-892 -8571)"
                >
                  <g
                    transform="matrix(1, 0, 0, 1, 892, 8571)"
                    filter="url(#Trazado_12261)"
                  >
                    <path
                      id="Trazado_12261-2"
                      data-name="Trazado 12261"
                      d="M57,0A57,57,0,1,1,0,57,57,57,0,0,1,57,0Z"
                      transform="translate(9 6)"
                      fill="#fff"
                    />
                  </g>
                  <g
                    id="Grupo_5785"
                    data-name="Grupo 5785"
                    transform="translate(15.309 -63)"
                  >
                    <path
                      className="color bg-color"
                      id="Trazado_12260"
                      data-name="Trazado 12260"
                      d="M363.995,49.991A49.991,49.991,0,1,1,314,0a49.991,49.991,0,0,1,49.991,49.99"
                      transform="translate(628.678 8646.999)"
                      fill="#fddb00"
                    />
                    <path
                      id="off"
                      d="M30.386,60.772A30.292,30.292,0,0,1,4.065,45.638,29.832,29.832,0,0,1,0,30.386a29.971,29.971,0,0,1,7.835-20.3q.177-.177.682-.712t.831-.861a3.681,3.681,0,0,1,.89-.623,2.456,2.456,0,0,1,1.158-.3,3.65,3.65,0,0,1,2.671,1.128,3.65,3.65,0,0,1,1.128,2.671,5.949,5.949,0,0,1-.208,1.661,2.359,2.359,0,0,1-.446.95l-.177.3a22.612,22.612,0,0,0-4.985,7.3A22.058,22.058,0,0,0,7.6,30.386a22.319,22.319,0,0,0,3.057,11.425,22.616,22.616,0,0,0,8.309,8.309,22.88,22.88,0,0,0,22.849,0A22.616,22.616,0,0,0,50.12,41.81a22.8,22.8,0,0,0,1.275-20.237,22.969,22.969,0,0,0-4.926-7.328,3.9,3.9,0,0,1-.89-2.85,3.65,3.65,0,0,1,1.128-2.671A3.65,3.65,0,0,1,49.377,7.6a2.728,2.728,0,0,1,.8.118,3.99,3.99,0,0,1,.623.238,3.128,3.128,0,0,1,.623.474,5.315,5.315,0,0,1,.5.5q.118.15.533.623t.474.533A30.212,30.212,0,0,1,58.368,42.2,29.732,29.732,0,0,1,42.2,58.368a29.808,29.808,0,0,1-11.81,2.4Zm0-30.386a3.748,3.748,0,0,1-3.8-3.8V3.8a3.624,3.624,0,0,1,1.128-2.7,3.727,3.727,0,0,1,2.7-1.1,3.644,3.644,0,0,1,2.671,1.1,3.667,3.667,0,0,1,1.1,2.7v22.79a3.748,3.748,0,0,1-3.8,3.8Z"
                      transform="translate(912.295 8666.604)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </svg>
            </button>
          </div>
        </div>
        <div className="container-form-cohete2" style={{ overflow: "hidden" }}>
          <animated.div
            className="container-cohete-form"
            ref={coheteRef}
            style={upRocket}
          >
            <div
              id="coheteForm"
              className="col-md-12 d-flex flex-column align-items-center cohete-form-container"
            >
              <Lottie
                lottieRef={coheteLottieRef}
                loop={true}
                mode={"normal"}
                autoplay={true}
                animationData={cohete}
                background="transparent"
                speed={"1"}
                style={{
                  width: "910px",
                  height: "auto",
                  zIndex: "1",
                  transition: "all 0.5s ease-out",
                }}
                className="rocket"
              />
            </div>
            <animated.div
              className="row d-flex justify-content-center content-form"
              style={showForm}
              id="form-contact"
            >
              <div ref={cardFormRef} className="col-md-12 col-lg-8 ">
                <div className="bg-form " ref={bgFormRef}>
                  <div
                    className="containerText d-none d-md-block"
                    ref={titleCardRef}
                  >
                    <div className=" d-md-flex align-items-baseline justify-content-center">
                      <img
                        loading="lazy"
                        width="19.738"
                        height="19.733"
                        src={arrowForm}
                      />
                      <h3 className="fc-darkBlue-01 fs-32 text-center ms-2">
                        {t("contactForm.deskTitle")}
                      </h3>
                    </div>
                    <p className="fs-18 ff-circularBook fc-darkBlue-01 text-center mb-4 mt-0 mt-md-3 mt-md-0  d-md-block">
                      {t("contactForm.weRead1")}
                    </p>
                    <div className=" d-md-flex justify-content-center mb-4 flex-md-wrap gap-md-2">
                      <a href="mailto:contacto@chimpancedigital.com.ar">
                        <div className="d-flex me-4">
                          <svg
                            className="mx-3"
                            width="23.33"
                            height="17.376"
                            viewBox="0 0 23.33 17.376"
                            style={{ verticalAlign: "sub" }}
                          >
                            <path
                              id="envelope-line"
                              d="M23.872,6H3.458A1.453,1.453,0,0,0,2,7.448V21.929a1.453,1.453,0,0,0,1.458,1.448H23.872a1.453,1.453,0,0,0,1.458-1.448V7.448A1.453,1.453,0,0,0,23.872,6ZM22.749,21.929H4.668l5.1-5.242L8.722,15.68,3.458,21.089V8.549L12.52,17.5a1.465,1.465,0,0,0,2.056,0l9.3-9.181v12.67l-5.366-5.329-1.028,1.021ZM4.413,7.448H22.691l-9.142,9.029Z"
                              transform="translate(-2 -6)"
                              fill="#0075a9"
                            ></path>
                          </svg>
                          <p className="fc-darkBlue-01 mb-0">
                            contacto@chimpancedigital.com.ar
                          </p>
                        </div>
                      </a>

                      <a
                        target="_blank"
                        className="d-block d-md-none"
                        href={
                          "https://api.whatsapp.com/send?phone=+" +
                          numberPhone +
                          "&amp;text=Buenos%20días,%20quiero%20mas%20info%20sobre%20desarrollo%20web"
                        }
                      >
                        <div className="d-flex ms-4">
                          <img
                            loading="lazy"
                            src={phoneForm}
                            width="21.847"
                            height="21.839"
                            className="me-3"
                          />
                          <p className="fc-darkBlue-01 mb-0">
                            +54 9 351 650-3470
                          </p>
                        </div>
                      </a>
                      <a
                        target="_blank"
                        className="d-none d-md-block"
                        href={
                          "https://web.whatsapp.com/send?phone=+" +
                          numberPhone +
                          "&amp;text=Buenos%20días,%20quiero%20mas%20info%20sobre%20desarrollo%20web"
                        }
                      >
                        <div className="d-flex ms-4">
                          <img
                            alt="icon-phone"
                            src={phoneForm}
                            width="21.847"
                            height="21.839"
                            className="me-3"
                          />
                          <p className="fc-darkBlue-01 mb-0">
                            +54 9 351 650-3470
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="bg-form-input">
                    <form
                      id="form-cohete"
                      method="post"
                      ref={formRef}
                      style={{ height: "auto" }}
                      onSubmit={handleSubmit}
                    >
                      {/* Name */}
                      {nameInput.isEmpty && nameInput.first && (
                        <span style={{ color: "red" }}>
                          {t("contactForm.required")}
                        </span>
                      )}
                      <div className="input-group input-group-lg mb-4 position-relative d-flex align-items-baseline campos">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <path
                            d="M6 6C7.65685 6 9 4.65685 9 3C9 1.34315 7.65685 0 6 0C4.34315 0 3 1.34315 3 3C3 4.65685 4.34315 6 6 6ZM8 3C8 4.10457 7.10457 5 6 5C4.89543 5 4 4.10457 4 3C4 1.89543 4.89543 1 6 1C7.10457 1 8 1.89543 8 3Z"
                            fill="#00214B"
                          />
                          <path
                            d="M12 11C12 12 11 12 11 12H1C1 12 0 12 0 11C0 10 1 7 6 7C11 7 12 10 12 11ZM11 10.9965C10.9986 10.7497 10.8462 10.0104 10.1679 9.33211C9.51563 8.67985 8.2891 8 5.99999 8C3.71088 8 2.48435 8.67985 1.8321 9.33211C1.15375 10.0104 1.00142 10.7497 1 10.9965H11Z"
                            fill="#00214B"
                          />
                        </svg>
                        <input
                          value={nameInput.value}
                          onChange={nameInput.handleChange}
                          onBlur={nameInput.handleBlur}
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          placeholder={t("contactForm.nameAndLast")}
                          required
                        />
                      </div>
                      {/* Empresa   */}
                      <div className="input-group input-group-lg mb-4 position-relative d-flex align-items-baseline campos">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="16"
                          viewBox="0 0 15 16"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.7629 0.0746751C14.9103 0.165778 15 0.326712 15 0.500001V15.5C15 15.7761 14.7761 16 14.5 16H11.5C11.2239 16 11 15.7761 11 15.5V14H10V15.5C10 15.7761 9.77614 16 9.5 16H0.5C0.223858 16 0 15.7761 0 15.5V10C0 9.78479 0.137715 9.59372 0.341886 9.52566L6 7.63962V4.5C6 4.31061 6.107 4.13748 6.27639 4.05279L14.2764 0.0527869C14.4314 -0.0247102 14.6155 -0.0164282 14.7629 0.0746751ZM6 8.69371L1 10.3604V15H6V8.69371ZM7 15H9V13.5C9 13.2239 9.22386 13 9.5 13H11.5C11.7761 13 12 13.2239 12 13.5V15H14V1.30902L7 4.80902V15Z"
                            fill="#00214B"
                          />
                          <path d="M2 11H3V12H2V11Z" fill="#00214B" />
                          <path d="M4 11H5V12H4V11Z" fill="#00214B" />
                          <path d="M2 13H3V14H2V13Z" fill="#00214B" />
                          <path d="M4 13H5V14H4V13Z" fill="#00214B" />
                          <path d="M8 9H9V10H8V9Z" fill="#00214B" />
                          <path d="M10 9H11V10H10V9Z" fill="#00214B" />
                          <path d="M8 11H9V12H8V11Z" fill="#00214B" />
                          <path d="M10 11H11V12H10V11Z" fill="#00214B" />
                          <path d="M12 9H13V10H12V9Z" fill="#00214B" />
                          <path d="M12 11H13V12H12V11Z" fill="#00214B" />
                          <path d="M8 7H9V8H8V7Z" fill="#00214B" />
                          <path d="M10 7H11V8H10V7Z" fill="#00214B" />
                          <path d="M12 7H13V8H12V7Z" fill="#00214B" />
                          <path d="M8 5H9V6H8V5Z" fill="#00214B" />
                          <path d="M10 5H11V6H10V5Z" fill="#00214B" />
                          <path d="M12 5H13V6H12V5Z" fill="#00214B" />
                          <path d="M12 3H13V4H12V3Z" fill="#00214B" />
                        </svg>
                        <input
                          type="text"
                          className="form-control"
                          name="empresa"
                          id="empresa"
                          placeholder={t("contactForm.company")}
                          required
                          value={companyInput.value}
                          onChange={companyInput.handleChange}
                          onBlur={companyInput.handleBlur}
                        />
                      </div>

                      {/* Cargo   */}
                      <div className="input-group input-group-lg mb-4 position-relative d-flex align-items-baseline campos">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="13"
                          viewBox="0 0 16 13"
                          fill="none"
                        >
                          <path
                            d="M6.5 0C5.67173 0 5 0.671169 5 1.49976V2H1.5C0.671573 2 0 2.67157 0 3.5V11.5C0 12.3284 0.671573 13 1.5 13H14.5C15.3284 13 16 12.3284 16 11.5V3.5C16 2.67157 15.3284 2 14.5 2H11V1.49976C11 0.671169 10.3283 0 9.5 0H6.5ZM6.5 1H9.5C9.7763 1 10 1.22463 10 1.50061V2H6V1.49976C6 1.22377 6.2237 1 6.5 1ZM8.38649 7.91441L15 6.15081V11.5C15 11.7761 14.7761 12 14.5 12H1.5C1.22386 12 1 11.7761 1 11.5V6.15081L7.61351 7.91441C7.86674 7.98194 8.13326 7.98194 8.38649 7.91441ZM1.5 3H14.5C14.7761 3 15 3.22386 15 3.5V5.11586L8.12883 6.94817C8.04442 6.97068 7.95558 6.97068 7.87117 6.94817L1 5.11586V3.5C1 3.22386 1.22386 3 1.5 3Z"
                            fill="#00214B"
                          />
                        </svg>
                        <input
                          type="text"
                          className="form-control"
                          name="cargo"
                          id="cargo"
                          placeholder={t("contactForm.position")}
                          value={cargoInput.value}
                          onChange={cargoInput.handleChange}
                          onBlur={cargoInput.handleBlur}
                          required
                        />
                      </div>

                      {/* Emaill */}
                      {(!emailInput.isValid || emailInput.isEmpty) &&
                        emailInput.first && (
                          <span style={{ color: "red" }}>
                            {t("contactForm.requred_email")}
                          </span>
                        )}
                      <div className="input-group input-group-lg mb-4 position-relative d-flex align-items-baseline campos">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M15.2 7.05572C15.2 2.89515 12.1283 0 7.71204 0C3.1371 0 0 3.24985 0 7.99521C0 12.8939 3.05307 16 7.87076 16C9.04717 16 10.1769 15.8274 11.0172 15.5303V14.3223C10.429 14.6004 9.16855 14.7825 7.88943 14.7825C3.89337 14.7825 1.31646 12.1462 1.31646 8.05273C1.31646 4.06471 3.94005 1.20791 7.59066 1.20791C11.3253 1.20791 13.8835 3.57579 13.8835 7.03655C13.8835 9.39485 13.1273 10.9191 11.9509 10.9191C11.2786 10.9191 10.8958 10.5261 10.8958 9.855V4.22768H9.486V5.39724H9.33661C8.97248 4.56321 8.13219 4.04554 7.15184 4.04554C5.23784 4.04554 3.9027 5.67525 3.9027 8.00479C3.9027 10.4398 5.20983 12.0791 7.15184 12.0791C8.24423 12.0791 9.08452 11.5327 9.486 10.5548H9.63538C9.74742 11.4943 10.5971 12.1654 11.6894 12.1654C13.8369 12.1654 15.2 10.1809 15.2 7.05572ZM5.39656 8.04314C5.39656 6.36549 6.13415 5.36848 7.38526 5.36848C8.65504 5.36848 9.46732 6.40383 9.46732 8.04314C9.46732 9.68244 8.6457 10.7274 7.36658 10.7274C6.14349 10.7274 5.39656 9.7112 5.39656 8.04314Z"
                            fill="#00214B"
                          />
                        </svg>
                        <input
                          value={emailInput.value}
                          onChange={emailInput.handleChange}
                          onBlur={emailInput.handleBlur}
                          type="email"
                          className="form-control"
                          name="email"
                          id="mail"
                          placeholder={t("contactForm.email")}
                          required
                        />
                      </div>
                      {/* telefono */}
                      <div className="input-group input-group-lg mb-4 position-relative d-flex align-items-baseline campos">
                        <div className="row" style={{ width: "110%" }}>
                          <div className="col-md-3 col-5 d-flex align-items-end">
                            {selectedCountry ? (
                              <div className="position-relative country_char">
                                <div
                                  onClick={handleChangeSearch}
                                  className="text-center fw-bold fc-darkBlue  position-relative"
                                >
                                  <span
                                    className={`flag-icon me-2 flag-icon-${selectedCountry.iso2
                                      .toString()
                                      .toLowerCase()} px-2`}
                                  ></span>
                                  <span className="ps-2">
                                    (+{selectedCountry.phone_code})
                                  </span>
                                  <span className="px-2 flecha">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="7"
                                      viewBox="0 0 14 7"
                                      fill="none"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M0.646447 0.146447C0.841709 -0.0488155 1.15829 -0.0488155 1.35355 0.146447L7 5.79289L12.6464 0.146447C12.8417 -0.0488155 13.1583 -0.0488155 13.3536 0.146447C13.5488 0.341709 13.5488 0.658291 13.3536 0.853553L7.35355 6.85355C7.15829 7.04882 6.84171 7.04882 6.64645 6.85355L0.646447 0.853553C0.451184 0.658291 0.451184 0.341709 0.646447 0.146447Z"
                                        fill="#00214B"
                                      />
                                    </svg>
                                  </span>
                                </div>
                                {showSearch ? (
                                  <div className="searchComponent">
                                    <SearchCountryComponent
                                      showSearch={showSearch}
                                      onChangeShow={handleChangeShow}
                                      onInfoSended={handleSearchInfo}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-md-9 col-7 telefono position-relative">
                            {phoneInput.isEmpty && phoneInput.first && (
                              <label style={{ color: "red" }}>
                                {t("contactForm.required")}
                              </label>
                            )}
                            <div className="input-group input-group-lg  position-relative d-flex align-items-end">
                              <svg
                                width="19"
                                height="19.012"
                                viewBox="0 0 19 19.012"
                                style={{
                                  verticalAlign: "sub",
                                  margin: "10px 7px",
                                }}
                              >
                                <defs>
                                  <clipPath id="clipPath">
                                    <rect
                                      id="Rectángulo_4335"
                                      data-name="Rectángulo 4335"
                                      width="19"
                                      height="19.012"
                                      fill="#005081"
                                    />
                                  </clipPath>
                                </defs>
                                <g
                                  id="Grupo_5255"
                                  data-name="Grupo 5255"
                                  transform="translate(-3.358 -2.944)"
                                >
                                  <g
                                    id="Grupo_4709"
                                    data-name="Grupo 4709"
                                    transform="translate(3.358 2.944)"
                                    clipPath="url(#clipPath)"
                                  >
                                    <path
                                      id="Trazado_17770"
                                      data-name="Trazado 17770"
                                      d="M14.245,19.012a9.542,9.542,0,0,1-2.489-.582,17.449,17.449,0,0,1-5-2.956A21.559,21.559,0,0,1,1.527,9.311,12.475,12.475,0,0,1,.1,5.558C.062,5.351.041,5.14.009,4.932a.981.981,0,0,1,.167-.638A13.7,13.7,0,0,1,2.137,1.645,17.348,17.348,0,0,1,3.56.3,1.107,1.107,0,0,1,5.266.547,22.583,22.583,0,0,1,7.631,4.423a4.916,4.916,0,0,1,.216.614.955.955,0,0,1-.2.962c-.228.254-.486.482-.737.714-.174.161-.354.317-.542.462a.212.212,0,0,0-.074.286,8.64,8.64,0,0,0,3.2,4.191,8.525,8.525,0,0,0,2.047,1.061.215.215,0,0,0,.286-.072q.329-.408.682-.8a4.958,4.958,0,0,1,.523-.509.905.905,0,0,1,.911-.18,8.19,8.19,0,0,1,1.357.581c1.036.615,2.046,1.276,3.06,1.927a1.246,1.246,0,0,1,.354.34,1.012,1.012,0,0,1,.052,1.347,8.231,8.231,0,0,1-1.406,1.522,14.574,14.574,0,0,1-2.654,1.955,3.37,3.37,0,0,1-.464.185M4.282,1.3c-.066.055-.1.079-.125.1A17.857,17.857,0,0,0,1.465,4.531a.871.871,0,0,0-.139.716A10.925,10.925,0,0,0,2.516,8.479a20.311,20.311,0,0,0,7.469,7.709,10.788,10.788,0,0,0,4.021,1.521.427.427,0,0,0,.289-.065c.716-.538,1.441-1.067,2.13-1.639.453-.377.851-.82,1.286-1.245-.1-.078-.165-.133-.234-.183a23.349,23.349,0,0,0-3.5-2.081.312.312,0,0,0-.456.094c-.384.451-.791.884-1.187,1.325a.574.574,0,0,1-.636.165,8.847,8.847,0,0,1-2.931-1.39A9.606,9.606,0,0,1,5.023,7.616c-.236-.753-.281-.6.306-1.2.365-.37.776-.695,1.17-1.035a.181.181,0,0,0,.053-.266c-.43-.778-.837-1.57-1.285-2.337-.295-.5-.646-.977-.985-1.485"
                                      transform="translate(0 0)"
                                      fill="#005081"
                                    />
                                  </g>
                                </g>
                              </svg>
                              <input
                                value={phoneInput.value}
                                onChange={phoneInput.handleChange}
                                onBlur={phoneInput.handleBlur}
                                type="number"
                                className="form-control"
                                id="phone"
                                name="phone"
                                placeholder={t("contactForm.phone")}
                                required
                              />
                            </div>
                            <span></span>
                          </div>
                        </div>
                      </div>
                      {/* Preguntas Checkbox */}
                      <div className=" mt-3 checkbox_preguntas ">
                        <p className="pregunta">{t("contactForm.help")}</p>
                        <FormGroup className="checkboxes">
                          {checkBoxes.map((checkbox, index) => (
                            <div className={index} key={index + "index"}>
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    checked={selectedCheckboxes.includes(
                                      t(checkbox)
                                    )}
                                    onChange={handleCheckboxChange}
                                    value={t(checkbox)}
                                  />
                                }
                                label={t(checkbox)}
                              />
                            </div>
                          ))}
                        </FormGroup>
                      </div>
                      {/* Message */}
                      <div
                        className="custom_consulta input-group input-group-lg pt-4 mb-4 position-relative  d-flex align-items-baseline campos"
                        id="select2"
                      >
                        <textarea
                          value={messageInput.value}
                          onChange={messageInput.handleChange}
                          onBlur={messageInput.handleBlur}
                          name="message"
                          id="message"
                          className="bg-transparent border-0 form-control "
                          cols="30"
                          rows="1"
                          placeholder={t("contactForm.message")}
                        ></textarea>
                      </div>

                      {/* Send */}
                      <div className="d-grid gap-2 pt-3">
                        <button
                          type="submit"
                          className="btn btn-send"
                          ref={buttonSubmit}
                          disabled={disabled}
                        >
                          {submitLoading ? (
                            <Box sx={{ display: "flex" }}>
                              <CircularProgress
                                sx={{
                                  color: "#E8F0FB",
                                }}
                              />
                            </Box>
                          ) : (
                            <span className="sendText">
                              {t("contactForm.send")}
                              <svg
                                className="ms-3"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                              >
                                <path
                                  style={{ fill: "#fff !important" }}
                                  id="send"
                                  d="M19.122,11.447,5.388,4.571a.618.618,0,0,0-.88.7L6.355,12,4.483,18.717a.636.636,0,0,0,.905.719L19.122,12.56a.625.625,0,0,0,0-1.112ZM6.075,17.7l1.379-5.07h5.768v-1.25H7.454L6.075,6.309,17.442,12Z"
                                  transform="translate(-4.461 -4.505)"
                                  fill="#fff"
                                />
                              </svg>
                            </span>
                          )}
                        </button>
                      </div>
                    </form>

                    <div
                      id="contact_form"
                      className="align-items-center d-none fc-darkBlue-01 flex-column justify-content-center "
                      ref={sendAnimationRef}
                    >
                      <h4 className="fs1">{t("contactForm.success")}</h4>
                      <p>
                        {t("contactForm.weContact")}
                        <strong>{t("contactForm.evolve")}</strong>
                      </p>
                      <img src={success_img} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </animated.div>
          </animated.div>
        </div>
        <div id="box-form"></div>
      </div>
    </section>
  );
}
