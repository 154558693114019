import React, { useState } from 'react';
import './pagination.css';

const Pagination = ({ pagination, pageChange }) => {
  const [startPage, setStartPage] = useState(0);
  const maxVisiblePages = 4;
  const arrowSvg = <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M228 128a12 12 0 0 1-12 12H69l51.52 51.51a12 12 0 0 1-17 17l-72-72a12 12 0 0 1 0-17l72-72a12 12 0 0 1 17 17L69 116h147a12 12 0 0 1 12 12"/></svg>

  const handlePrevious = () => {
    if (startPage > 0) {
      setStartPage(startPage - 1);
    }
  };

  const handleNext = () => {
    if (startPage + maxVisiblePages < pagination.length) {
      setStartPage(startPage + 1);
    }
  };

  return (
    <div className="articles__paginacion">
      {pagination.length > maxVisiblePages && (
        <button className="paginacion__flechaIzquierda" onClick={handlePrevious}>
          {arrowSvg}
        </button>
      )}
      {pagination &&
        pagination
          .slice(startPage, startPage + maxVisiblePages)
          .map(({ page, active }, i) => (
            <button
              key={i}
              className={
                active
                  ? 'paginacion__boton__seleccionado'
                  : 'paginacion__boton'
              }
              value={page}
              onClick={pageChange}
            >
              {page}
            </button>
          ))}
      {pagination.length > maxVisiblePages && (
        <button className="paginacion__flechaDerecha" onClick={handleNext}>
          {arrowSvg}
        </button>
      )}
    </div>
  );
};

export default Pagination;