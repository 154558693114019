import SectionThree from './../desarrollo-web/section_3/SectionThree'
import SectionFourteen from './../desarrollo-web/section_14/SectionFourteen'
import SectionFifteen from './../desarrollo-web/section_15/SectionFifteen'
import Footer from '../../layouts/Footer'
import CustomSliderMobile from './../desarrollo-web/section_2/CustomSliderMobile'
import ContactComponent from '../shared-components/contactComponent/ContactComponent'

import React, { useEffect, useRef } from 'react'


import bgMobil1 from './../../assets/mockup/bg-slider-mobile-web.jpg'
import bgSlider1 from './../../assets/img/mockup/bg-slider-desarrollo-web-01.jpg'
import slider1 from './../../assets/img/mockup/img-slider-desarrollo-web-01.png'



import bgMobil3 from './../../assets/mockup/bg-slider-mobile-ecommerce.jpg'
import bgSlider3 from './../../assets/img/mockup/bg-slider-desarrollo-web-03.jpg'
import slider3 from './../../assets/img/mockup/img-slider-desarrollo-web-03.png'

import bgMobil4 from './../../assets/mockup/bg-slider-mobile-plataformas.jpg'
import bgSlider4 from './../../assets/img/mockup/bg-slider-desarrollo-web-04.jpg'
import slider4 from './../../assets/img/mockup/img-slider-desarrollo-web-04.png'

import bgMobil5 from './../../assets/mockup/bg-slider-mobile-app.jpg'
import bgSlider5 from './../../assets/img/mockup/bg-slider-desarrollo-web-05.jpg'
import slider5 from './../../assets/img/mockup/img-slider-desarrollo-web-05.png'
import './styles.css'
import '../desarrollo-web/desarrolloweb.css'
import ServiciosAtom from './ServiciosAtom'
import HeaderConversiones from './HeaderConversiones'

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import MobileNavbarConv from './MobileConv'
import NavbarConv from './NavbarConv'
import ServiciosMConversiones from './ServiciosMConversiones'
// Register ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

export default function DesarrolloConversiones({ isMobile, isTable }) {
    const [menuIsOpen, setMenuIsOpen] = React.useState(false);
    const [isAutoScrolling, setIsAutoScrolling] = React.useState(false);
    const containerConversionsRef = useRef(null);
    const section1Ref = useRef(null);
    const services = [
        {
            title: 'slidersServices.web.title',
            bgSlider: bgSlider1,
            subtitle: 'slidersServices.web.paragraph',
            button: 'slidersServices.web.button',
            image: slider1,
            class: "web",
            name: 'slidersServices.web.name',
            bgMobile: bgMobil1
        },
        {
            title: 'slidersServices.eCommerce.title',
            bgSlider: bgSlider3,
            subtitle: 'slidersServices.eCommerce.paragraph',
            button: 'slidersServices.eCommerce.button',
            image: slider3,
            class: "eCommerce",
            name: 'slidersServices.eCommerce.name',
            bgMobile: bgMobil3
        },
        {
            title: 'slidersServices.platform.title',
            bgSlider: bgSlider4,
            subtitle: 'slidersServices.platform.paragraph',
            button: 'slidersServices.platform.button',
            image: slider4,
            class: "platform",
            name: 'slidersServices.platform.name',
            bgMobile: bgMobil4
        },
        {
            title: 'slidersServices.app.title',
            bgSlider: bgSlider5,
            subtitle: 'slidersServices.app.paragraph',
            button: 'slidersServices.app.button',
            image: slider5,
            class: "app",
            name: 'slidersServices.app.name',
            bgMobile: bgMobil5
        }
    ]
    useEffect(() => {
        if (!isMobile) {

            gsap.defaults({
                ease: 'power2',
            });

            ScrollTrigger.defaults({
                toggleActions: 'restart pause resume pause',
                scroller: '.containerConversions',
            });
          



        }




    }, []);

    const handleScroll = () => {
        

        window.scrollTo({
          top: 0,
          behavior: 'auto', // You can change this to 'smooth' for smooth scrolling
        });
    

};

    

   

    return (
        <>
            <NavbarConv isMobile={isMobile} menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
            {isMobile ?
                <>
                    <div className="section-1 h-screen"></div>
                    <div className="section-2 h-screen"></div>
                    <div className="section-3 h-screen"></div>
                    {/* Renderiza componentes para mobile */}
                    <MobileNavbarConv menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
                    <HeaderConversiones isMobile={isMobile}/>
                    <ServiciosMConversiones services={services}></ServiciosMConversiones>
                    {/* <CustomSliderMobile /> */}
                    <SectionThree />

                    <div >
                        <ContactComponent isMobile={isMobile} lottieContact={true} />
                    </div>

                </>
                :
                <>
                    {/* Renderiza componentes para desktop */}
                    <section onMouseEnter={handleScroll}  id="slider-desarrollo" className='scrollT' style={{height:'100vh', maxHeight:'100vh', width:'100%'}} ref={section1Ref}>
                        <div className="containerConversions position-absolute" style={{top:0, width:'100%'}} ref={containerConversionsRef}>
                        <HeaderConversiones isMobile={isMobile}/>
                            {
                                services.map((service, index) =>
                                (
                                    <ServiciosAtom
                                        key={index}
                                        service={service}
                                    />
                                ))
                            }
                        </div>

                    </section>
                    <SectionThree />
                    <SectionFourteen />
                    <SectionFifteen />
                    <div >
                        <ContactComponent isMobile={isMobile} lottieContact={true} />
                    </div>
                </>
            }
            <div className='bg-darkBlue zIndex2'>

            <Footer />
            </div>
        </>
    )
}