import Traslaqua from "../../../assets/img/branding/Traslaqua/1-traslaqua.webp";
import Sebastian from "../../../assets/img/branding/Sebastian/1-sebastian.webp";
import Aapre from "../../../assets/img/branding/Aapre/1-aapre.webp";
import ZonaInmo from "../../../assets/img/branding/Zonainmo/1-zonainmo.webp";
import Airfort from "../../../assets/img/branding/Airfort/1-airfort.webp";
import LaMatilde from "../../../assets/img/branding/La-matilde/1-lamatilde.webp";
import Marph from "../../../assets/img/branding/Marph/1-marph.webp";
import Paracol from "../../../assets/img/branding/Paracol/1-paracol.webp";
import Indisual from "../../../assets/img/branding/Indisual/1-indisual.webp";
import Puro from "../../../assets/img/branding/Puro/1-puro.webp";
import Singulares from "../../../assets/img/branding/Singulares/1-singulares.webp";
import DelGredal from "../../../assets/img/branding/Del-gredal/1-delgredal.webp";
import Gaintza from "../../../assets/img/branding/Gaintza/1-gaintza.webp";
import DeliveryWap from "../../../assets/img/branding/DeliveryWap/1-deliverywap.webp";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function SectionTwo() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const delay = setTimeout(() => {
      setIsVisible(true);
    }, 600);

    return () => {
      clearTimeout(delay);
    };
  }, []);
  return (
    <section className="d-none d-sm-block">
      <div className="container">
        <div className="row mb-4 marginRowBrandingTablet">
          <div className="col-md-8 d-flex flex-column justify-content-center">
            <div style={{ position: "relative" }}>
              <div className="gradient">
                {isVisible && (
                  <div className="z-index2 position-absolute w-100 bottom30 px-5 miElemento">
                    <p className="font-circularBlack fw-bold fs-4 ">Branding</p>
                    <div className="d-flex justify-content-between">
                      <span className="ff-circularLight">Traslaaqua</span>
                      <span className=" ">
                        <Link
                          to="/branding/TRASLAAQUA"
                          className="link-light border-bottom arrowActive"
                        >
                          Ver más{" "}
                          <svg
                            id="Grupo_2257"
                            className="mt--5 arrowTransition"
                            data-name="Grupo 2257"
                            xmlns="http://www.w3.org/2000/svg"
                            xlinkHref="http://www.w3.org/1999/xlink"
                            width="25"
                            height="12"
                            viewBox="0 0 29.967 14.518"
                          >
                            <defs>
                              <clipPath id="clipPath">
                                <rect
                                  id="Rectángulo_1972"
                                  data-name="Rectángulo 1972"
                                  width="29.967"
                                  height="14.518"
                                  fill="#fff"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Grupo_2255"
                              data-name="Grupo 2255"
                              clipPath="url(#clipPath)"
                            >
                              <path
                                id="Trazado_9345"
                                data-name="Trazado 9345"
                                d="M29.967,7.493a5.877,5.877,0,0,1-.582.822q-2.89,2.9-5.8,5.784a1.171,1.171,0,1,1-1.687-1.617q1.893-1.894,3.794-3.78c.073-.073.143-.15.259-.273H1.319A1.194,1.194,0,0,1,.053,7.6,1.168,1.168,0,0,1,1.086,6.092c.107-.008.215,0,.322,0H25.947c-.112-.118-.184-.2-.26-.274q-1.9-1.89-3.8-3.778a1.149,1.149,0,0,1-.327-1.2,1.106,1.106,0,0,1,.884-.805A1.167,1.167,0,0,1,23.561.4q2.124,2.118,4.254,4.231c.526.523,1.064,1.034,1.57,1.575a7.084,7.084,0,0,1,.582.822Z"
                                transform="translate(0 0)"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </Link>
                      </span>
                    </div>
                  </div>
                )}

                <img
                  loading="lazy"
                  src={Traslaqua}
                  className="img-branding"
                  width="100%"
                  alt="branding Traslaqua"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex flex-column justify-content-center">
            <div style={{ position: "relative" }}>
              <div className="gradient">
                {isVisible && (
                  <div className="z-index2 position-absolute w-100 bottom30 px-5 miElemento">
                    <p className="font-circularBlack fw-bold fs-4">Branding</p>
                    <div className="d-flex justify-content-between">
                      <span className="ff-circularLight">
                        Sebastian Martinez
                      </span>
                      <span className=" ">
                        <Link
                          to="/branding/SEBASTIAN MARTINEZ"
                          className="link-light border-bottom arrowActive"
                        >
                          Ver más{" "}
                          <svg
                            id="Grupo_2257"
                            className="mt--5 arrowTransition"
                            data-name="Grupo 2257"
                            xmlns="http://www.w3.org/2000/svg"
                            xlinkHref="http://www.w3.org/1999/xlink"
                            width="25"
                            height="12"
                            viewBox="0 0 29.967 14.518"
                          >
                            <defs>
                              <clipPath id="clipPath">
                                <rect
                                  id="Rectángulo_1972"
                                  data-name="Rectángulo 1972"
                                  width="29.967"
                                  height="14.518"
                                  fill="#fff"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Grupo_2255"
                              data-name="Grupo 2255"
                              clipPath="url(#clipPath)"
                            >
                              <path
                                id="Trazado_9345"
                                data-name="Trazado 9345"
                                d="M29.967,7.493a5.877,5.877,0,0,1-.582.822q-2.89,2.9-5.8,5.784a1.171,1.171,0,1,1-1.687-1.617q1.893-1.894,3.794-3.78c.073-.073.143-.15.259-.273H1.319A1.194,1.194,0,0,1,.053,7.6,1.168,1.168,0,0,1,1.086,6.092c.107-.008.215,0,.322,0H25.947c-.112-.118-.184-.2-.26-.274q-1.9-1.89-3.8-3.778a1.149,1.149,0,0,1-.327-1.2,1.106,1.106,0,0,1,.884-.805A1.167,1.167,0,0,1,23.561.4q2.124,2.118,4.254,4.231c.526.523,1.064,1.034,1.57,1.575a7.084,7.084,0,0,1,.582.822Z"
                                transform="translate(0 0)"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </Link>
                      </span>
                    </div>
                  </div>
                )}

                <img
                  loading="lazy"
                  src={Sebastian}
                  width="100%"
                  className="img-branding"
                  alt="branding sebastian martinez"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4 min-vh-44 marginRowBrandingTablet">
          <div className="col-md-4 d-flex flex-column justify-content-center">
            <div style={{ position: "relative" }}>
              <div className="gradient">
                {isVisible && (
                  <div className="z-index2 position-absolute w-100 bottom30 px-5">
                    <p className="font-circularBlack fw-bold fs-4">
                      Key visual
                    </p>
                    <div className="d-flex justify-content-between">
                      <span className="ff-circularLight">Aapresid</span>
                      <span className=" ">
                        <Link
                          to="/branding/AAPRESID"
                          className="link-light border-bottom arrowActive"
                        >
                          Ver más{" "}
                          <svg
                            id="Grupo_2257"
                            className="mt--5 arrowTransition"
                            data-name="Grupo 2257"
                            xmlns="http://www.w3.org/2000/svg"
                            xlinkHref="http://www.w3.org/1999/xlink"
                            width="25"
                            height="12"
                            viewBox="0 0 29.967 14.518"
                          >
                            <defs>
                              <clipPath id="clipPath">
                                <rect
                                  id="Rectángulo_1972"
                                  data-name="Rectángulo 1972"
                                  width="29.967"
                                  height="14.518"
                                  fill="#fff"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Grupo_2255"
                              data-name="Grupo 2255"
                              clipPath="url(#clipPath)"
                            >
                              <path
                                id="Trazado_9345"
                                data-name="Trazado 9345"
                                d="M29.967,7.493a5.877,5.877,0,0,1-.582.822q-2.89,2.9-5.8,5.784a1.171,1.171,0,1,1-1.687-1.617q1.893-1.894,3.794-3.78c.073-.073.143-.15.259-.273H1.319A1.194,1.194,0,0,1,.053,7.6,1.168,1.168,0,0,1,1.086,6.092c.107-.008.215,0,.322,0H25.947c-.112-.118-.184-.2-.26-.274q-1.9-1.89-3.8-3.778a1.149,1.149,0,0,1-.327-1.2,1.106,1.106,0,0,1,.884-.805A1.167,1.167,0,0,1,23.561.4q2.124,2.118,4.254,4.231c.526.523,1.064,1.034,1.57,1.575a7.084,7.084,0,0,1,.582.822Z"
                                transform="translate(0 0)"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </Link>
                      </span>
                    </div>
                  </div>
                )}

                <img
                  loading="lazy"
                  src={Aapre}
                  width="100%"
                  alt="branding aapresid"
                  className="img-branding"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex flex-column justify-content-center">
            <div style={{ position: "relative" }}>
              <div className="gradient">
                {isVisible && (
                  <div className="z-index2 position-absolute w-100 bottom30 px-5">
                    <p className="font-circularBlack fw-bold fs-4">Branding</p>
                    <div className="d-flex justify-content-between">
                      <span className="ff-circularLight">Zonainmo</span>
                      <span className=" ">
                        <Link
                          to="/branding/ZONAINMO"
                          className="link-light border-bottom arrowActive"
                        >
                          Ver más{" "}
                          <svg
                            id="Grupo_2257"
                            className="mt--5 arrowTransition"
                            data-name="Grupo 2257"
                            xmlns="http://www.w3.org/2000/svg"
                            xlinkHref="http://www.w3.org/1999/xlink"
                            width="25"
                            height="12"
                            viewBox="0 0 29.967 14.518"
                          >
                            <defs>
                              <clipPath id="clipPath">
                                <rect
                                  id="Rectángulo_1972"
                                  data-name="Rectángulo 1972"
                                  width="29.967"
                                  height="14.518"
                                  fill="#fff"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Grupo_2255"
                              data-name="Grupo 2255"
                              clipPath="url(#clipPath)"
                            >
                              <path
                                id="Trazado_9345"
                                data-name="Trazado 9345"
                                d="M29.967,7.493a5.877,5.877,0,0,1-.582.822q-2.89,2.9-5.8,5.784a1.171,1.171,0,1,1-1.687-1.617q1.893-1.894,3.794-3.78c.073-.073.143-.15.259-.273H1.319A1.194,1.194,0,0,1,.053,7.6,1.168,1.168,0,0,1,1.086,6.092c.107-.008.215,0,.322,0H25.947c-.112-.118-.184-.2-.26-.274q-1.9-1.89-3.8-3.778a1.149,1.149,0,0,1-.327-1.2,1.106,1.106,0,0,1,.884-.805A1.167,1.167,0,0,1,23.561.4q2.124,2.118,4.254,4.231c.526.523,1.064,1.034,1.57,1.575a7.084,7.084,0,0,1,.582.822Z"
                                transform="translate(0 0)"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </Link>
                      </span>
                    </div>
                  </div>
                )}

                <img
                  loading="lazy"
                  src={ZonaInmo}
                  width="100%"
                  alt="Branding ZonaInmo"
                  className="img-branding"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex flex-column justify-content-center">
            <div style={{ position: "relative" }}>
              <div className="gradient">
                {isVisible && (
                  <div className="z-index2 position-absolute w-100 bottom30 px-5">
                    <p className="font-circularBlack fw-bold fs-4">
                      Rebranding
                    </p>
                    <div className="d-flex justify-content-between">
                      <span className="ff-circularLight">Airfort</span>
                      <span className=" ">
                        <Link
                          to="/branding/AIRFORT"
                          className="link-light border-bottom arrowActive"
                        >
                          Ver más{" "}
                          <svg
                            id="Grupo_2257"
                            className="mt--5 arrowTransition"
                            data-name="Grupo 2257"
                            xmlns="http://www.w3.org/2000/svg"
                            xlinkHref="http://www.w3.org/1999/xlink"
                            width="25"
                            height="12"
                            viewBox="0 0 29.967 14.518"
                          >
                            <defs>
                              <clipPath id="clipPath">
                                <rect
                                  id="Rectángulo_1972"
                                  data-name="Rectángulo 1972"
                                  width="29.967"
                                  height="14.518"
                                  fill="#fff"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Grupo_2255"
                              data-name="Grupo 2255"
                              clipPath="url(#clipPath)"
                            >
                              <path
                                id="Trazado_9345"
                                data-name="Trazado 9345"
                                d="M29.967,7.493a5.877,5.877,0,0,1-.582.822q-2.89,2.9-5.8,5.784a1.171,1.171,0,1,1-1.687-1.617q1.893-1.894,3.794-3.78c.073-.073.143-.15.259-.273H1.319A1.194,1.194,0,0,1,.053,7.6,1.168,1.168,0,0,1,1.086,6.092c.107-.008.215,0,.322,0H25.947c-.112-.118-.184-.2-.26-.274q-1.9-1.89-3.8-3.778a1.149,1.149,0,0,1-.327-1.2,1.106,1.106,0,0,1,.884-.805A1.167,1.167,0,0,1,23.561.4q2.124,2.118,4.254,4.231c.526.523,1.064,1.034,1.57,1.575a7.084,7.084,0,0,1,.582.822Z"
                                transform="translate(0 0)"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </Link>
                      </span>
                    </div>
                  </div>
                )}

                <img
                  loading="lazy"
                  src={Airfort}
                  width="100%"
                  alt="Branding Airfort"
                  className="img-branding"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4 min-vh-44 marginRowBrandingTablet">
          <div className="col-md-8 d-flex flex-column justify-content-center">
            <div style={{ position: "relative" }}>
              <div className="gradient">
                {isVisible && (
                  <div className="z-index2 position-absolute w-100 bottom30 px-5">
                    <p className="font-circularBlack fw-bold fs-4">
                      Key Visual
                    </p>
                    <div className="d-flex justify-content-between">
                      <span className="ff-circularLight">La Matilde</span>
                      <span className="">
                        <Link
                          to="/branding/LA MATILDE"
                          className="link-light border-bottom arrowActive"
                        >
                          Ver más{" "}
                          <svg
                            id="Grupo_2257"
                            className="mt--5 arrowTransition"
                            data-name="Grupo 2257"
                            xmlns="http://www.w3.org/2000/svg"
                            xlinkHref="http://www.w3.org/1999/xlink"
                            width="25"
                            height="12"
                            viewBox="0 0 29.967 14.518"
                          >
                            <defs>
                              <clipPath id="clipPath">
                                <rect
                                  id="Rectángulo_1972"
                                  data-name="Rectángulo 1972"
                                  width="29.967"
                                  height="14.518"
                                  fill="#fff"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Grupo_2255"
                              data-name="Grupo 2255"
                              clipPath="url(#clipPath)"
                            >
                              <path
                                id="Trazado_9345"
                                data-name="Trazado 9345"
                                d="M29.967,7.493a5.877,5.877,0,0,1-.582.822q-2.89,2.9-5.8,5.784a1.171,1.171,0,1,1-1.687-1.617q1.893-1.894,3.794-3.78c.073-.073.143-.15.259-.273H1.319A1.194,1.194,0,0,1,.053,7.6,1.168,1.168,0,0,1,1.086,6.092c.107-.008.215,0,.322,0H25.947c-.112-.118-.184-.2-.26-.274q-1.9-1.89-3.8-3.778a1.149,1.149,0,0,1-.327-1.2,1.106,1.106,0,0,1,.884-.805A1.167,1.167,0,0,1,23.561.4q2.124,2.118,4.254,4.231c.526.523,1.064,1.034,1.57,1.575a7.084,7.084,0,0,1,.582.822Z"
                                transform="translate(0 0)"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </Link>
                      </span>
                    </div>
                  </div>
                )}

                <img
                  loading="lazy"
                  src={LaMatilde}
                  width="100%"
                  alt="Branding La matilde"
                  className="img-branding"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex flex-column justify-content-center">
            <div style={{ position: "relative" }}>
              <div className="gradient">
                {isVisible && (
                  <div className="z-index2 position-absolute w-100 bottom30 px-5">
                    <p className="font-circularBlack fw-bold fs-4">
                      Key Visual
                    </p>
                    <div className="d-flex justify-content-between">
                      <span className="ff-circularLight">Marph</span>
                      <span className=" ">
                        <Link
                          to="/branding/MARPH"
                          className="link-light border-bottom arrowActive"
                        >
                          Ver más{" "}
                          <svg
                            id="Grupo_2257"
                            className="mt--5 arrowTransition"
                            data-name="Grupo 2257"
                            xmlns="http://www.w3.org/2000/svg"
                            xlinkHref="http://www.w3.org/1999/xlink"
                            width="25"
                            height="12"
                            viewBox="0 0 29.967 14.518"
                          >
                            <defs>
                              <clipPath id="clipPath">
                                <rect
                                  id="Rectángulo_1972"
                                  data-name="Rectángulo 1972"
                                  width="29.967"
                                  height="14.518"
                                  fill="#fff"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Grupo_2255"
                              data-name="Grupo 2255"
                              clipPath="url(#clipPath)"
                            >
                              <path
                                id="Trazado_9345"
                                data-name="Trazado 9345"
                                d="M29.967,7.493a5.877,5.877,0,0,1-.582.822q-2.89,2.9-5.8,5.784a1.171,1.171,0,1,1-1.687-1.617q1.893-1.894,3.794-3.78c.073-.073.143-.15.259-.273H1.319A1.194,1.194,0,0,1,.053,7.6,1.168,1.168,0,0,1,1.086,6.092c.107-.008.215,0,.322,0H25.947c-.112-.118-.184-.2-.26-.274q-1.9-1.89-3.8-3.778a1.149,1.149,0,0,1-.327-1.2,1.106,1.106,0,0,1,.884-.805A1.167,1.167,0,0,1,23.561.4q2.124,2.118,4.254,4.231c.526.523,1.064,1.034,1.57,1.575a7.084,7.084,0,0,1,.582.822Z"
                                transform="translate(0 0)"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </Link>
                      </span>
                    </div>
                  </div>
                )}

                <img
                  loading="lazy"
                  src={Marph}
                  width="100%"
                  className="img-branding"
                  alt="branding de Marph"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row marginRowBrandingTablet mb-4 ">
          <div className="col-md-4 d-flex flex-column justify-content-center position-relative">
            <div style={{ position: "relative" }}>
              <div className="gradient">
                {isVisible && (
                  <div className="z-index2 position-absolute w-100 bottom30 px-5">
                    <p className="font-circularBlack fw-bold fs-4">Branding</p>
                    <div className="d-flex justify-content-between">
                      <span className="ff-circularLight">Paracol</span>
                      <span className=" ">
                        <Link
                          to="/branding/PARACOL"
                          className="link-light border-bottom arrowActive"
                        >
                          Ver más{" "}
                          <svg
                            id="Grupo_2257"
                            className="mt--5 arrowTransition"
                            data-name="Grupo 2257"
                            xmlns="http://www.w3.org/2000/svg"
                            xlinkHref="http://www.w3.org/1999/xlink"
                            width="25"
                            height="12"
                            viewBox="0 0 29.967 14.518"
                          >
                            <defs>
                              <clipPath id="clipPath">
                                <rect
                                  id="Rectángulo_1972"
                                  data-name="Rectángulo 1972"
                                  width="29.967"
                                  height="14.518"
                                  fill="#fff"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Grupo_2255"
                              data-name="Grupo 2255"
                              clipPath="url(#clipPath)"
                            >
                              <path
                                id="Trazado_9345"
                                data-name="Trazado 9345"
                                d="M29.967,7.493a5.877,5.877,0,0,1-.582.822q-2.89,2.9-5.8,5.784a1.171,1.171,0,1,1-1.687-1.617q1.893-1.894,3.794-3.78c.073-.073.143-.15.259-.273H1.319A1.194,1.194,0,0,1,.053,7.6,1.168,1.168,0,0,1,1.086,6.092c.107-.008.215,0,.322,0H25.947c-.112-.118-.184-.2-.26-.274q-1.9-1.89-3.8-3.778a1.149,1.149,0,0,1-.327-1.2,1.106,1.106,0,0,1,.884-.805A1.167,1.167,0,0,1,23.561.4q2.124,2.118,4.254,4.231c.526.523,1.064,1.034,1.57,1.575a7.084,7.084,0,0,1,.582.822Z"
                                transform="translate(0 0)"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </Link>
                      </span>
                    </div>
                  </div>
                )}

                <img
                  loading="lazy"
                  src={Paracol}
                  width="100%"
                  className="img-branding"
                  alt="branding paracol"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex flex-column justify-content-center position-relative">
            <div style={{ position: "relative" }}>
              <div className="gradient">
                {isVisible && (
                  <div className="z-index2 position-absolute w-100 bottom30 px-5">
                    <p className="font-circularBlack fw-bold fs-4">Branding</p>
                    <div className="d-flex justify-content-between">
                      <span className="ff-circularLight">Indisual</span>
                      <span className=" ">
                        <Link
                          to="/branding/INDISUAL"
                          className="link-light border-bottom arrowActive"
                        >
                          Ver más{" "}
                          <svg
                            id="Grupo_2257"
                            className="mt--5 arrowTransition"
                            data-name="Grupo 2257"
                            xmlns="http://www.w3.org/2000/svg"
                            xlinkHref="http://www.w3.org/1999/xlink"
                            width="25"
                            height="12"
                            viewBox="0 0 29.967 14.518"
                          >
                            <defs>
                              <clipPath id="clipPath">
                                <rect
                                  id="Rectángulo_1972"
                                  data-name="Rectángulo 1972"
                                  width="29.967"
                                  height="14.518"
                                  fill="#fff"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Grupo_2255"
                              data-name="Grupo 2255"
                              clipPath="url(#clipPath)"
                            >
                              <path
                                id="Trazado_9345"
                                data-name="Trazado 9345"
                                d="M29.967,7.493a5.877,5.877,0,0,1-.582.822q-2.89,2.9-5.8,5.784a1.171,1.171,0,1,1-1.687-1.617q1.893-1.894,3.794-3.78c.073-.073.143-.15.259-.273H1.319A1.194,1.194,0,0,1,.053,7.6,1.168,1.168,0,0,1,1.086,6.092c.107-.008.215,0,.322,0H25.947c-.112-.118-.184-.2-.26-.274q-1.9-1.89-3.8-3.778a1.149,1.149,0,0,1-.327-1.2,1.106,1.106,0,0,1,.884-.805A1.167,1.167,0,0,1,23.561.4q2.124,2.118,4.254,4.231c.526.523,1.064,1.034,1.57,1.575a7.084,7.084,0,0,1,.582.822Z"
                                transform="translate(0 0)"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </Link>
                      </span>
                    </div>
                  </div>
                )}

                <img
                  loading="lazy"
                  src={Indisual}
                  width="100%"
                  className="img-branding"
                  alt="branding "
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex flex-column justify-content-center position-relative">
            <div style={{ position: "relative" }}>
              <div className="gradient">
                {isVisible && (
                  <div className="z-index2 position-absolute w-100 bottom30 px-5">
                    <p className="font-circularBlack fw-bold fs-4">Branding</p>
                    <div className="d-flex justify-content-between">
                      <span className="ff-circularLight">Puro</span>
                      <span className=" ">
                        <Link
                          to="/branding/PURO"
                          className="link-light border-bottom arrowActive"
                        >
                          Ver más{" "}
                          <svg
                            id="Grupo_2257"
                            className="mt--5 arrowTransition"
                            data-name="Grupo 2257"
                            xmlns="http://www.w3.org/2000/svg"
                            xlinkHref="http://www.w3.org/1999/xlink"
                            width="25"
                            height="12"
                            viewBox="0 0 29.967 14.518"
                          >
                            <defs>
                              <clipPath id="clipPath">
                                <rect
                                  id="Rectángulo_1972"
                                  data-name="Rectángulo 1972"
                                  width="29.967"
                                  height="14.518"
                                  fill="#fff"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Grupo_2255"
                              data-name="Grupo 2255"
                              clipPath="url(#clipPath)"
                            >
                              <path
                                id="Trazado_9345"
                                data-name="Trazado 9345"
                                d="M29.967,7.493a5.877,5.877,0,0,1-.582.822q-2.89,2.9-5.8,5.784a1.171,1.171,0,1,1-1.687-1.617q1.893-1.894,3.794-3.78c.073-.073.143-.15.259-.273H1.319A1.194,1.194,0,0,1,.053,7.6,1.168,1.168,0,0,1,1.086,6.092c.107-.008.215,0,.322,0H25.947c-.112-.118-.184-.2-.26-.274q-1.9-1.89-3.8-3.778a1.149,1.149,0,0,1-.327-1.2,1.106,1.106,0,0,1,.884-.805A1.167,1.167,0,0,1,23.561.4q2.124,2.118,4.254,4.231c.526.523,1.064,1.034,1.57,1.575a7.084,7.084,0,0,1,.582.822Z"
                                transform="translate(0 0)"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </Link>
                      </span>
                    </div>
                  </div>
                )}

                <img
                  loading="lazy"
                  src={Puro}
                  width="100%"
                  className="img-branding"
                  alt="branding "
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row min-vh-44 marginRowBrandingTablet mb-4 ">
          <div className="col-md-8 d-flex flex-column justify-content-center">
            <div style={{ position: "relative" }}>
              <div className="gradient">
                {isVisible && (
                  <div className="z-index2 position-absolute w-100 bottom30 px-5">
                    <p className="font-circularBlack fw-bold fs-4">Branding</p>
                    <div className="d-flex justify-content-between">
                      <span className="ff-circularLight">Singulares</span>
                      <span className="">
                        <Link
                          to="/branding/SINGULARES"
                          className="link-light border-bottom arrowActive"
                        >
                          Ver más{" "}
                          <svg
                            id="Grupo_2257"
                            className="mt--5 arrowTransition"
                            data-name="Grupo 2257"
                            xmlns="http://www.w3.org/2000/svg"
                            xlinkHref="http://www.w3.org/1999/xlink"
                            width="25"
                            height="12"
                            viewBox="0 0 29.967 14.518"
                          >
                            <defs>
                              <clipPath id="clipPath">
                                <rect
                                  id="Rectángulo_1972"
                                  data-name="Rectángulo 1972"
                                  width="29.967"
                                  height="14.518"
                                  fill="#fff"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Grupo_2255"
                              data-name="Grupo 2255"
                              clipPath="url(#clipPath)"
                            >
                              <path
                                id="Trazado_9345"
                                data-name="Trazado 9345"
                                d="M29.967,7.493a5.877,5.877,0,0,1-.582.822q-2.89,2.9-5.8,5.784a1.171,1.171,0,1,1-1.687-1.617q1.893-1.894,3.794-3.78c.073-.073.143-.15.259-.273H1.319A1.194,1.194,0,0,1,.053,7.6,1.168,1.168,0,0,1,1.086,6.092c.107-.008.215,0,.322,0H25.947c-.112-.118-.184-.2-.26-.274q-1.9-1.89-3.8-3.778a1.149,1.149,0,0,1-.327-1.2,1.106,1.106,0,0,1,.884-.805A1.167,1.167,0,0,1,23.561.4q2.124,2.118,4.254,4.231c.526.523,1.064,1.034,1.57,1.575a7.084,7.084,0,0,1,.582.822Z"
                                transform="translate(0 0)"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </Link>
                      </span>
                    </div>
                  </div>
                )}

                <img
                  loading="lazy"
                  src={Singulares}
                  width="100%"
                  alt="Branding Singulares"
                  className="img-branding"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex flex-column justify-content-center">
            <div style={{ position: "relative" }}>
              <div className="gradient">
                {isVisible && (
                  <div className="z-index2 position-absolute w-100 bottom30 px-5">
                    <p className="font-circularBlack fw-bold fs-4">Branding</p>
                    <div className="d-flex justify-content-between">
                      <span className="ff-circularLight">Del Gredal</span>
                      <span className=" ">
                        <Link
                          to="/branding/DEL GREDAL"
                          className="link-light border-bottom arrowActive"
                        >
                          Ver más{" "}
                          <svg
                            id="Grupo_2257"
                            className="mt--5 arrowTransition"
                            data-name="Grupo 2257"
                            xmlns="http://www.w3.org/2000/svg"
                            xlinkHref="http://www.w3.org/1999/xlink"
                            width="25"
                            height="12"
                            viewBox="0 0 29.967 14.518"
                          >
                            <defs>
                              <clipPath id="clipPath">
                                <rect
                                  id="Rectángulo_1972"
                                  data-name="Rectángulo 1972"
                                  width="29.967"
                                  height="14.518"
                                  fill="#fff"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Grupo_2255"
                              data-name="Grupo 2255"
                              clipPath="url(#clipPath)"
                            >
                              <path
                                id="Trazado_9345"
                                data-name="Trazado 9345"
                                d="M29.967,7.493a5.877,5.877,0,0,1-.582.822q-2.89,2.9-5.8,5.784a1.171,1.171,0,1,1-1.687-1.617q1.893-1.894,3.794-3.78c.073-.073.143-.15.259-.273H1.319A1.194,1.194,0,0,1,.053,7.6,1.168,1.168,0,0,1,1.086,6.092c.107-.008.215,0,.322,0H25.947c-.112-.118-.184-.2-.26-.274q-1.9-1.89-3.8-3.778a1.149,1.149,0,0,1-.327-1.2,1.106,1.106,0,0,1,.884-.805A1.167,1.167,0,0,1,23.561.4q2.124,2.118,4.254,4.231c.526.523,1.064,1.034,1.57,1.575a7.084,7.084,0,0,1,.582.822Z"
                                transform="translate(0 0)"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </Link>
                      </span>
                    </div>
                  </div>
                )}

                <img
                  loading="lazy"
                  src={DelGredal}
                  width="100%"
                  className="img-branding"
                  alt="branding de Del Gredal"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row marginRowBrandingTablet mb-4 ">
          <div className="col-md-4 d-flex flex-column justify-content-center position-relative">
            <div style={{ position: "relative" }}>
              <div className="gradient">
                {isVisible && (
                  <div className="z-index2 position-absolute w-100 bottom30 px-5">
                    <p className="font-circularBlack fw-bold fs-4">
                      Rebranding
                    </p>
                    <div className="d-flex justify-content-between">
                      <span className="ff-circularLight">Gaintza</span>
                      <span className=" ">
                        <Link
                          to="/branding/GAINTZA"
                          className="link-light border-bottom arrowActive"
                        >
                          Ver más{" "}
                          <svg
                            id="Grupo_2257"
                            className="mt--5 arrowTransition"
                            data-name="Grupo 2257"
                            xmlns="http://www.w3.org/2000/svg"
                            xlinkHref="http://www.w3.org/1999/xlink"
                            width="25"
                            height="12"
                            viewBox="0 0 29.967 14.518"
                          >
                            <defs>
                              <clipPath id="clipPath">
                                <rect
                                  id="Rectángulo_1972"
                                  data-name="Rectángulo 1972"
                                  width="29.967"
                                  height="14.518"
                                  fill="#fff"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Grupo_2255"
                              data-name="Grupo 2255"
                              clipPath="url(#clipPath)"
                            >
                              <path
                                id="Trazado_9345"
                                data-name="Trazado 9345"
                                d="M29.967,7.493a5.877,5.877,0,0,1-.582.822q-2.89,2.9-5.8,5.784a1.171,1.171,0,1,1-1.687-1.617q1.893-1.894,3.794-3.78c.073-.073.143-.15.259-.273H1.319A1.194,1.194,0,0,1,.053,7.6,1.168,1.168,0,0,1,1.086,6.092c.107-.008.215,0,.322,0H25.947c-.112-.118-.184-.2-.26-.274q-1.9-1.89-3.8-3.778a1.149,1.149,0,0,1-.327-1.2,1.106,1.106,0,0,1,.884-.805A1.167,1.167,0,0,1,23.561.4q2.124,2.118,4.254,4.231c.526.523,1.064,1.034,1.57,1.575a7.084,7.084,0,0,1,.582.822Z"
                                transform="translate(0 0)"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </Link>
                      </span>
                    </div>
                  </div>
                )}

                <img
                  loading="lazy"
                  src={Gaintza}
                  width="100%"
                  className="img-branding"
                  alt="branding Gaintza"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex flex-column justify-content-center position-relative">
            <div style={{ position: "relative" }}>
              <div className="gradient">
                {isVisible && (
                  <div className="z-index2 position-absolute w-100 bottom30 px-5">
                    <p className="font-circularBlack fw-bold fs-4">Branding</p>
                    <div className="d-flex justify-content-between">
                      <span className="ff-circularLight">Delivery Wap</span>
                      <span className=" ">
                        <Link
                          to="/branding/DELIVERY WAP"
                          className="link-light border-bottom arrowActive"
                        >
                          Ver más{" "}
                          <svg
                            id="Grupo_2257"
                            className="mt--5 arrowTransition"
                            data-name="Grupo 2257"
                            xmlns="http://www.w3.org/2000/svg"
                            xlinkHref="http://www.w3.org/1999/xlink"
                            width="25"
                            height="12"
                            viewBox="0 0 29.967 14.518"
                          >
                            <defs>
                              <clipPath id="clipPath">
                                <rect
                                  id="Rectángulo_1972"
                                  data-name="Rectángulo 1972"
                                  width="29.967"
                                  height="14.518"
                                  fill="#fff"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Grupo_2255"
                              data-name="Grupo 2255"
                              clipPath="url(#clipPath)"
                            >
                              <path
                                id="Trazado_9345"
                                data-name="Trazado 9345"
                                d="M29.967,7.493a5.877,5.877,0,0,1-.582.822q-2.89,2.9-5.8,5.784a1.171,1.171,0,1,1-1.687-1.617q1.893-1.894,3.794-3.78c.073-.073.143-.15.259-.273H1.319A1.194,1.194,0,0,1,.053,7.6,1.168,1.168,0,0,1,1.086,6.092c.107-.008.215,0,.322,0H25.947c-.112-.118-.184-.2-.26-.274q-1.9-1.89-3.8-3.778a1.149,1.149,0,0,1-.327-1.2,1.106,1.106,0,0,1,.884-.805A1.167,1.167,0,0,1,23.561.4q2.124,2.118,4.254,4.231c.526.523,1.064,1.034,1.57,1.575a7.084,7.084,0,0,1,.582.822Z"
                                transform="translate(0 0)"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </Link>
                      </span>
                    </div>
                  </div>
                )}

                <img
                  loading="lazy"
                  src={DeliveryWap}
                  width="100%"
                  className="img-branding"
                  alt="branding "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
