import React from "react";
import "./ProyectoGaleriaStyles.css";
import { motion } from "framer-motion";

const ProyectoGaleria = ({ imagenes }) => {
  return (
    <section className="main-contaner-galeria">
      {imagenes.map((img, index) => {
        if (Array.isArray(img, index)) {
          return (
            <motion.div
              className="contenedor-img-doble"
              key={index}
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 0.8 } }}
              viewport={{ once: true }}
            >
              {img.map((imgDoble) => {
                return (
                  <div style={{ width: "100%" }} key={imgDoble.localPath}>
                    <div className="container-imagen">
                      <img
                        className="galeria-imagen"
                        src={imgDoble.localPath}
                        alt="imagen muestra Traslaaqua"
                        loading="lazy"
                      />
                    </div>
                  </div>
                );
              })}
            </motion.div>
          );
        }

        return (
          <motion.div
            key={img.localPath}
            style={{ width: "100%" }}
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0, transition: { duration: 0.8 } }}
            viewport={{ once: true }}
          >
            <div
              className="container-imagen"
              style={{
                width: img.width,
              }}
            >
              <img
                className="galeria-imagen"
                src={img.localPath}
                alt="imagen muestra Traslaaqua"
                loading="lazy"
              />
            </div>
          </motion.div>
        );
      })}
    </section>
  );
};

export default ProyectoGaleria;
