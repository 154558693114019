import React, { useState, useRef, useEffect } from 'react';
import ModalSlide from './ModalSlide'
import { Effitech, JmGroup, Tribunales, Bhi, DelGredal, Taller, Eco, CostaEsmeralda, Ralseff } from './../../../assets/img/slidesImages/index';
import './exitos.css'
export default function SectionFourB(){

    const sliderContainerRef = useRef(null);
    const containerTitle = useRef()

    const [slideSelected, setSlideSelected] = useState({});
    const [idSelected, setIdSelected] = useState(1  );
    const [showModal, setShowModal] = useState(false);
    const [lastIdSlide, setLastIdSlide] = useState(1);
    const [finalSlider, setFinalSlider] = useState([])

    const [time, setTime] = useState(0)
    const [flag, setFlag] = useState(false)
    
    // Mouse events
    const [mouseStartX, setMouseStart] = useState(0)
    const [mouseEndX, setMouseEnd] = useState(0)
    const [touchStartX, setTouchStart] = useState(0)
    const [touchEndX, setTouchEnd] = useState(0)

    

    useEffect(()=>{
        // sliderContainerRef.current.scrollLeft = 730
        let objectStyle = window.getComputedStyle(containerTitle.current)
        let padding = objectStyle.getPropertyValue('padding-right')
        let margin = objectStyle.getPropertyValue('margin-right')
        sliderContainerRef.current.style.padding = `2rem ${transformToNumber(padding) + transformToNumber(margin)}px`

        window.addEventListener('resize', (ev)=>{
            let objectStyle = window.getComputedStyle(containerTitle.current)
            let padding = objectStyle.getPropertyValue('padding-right')
            let margin = objectStyle.getPropertyValue('margin-right')
            sliderContainerRef.current.style.padding = `2rem ${transformToNumber(padding) + transformToNumber(margin)}px`
        })
    },[sliderContainerRef])

    useEffect(()=>{
        const hardSlides = [
            // {
            //     id: "1",
            //     name:"Effitech",
            //     title:"Soluciones tecnológicas <br> para el agro",
            //     image:Effitech,
            //     link:"https://effitech.villanueva.com.ar/",
            //     client:"Efitech",
            //     text1:"Efitech tiene un gran producto: soluciones tecnológicas para el agro. <br>Quisieron encontrar la mejor forma de mostrarlo y llegar a sus potenciales clientes. Buscaban un sitio para comunicar y también para vender, y nos convocaron para crearlo",
            //     text2:"<p>Diseñamos una web que comunica los servicios de la empresa y que guía al usuario hacia el contacto directo con la marca, a través de una experiencia de usuario totalmente personalizada</p><p class='mt-4'>Cualquier persona interesada en tecnología para el agro puede encontrar en este sitio no solo la solución que necesita, sino también una manera sencilla y ráida de contactarse con la empresa</p>"
            // },
            {
                id: "1",
                name:"JM Group",
                title:"Plataforma de alquiler de <br> departamentos temporarios <br> Brasil",
                image:JmGroup,
                link:"https://jmgroup.com.ar/",
                client:"JM Group",
                text1:"JM Group es una empresa dedicada al alqiler temporario de departamentos en Brasil. Buscaban un sitio en el que pudieran cargar sus propidades y, en la misma plataforma, los usuarios pudieran buscar, reservar y pagar un departamento.",
                text2:"<p>Diseñamos y creamos una plataforma permite administrar y gestionar los departamentos en alquiler, agregando las fotos y los precios determinados. Es un sitio multidioma y multidivisa: a partir de ingresar el precio en dólares, el sistema calcula el cambio según las cotizaciones de las monedas.</p><p class='mt-4'>Su viajás a Brasil, podés encontrar el alquiler temporario que estás buscando y gestionarlo a través de la web de JM Group.</p>"
            },
            {
                id: "2",
                name:"Tribunales de cuentas",
                title:"Plataforma de gestión de <br> trámites y consultoras",
                image:Tribunales,
                link:"https://www.tcpcordoba.gov.ar/",
                client:"Tribunal de Cuentas",
                text1:"Hacer un trámite online en el Tribunal de Cuentas de la Provincia de Córdoba era un proceso muy largo y complicado: acceer al contenido era difícil y llevaba mucho tiempo. Los usuarios terminaban cada vez más enojados, por lo que el Tribunal decidió contratarnos para hacer una nueva web",
                text2:"<p>Generamos una plataforma sencilla el intuitiva para acceder con facilidad a los trámites. Diseñamos una experiencia de usuario distinta para cada público, así cada persona que ingrese al sitio pueda encontrar lo que esta buscando rápidamente</p><p class='mt-4'>Hoy, hacer un trámite online en el Tribunal de Cuentas de Córdoba es un proceso mucho más rápido y accesible.</p>"
            },
            {
                id: "3",
                name:"BHI",
                title:"Plataforma de venta <br> mayorista de vajes",
                image:Bhi,
                link:"https://www.app.bhiviajes.com.ar/",
                client:"Cinergia",
                text1:"BHI se dirige a un público muy específico: agencias de viaje. Venden paquetes completos de viaje a toda Argentina. Llegaron a Chimpancé en busca de un sitio que optimice el proceso de consultas y reservas",
                text2:"<p>Diseñamos una plataforma con un sistema ideado para que el usuario pueda consultar fácilmente fechas y destinos, ver disponibilidad, reservar el viaje, elegir butacas y cargar los datos del pasajero.</p><p class='mt-4'>Para una mejor segmentación aplicamos un filtro: antes de hacer una compra o reserva, las agencias deben estar registradas y verificadas. A través de un sistema de registro y aprobación.<br><br>Gracias a esta plataforma, es más fácil para BHI llegar a las personas indicadas y más fácil para los usuarios comprar sus productos.</p>"
            },
            {
                id: "4",
                name:"Del Gredal",
                title:"Bodega online: Mostrá tu marca <br> y contá tu historia",
                image:DelGredal,
                link:"https://www.delgredal.com.ar/",
                client:"Del Gredal",
                text1:"Del Gredal es una bodega con trayectoria, situada en un lugar poco tradicional para la industria: el Cerro Colorado, Provincia de Córdoba. Cinergia Estudio Creativo desarrolló una nueva identidad y branding para la marca y nos contrataron par lasmarlo en un sitio web",
                text2:"<p>Desarrollamos y diseñamos una web institucional que propone una narrativa en línea con el espíritu y la identidad de la marca: la imagen, colores, tipografías. Se muestran los principales productos, la variedad y el proceso de cada uno. Además, se hace especial foco en el relato de origen de la bodega. </p><p class='mt-4'>Al ingresar al sitio de Bodega Del Gredal, es posible entrar en su historia y conocer en profundidad sus productos. Durante 2021, la web ganó un premio Eikon.</p>"
            },
            {
                id: "5",
                name:"Taller",
                title:"Startup de software, innovación <br> y tecnología",
                image:Taller,
                link:"https://chimpance.digital/taller",
                client:"Cinergia",
                text1:"Taller es una empresa de tecnología alemana que estaba atravesando un momento expansión, participando de rondas de inversión. Necesitaban una web que elevara su status y comunicara innovación, experiencia y tecnología.",
                text2:"<p>Nos contrataron para hacer crecer su imagen frente a sus potenciales inversores. Para eso desarrollamos una web minimalista y funcional, enfocada directamente en el público objetivo. Además, nos centramos exclusivamente en un diseño mobile. </p><p class='mt-4'>Taller ahora cuenta con un sitio que aporta un gran diferencial, mostrando su experiencia en innovación y tecnología.</p>"
            },
            {
                id: "6",
                name:"Eco",
                title:"eCommerce venta de e-books",
                image:Eco,
                link:"https://chimpance.digital/desarrolloweb/eco/",
                client:"Cinergia",
                text1:"ECO Inclusión es una fundación que se dedica a disminuir el impacto ambiental a través de la reutilización del plástico para crear ladrillos ecológicos. Han inventado un proceso llamado ECO-LADRILLO y necesitaban una plataforma para difundir este proceso a través de la venta de capacitaciones.",
                text2:"<p>Para abordar este desafío, desarrollamos una plataforma que permite a los usuarios pagar y registrarse para acceder al contenido y agendar encuentros de capacitación. La plataforma también cuenta con un calendario personalizado para facilitar la organización de las capacitaciones.</p><p class='mt-4'>Con la implementación de esta plataforma, ECO Inclusión ha podido difundir su proceso de creación de ladrillos ecológicos a través de la venta de capacitaciones, y ha logrado una mayor eficiencia en la organización de estos encuentros.</p>"
            },
            {
                id: "7",
                name:"Costa Esmeralda",
                title:"Plataforma de gestión de <br> proyectos inmobiliarios",
                image:CostaEsmeralda,
                link:"-",
                client:"Costa Esmeralda",
                text1:"Costa Esmeralda es un complejo de 16 barrios privados ubicado en Pinamar que no para de crecer. Con el fin de alcanzar un ordenamiento urbano integrado, establecieron un reglamento de construcción para los propietarios.",
                text2:"<p>Necesitaban controlar los estados de los proyectos de obra y los profesionales involucrados. Tener la posibilidad de comunicarse con arquitectos, ingenieros y propietarios; y controlar y organizar documentos, archivos e información.<br> Entonces, decidimos desarrollar una plataforma web que funciona como sistema de gestión interna.</p><p class='mt-4'>Profesionales y personal de la empresa pueden chatear, adjuntar archivos y actualizar estados de proyectos; todo en un mismo lugar.<br>Ahora, el control de proyectos es un proceso más automatizado, práctico y simple.</p>"
            },
            {
                id: "8",
                name:"Ralseff",
                title:"Plataforma eLearning: pagos, <br> cursos y módulos, exámenes y más",
                image:Ralseff,
                link:"https://ralseff.com/",
                client:"Ralseff",
                text1:"Ralseff nos trajo un gran desafío. Buscaban una manera para unificar en el mismo lugar todos los pasos necesarios para la inscripción, desarrollo y gestión de cursos online. <br> <br>Creamos una plataforma que centraliza todo lo necesario para los distintos tipos de usuarios. Desde el sitio se puede realizar la inscripción, pago y cursada, a través de un sistema integrado con Mercado Pago.",
                text2:"<p>Los alumnos pueden gestionar sus pagos, ver videos y descargar material de estudio, y los administradores pueden crear cursos, subir material y hacer el seguimiento de pagos y pendientes.</p><p class='mt-4'>Ahora, cuando hacés un curso en Ralseff, tenés todo lo que necesitás en el mismo lugar.</p>"
            }
        ]
     
        setFinalSlider(hardSlides)
        
    },[])


    function transformToNumber(property){
        let i = property.indexOf('p')

        return Number(property.slice(0, i))
    }

    let direction
    let images
    let  timeEnd, deltaTime
  
   
    function handleTouchStart(event) {
        setTouchStart(event.changedTouches[0].pageX);
    }
  
    function handleTouchMove(event) {
        touchEndX(event.changedTouches[0].pageX);
     
    }
  
    function handleTouchEnd() {

    }

    function handleMouseDown(event) {
        setMouseStart(mouseEndX);
      setTime(new Date().getTime())
      
      setFlag(true)
    images = document.querySelectorAll('.image')
      
      
    }


    function handleMouseMove(event) {
        setMouseEnd(event.clientX);
      if (mouseStartX - mouseEndX > 0) {
        direction = ''
      }else{
        direction = '-'
      }
      if(flag){
        images = document.querySelectorAll('.image')
        for(let i = 0; i<8; i++){
            images[i].style.transition = 'transform .6s ease-in-out'
            images[i].style.transform = `rotateY(${direction}15deg)`
        }
      }
    }
  
    function handleMouseUp() {
        let containerWidth = sliderContainerRef.current.clientWidth;
        images = document.querySelectorAll('.image')
        for(let i = 0; i<8; i++){
            
            images[i].style.transform = `rotateY(0deg)`
        }

      if (mouseStartX - mouseEndX > 50) {
        const goToSlideId = lastIdSlide + 1
        
        let extra = (8 * containerWidth) / 100 
        let scrollNumber = 730 * (goToSlideId - 1)

        sliderContainerRef.current.scrollLeft = scrollNumber;
        if(goToSlideId <= 8){
            setLastIdSlide(Number(goToSlideId))
            setIdSelected(Number(goToSlideId))
        }
        
      }
  
      if (mouseStartX - mouseEndX  < 50) {
        
        const goToSlideId = lastIdSlide - 1

        let extra = (8 * containerWidth) / 100 
        let scrollNumber = 730 * (goToSlideId - 1)

        sliderContainerRef.current.scrollLeft = scrollNumber;
        if(goToSlideId > 0){
            setLastIdSlide(Number(goToSlideId))
            setIdSelected(Number(goToSlideId))
        }
        
      }
      setMouseStart(0);
      setMouseEnd(0);
      setFlag(false)
     
    }

    const mapSlideArray = (n)=>{
        
    }
    const bullet = (slide)=>{
        let containerWidth = sliderContainerRef.current.clientWidth;
            let slideId = Number(slide.id) -1 ;
            let extra = (9 * containerWidth) / 100 
            let scrollNumber = 730 * slideId
            
            sliderContainerRef.current.scrollLeft = scrollNumber;
            setLastIdSlide(Number(slide.id))
            setIdSelected(Number(slide.id))
            setSlideSelected(slide)
    }

    function handleButtonClick(slide) {
        timeEnd = new Date().getTime()
        deltaTime = timeEnd- time
        if(deltaTime<250){

            let containerWidth = sliderContainerRef.current.clientWidth;
            let slideId = Number(slide.id) -1 ;
            let extra = (9 * containerWidth) / 100 
            let scrollNumber = 730 * slideId
            
            sliderContainerRef.current.scrollLeft = scrollNumber;
            setLastIdSlide(Number(slide.id))
            setIdSelected(Number(slide.id))
            setSlideSelected(slide)
        }
        
    }

 

    
    return (
        <>
        <section className="slider_projects d-flex justify-content-center align-items-center flex-column position-relative d-none d-md-block py-5">
            <div className="position-absolute bg-lb"></div>
            <div className="container-fluid prime-fluid position-relative" >
                <div ref={containerTitle} className="container">
                    <div className="row">
                        <h2 className="mt-3 position-relative success_text">CASOS DE ÉXITOS</h2>
                    </div>
                </div>
                <div 
                    className="w-100 h-100 py-5"
                    id="container-slider"
                    ref={sliderContainerRef}
                    style={{overflow: 'hidden', scrollBehavior: 'smooth', display: 'flex'}}
                    onTouchMove={handleTouchMove}
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    >
                    {
                        finalSlider.map((slide)=>(
                            <article className="item-projects px-5" key={slide.id} 
                            onClick={()=>handleButtonClick(slide)} 
                            >
                                <div className='title fs-3 fw-bold mb-4'>
                                    .0{slide.id} 
                                    <br />
                                    {slide.name}
                                </div>
                                <div className='image'
                                //  onclick="changeToN(${i})"
                                >
                                    <img draggable='false' src={slide.image} />
                                    <div className='overlay' style={{ opacity: idSelected === Number(slide.id) ? 1 : 0, transition: "opacity 1s ease-in-out" }}>
                            
                                        <div className="position-absolute py-5 title-image">
                                            <div className="">
                                                <div className='title pb-1' dangerouslySetInnerHTML={{__html: slide.title}}></div>
                                                <a className='link'>{slide.link}</a>
                                            </div>
                                            <button 
                                                className="align-items-center btn d-flex fw- gap-2 mt-4"
                                                style={{ width: 'fit-content', padding: '5px 20px', background: 'white', fontSize: '17px', borderRadius: '3px', color: 'var(--lightBlue-01)' }}
                                                // onclick="expand(${i})"
                                                onClick={()=>setShowModal(true)}
                                            >
                                                Ver proyecto
                                                <i className="fa-arrow-up fa-solid" style={{ transform: 'rotate(90deg)' }}></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        ))
                    }
                </div>
                {/* bullets amarillas */}
                <div className="w-100 d-flex justify-content-center dots py-3 ">
                    <ul>
                        {finalSlider.map((slide, index)=>(
                            <li 
                            className={idSelected === Number(slide.id)? 'active ' : ''}
                            onClick={()=>bullet(slide)}
                            >
                            
                            </li>
                        ))
                        }
                    </ul>
                </div>
            </div>
        </section>
        <ModalSlide
            slide={slideSelected}
            showModal={showModal}
            setShowModal={setShowModal}
        />        
        </>
    );

}

