import React from 'react';
import MultimediaComponent from './MultimediaComponent';
import ContentComponent from './contentComponent';



const ArticleDownload = ({ article }) => {

  const poseIntoPieze = (id) => {
    const elemento = document.getElementById(id);
    if (elemento) {
      const offsetTop = elemento.offsetTop - 105;
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth'
      });
    }
  };


  return (
    <div className="article">
      <div className="article__fondo">
        <div className="article__fondo__contenedor0"></div>
        <h1 className="article__fondo__title">{article.title}</h1>
        <div className="fondo__contenedor1">
          <img src="/images/selva1.svg" alt="jungla de fondo" className="fondo__selva1" />
        </div>
        <div className="fondo__contenedor2">
          <img src="/images/selva2.svg" alt="jungla de fondo" className="fondo__selva2" />
        </div>
      </div>
      <div className='article_body'><div className='article_body_Container container'>
        <p className='article_body_Container__title'>chimpancé digital</p>
        <ContentComponent
          content={article.introduction}
        />


        <div className='article_indice'>
          <div className='article_indice_contenedor'>


            <span className='article_indice_contenedor_titulo'>
              <svg
                className="article_indice_contenedor_icono"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                ></path>
              </svg>
              Seguí el hilo
            </span>
            <ul className="article_indice_contenedor_lista">
              {article.indexHeaders.map((heading, index) => (
                <li
                  key={index}
                  className="article_indice_contenedor_lista_item"
                  onClick={() => poseIntoPieze(heading.text.replace(/\s+/g, '_'))}
                >
                  {heading.text}
                </li>
              ))}
            </ul>
          </div>
        </div>


        {article.content && article.content.map((item) => {
          switch (item.__component) {
            case 'content.multimedia':
              return (
                <MultimediaComponent
                  key={item.id}
                  alternativeText={item.alternativeText}
                  file={item.file}
                  type={item.type}
                />
              );
            case 'content.content':
              return (
                <ContentComponent
                  key={item.id}
                  content={item.content}
                />
              );
            default:
              return null;
          }
        })}
        <ContentComponent
          content={article.conclusion}
        />
        {article.lastContent && article.lastContent.map((item) => {
          switch (item.__component) {
            case 'content.multimedia':
              return (
                <MultimediaComponent
                  key={item.id}
                  alternativeText={item.alternativeText}
                  file={item.file}
                  type={item.type}
                />
              );
            case 'content.content':
              return (
                <ContentComponent
                  key={item.id}
                  content={item.content}
                />
              );
            default:
              return null;
          }
        })}
      </div>
      </div>
    </div>
  )
};

export default ArticleDownload;