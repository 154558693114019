import React from "react";
import "./ProyectoDataStyles.css";
import ArrowLong from "../../../assets/img/arrows/ArrowLong";
import ArrowDiagonal from "../../../assets/img/arrows/arrow-diagonal.png";
import ProyectoAcordeon from "./ProyectoAcordeon";
import { motion } from "framer-motion";

const ProyectoData = ({ proyecto }) => {
  return (
    <>
      <motion.article
        initial={{ opacity: 0, y: 100 }}
        animate={{
          opacity: 1,
          y: 0,
          transition: { duration: 0.8, delay: 0.5 },
        }}
        className="main-container"
      >
        <div className="container-header">
          <div>
            <h2 className="proyecto-titulo">{proyecto.titulo}</h2>
            {proyecto.subtitulo && (
              <h5 className="proyecto-subtitulo">{proyecto.subthitulo}</h5>
            )}
          </div>
          <a href="/branding" className="link-arrow">
            <ArrowLong className={"svg-arrow"} />
          </a>
        </div>

        <div className="container-proyecto-data">
          <div className="data-left">
            <div className="container-textos">
              <span className="item-name">Cliente:</span>
              <span className="item-desc"> {proyecto.cliente}</span>
            </div>

            {proyecto.sector && (
              <div className="sector">
                <span className="item-name">Sector:</span>
                <span className="item-desc"> {proyecto.sector}</span>
              </div>
            )}

            <div className="container-textos">
              <p className="item-name descripcion" style={{ fontSize: 24 }}>
                Descripción del Proyecto:
              </p>
              <p className="item-desc">{proyecto.descripcion}</p>
              {proyecto.descripcion2 && (
                <p className="item-desc">{proyecto.descripcion2}</p>
              )}
            </div>
            <div className="container-textos">
              <p className="item-name desatio" style={{ fontSize: 24 }}>
                Desafíos y Soluciones:
              </p>
              {proyecto.desafios.map((desafio, index) => {
                return (
                  <div key={index + "desafio"} className="desafio-container">
                    <span className="item-name">
                      <img alt="flecha" src={ArrowDiagonal} /> {desafio.item}:
                    </span>
                    <span className="item-desc"> {desafio.desarrollo}</span>
                    {desafio.lista && (
                      <ol className="item-desc">
                        {desafio.lista.map((item, index) => {
                          return <li key={index}>{item}</li>;
                        })}
                      </ol>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="data-right">
            <div className="tags-container">
              {proyecto.tags.map((tag) => (
                <div key={tag} className="tag">
                  <span>{tag}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </motion.article>
      <div className="contenedor-acordeones">
        <ProyectoAcordeon titulo={"Descripción del Proyecto"}>
          <p className="accordeon-texto">{proyecto.descripcion}</p>
          {proyecto.descripcion2 && (
            <p className="accordeon-texto">{proyecto.descripcion2}</p>
          )}
        </ProyectoAcordeon>
        <ProyectoAcordeon titulo={"Desafíos y soluciones"}>
          {proyecto.desafios.map((desafio, index) => {
            return (
              <div key={index + "desafio"} className="accordeon-texto">
                <span className="item-name">
                  <img alt="flecha" src={ArrowDiagonal} /> {desafio.item}:
                </span>
                <span className="item-desc"> {desafio.desarrollo}</span>
              </div>
            );
          })}
          <div
            className="accordeon-tags"
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: 13,
              marginTop: 18,
            }}
          >
            {proyecto.tags.map((tag) => (
              <div key={tag} className="tag">
                <span>{tag}</span>
              </div>
            ))}
          </div>
        </ProyectoAcordeon>
      </div>
    </>
  );
};

export default ProyectoData;
