import React from 'react'

import { useTranslation } from 'react-i18next';
export default function ServiciosAtom({service}) {
    const { t } = useTranslation();
    return (
        <>
            <section className={`panel ${service.class} item slider-item-h`} data-hash="pagina-web" >
                <img src={service.bgSlider} className="bg" />
                <div className="container w-100 d-flex align-items-center" style={{height:'100vh'}}>
                    <div className="row mt-5 w-100 d-flex justify-content-between">
                        <div className="col-md-6 d-flex flex-column align-self-center align-items-start">
                            <h4 className="h1" dangerouslySetInnerHTML={{ __html: t(service.title) }}></h4>
                            <h5 className="ff-circularBold mt-2 mb-5">{t(service.subtitle)}</h5>

                            <a href="#hero" className="btn btn-blue-outline-white">{t(service.button)}</a>
                        </div>
                        <div className="col-md-4 d-flex justify-content-center">
                            <img src={service.image} className="img" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
