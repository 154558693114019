import Lottie from "lottie-react";
import mono from '../../../assets/img/lotties/mono.json'
import chatDesk from '../../../assets/img/lotties/chatDesk.json'
import chatMobile from '../../../assets/img/lotties/chatMobile.json'
import React, { useEffect, useRef } from 'react'
import { numberPhone } from "../../../utils/configChimpa";

export default function SectionFour({ lottieChat }) {

    const lottie1 = useRef()
    const lottie2 = useRef()
    const lottie3 = useRef()

    useEffect(() => {
        if (lottieChat) {
            if (lottie1.current) {
                lottie1.current.play()
            }
            if (lottie2.current) {
                lottie2.current.play()
            }
            if (lottie3.current) {
                lottie3.current.play()
            }
        } else {
            if (lottie1.current) {
                lottie1.current.pause()
            }
            if (lottie2.current) {
                lottie2.current.pause()
            }
            if (lottie3.current) {
                lottie3.current.pause()
            }
        }
    }, [lottieChat])

    return (
        <section id="tu-marca">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center mb-5 d-none d-md-block">
                        <h2>TU MARCA ES ÚNICA</h2>
                        <h1 className="fc-lightBlue">TU SITIO WEB TAMBIEN</h1>
                    </div>
                    <div className="col-12 text-center my-5 d-block d-md-none">
                        <h3>TU MARCA ES ÚNICA</h3>
                        <h2 className="fc-lightBlue">TU SITIO WEB TAMBIEN</h2>
                    </div>
                    <div className="col-6 mb-5 d-none d-md-block">
                        <Lottie lottieRef={lottie1} loop={true} mode={"normal"} autoplay={true} animationData={mono} />
                    </div>
                    <div className="col-6 mb-5 d-none d-md-block">
                        <Lottie lottieRef={lottie2} loop={true} mode={"normal"} autoplay={true} animationData={chatDesk} />
                    </div>
                    <div className="col-12 mb-5 d-block d-md-none">
                        <Lottie lottieRef={lottie3} loop={true} mode={"normal"} autoplay={true} animationData={chatMobile} />
                    </div>
                    <div className="col-12 text-center mb-4">
                        <h3 className="mb-4 py-4">Te hacemos un hogar en la jungla digital</h3>
                        <div className="d-block d-md-none">
                            <a className="btn btn-blue-outline-white" href={"https://api.whatsapp.com/send?phone=+" + numberPhone + "&amp;text=Buenos%20días,%20quiero%20mas%20info%20sobre%20desarrollo%20web"}>Hablemos</a>
                        </div>
                        <div className="d-none d-md-block">
                            <a className="btn btn-blue-outline-white" target="_blank" href={"https://web.whatsapp.com/send?phone=+" + numberPhone + "&amp;text=Buenos%20días,%20quiero%20mas%20info%20sobre%20desarrollo%20web"}>Hablemos</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}