import React from 'react'
import { NavLink } from 'react-router-dom';
import './bannerComponent.css'


const BannerComponent = ({ bannerTitle, bannerContent, bannerLink, type, articleSlug }) => (
  <>
    {type === 'download' ? (
      <div className='article__bannerDescarga'>
        <img className='article__bannerDescarga__campana' src="/images/bannerBell.svg" alt="campana" />
        <p className='article__bannerDescarga__titulo'>{bannerTitle}</p>
        <NavLink className='article__bannerDescarga__enlace' to={`/download/${articleSlug}`} target="_blank">Descargar <svg className='article__bannerDescarga__enlace__icono' width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M16.59 9H15V4c0-.55-.45-1-1-1h-4c-.55 0-1 .45-1 1v5H7.41c-.89 0-1.34 1.08-.71 1.71l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.63-.63.19-1.71-.7-1.71M5 19c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H6c-.55 0-1 .45-1 1" /></svg></NavLink>
      </div>
    ) : (
      <div className='article__BannerEnlace'>
        <div className='article__BannerEnlace__contenido'>

          <p className='article__BannerEnlace__contenido__titulo'>{bannerTitle}</p>
          <div
            className='article__BannerEnlace__contenido__texto'>{bannerContent}</div>
        </div>
        <a className='article__BannerEnlace__enlace' href={bannerLink}><img className='article__BannerEnlace__avion' src="/images/bannerPlane.svg" alt="avion" />¡Quiero saber más!</a>
      </div>
    )}
  </>
);


export default BannerComponent